<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <h4 class="title-red">{{ $t("PRICING_SEASONS.PRICE") }}</h4>

        <dl class="row full">
          <dt>{{ $t("PRICING_SEASONS.NAME") }}</dt>
          <dd>{{ pricingSeason.name }}</dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.PRICE") }}</dt>
          <dd v-for="pricingRule in pricingSeason.pricingRules" v-bind:key="pricingRule.id">
            {{ pricingRule.fullName }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.DATE") }}</dt>
          <dd>{{ pricingSeason.start_date }} - {{ pricingSeason.end_date }}</dd>
        </dl>

        <h4 class="title-red">{{ $t("PRICING_SEASONS.WEEK_PRICE") }}</h4>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.SELECT_TYPE_LABEL") }}</dt>
          <dd>
            {{ $t("PRICING_SEASONS." + pricingSeason.pricing_type) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.PRICE_LABEL") }}</dt>
          <dd>
            {{ pricingSeason.variation }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.PRICE$") }}</dt>
          <dd v-if="pricingSeason.pricing_type === 'PERCENT'">
            %{{ pricingSeason.value }}
          </dd>
          <dd v-if="pricingSeason.pricing_type === 'DOLLAR'">
            {{ $formatCurrency(pricingSeason.value) }}
          </dd>
        </dl>

        <h4 class="title-red">{{ $t("PRICING_SEASONS.WEEKEND_PRICE") }}</h4>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.SELECT_TYPE_LABEL") }}</dt>
          <dd>
            {{ $t("PRICING_SEASONS." + pricingSeason.weekend_pricing_type) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.PRICE_LABEL") }}</dt>
          <dd>
            {{ pricingSeason.weekend_variation }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.PRICE$") }}</dt>
          <dd v-if="pricingSeason.weekend_pricing_type === 'PERCENT'">
            %{{ pricingSeason.weekend_value }}
          </dd>
          <dd v-if="pricingSeason.weekend_pricing_type === 'DOLLAR'">
            {{ $formatCurrency(pricingSeason.weekend_value) }}
          </dd>
        </dl>

        <h4 class="title-red">{{ $t("PRICING_SEASONS.RESTRICTION") }}</h4>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.MINIMUM_STAY") }}</dt>
          <dd>
            {{ pricingSeason.minimum_stay }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.MAXIMUM_STAY") }}</dt>
          <dd>
            {{ pricingSeason.maximum_stay }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.MINIMUM_DAY_BEFORE") }}</dt>
          <dd>
            {{ pricingSeason.minimum_day_before_arrival }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.MAXIMUM_DAY_BEFORE") }}</dt>
          <dd>
            {{ pricingSeason.maximum_day_before_arrival }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_SEASONS.ACTIVE_FROM") }}</dt>
          <dd>
            {{ pricingSeason.active_from }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-season-view-global",

  props: ["pricingSeason"],

  components: {},

  data() {
    return {};
  },

  computed: {
    // isPaymentType() {
    //   return this.policy?.policies_type === POLICIES_TYPE_PAYMENTS;
    // },
    // isCancellationType() {
    //   return this.policy?.policies_type === POLICIES_TYPE_CANCELLATION;
    // },
    // isGuaranteeType() {
    //   return this.policy?.policies_type === POLICIES_TYPE_GUARANTEE;
    // },
  },

  created() {},

  methods: {
    // spotUpdated() {
    //   this.$emit("spotUpdated", true);
    // },
  },

  mounted() {},

  watch: {
    pricingSeason(pricingSeason) {},
  },
};
</script>
