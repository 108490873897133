<template>
  <div class="container">
    <span class="resize-loading" v-if="isLoading">
      <span class="loader"></span>
    </span>
    <div class="container">
        <div class="form-wrapper width_1-2">
          <!-- date de reservation -->
          <base-input :label="$t('BOOKINGS.BOOKING_DATE')">
            <el-date-picker
                v-model="selectedDateRange"
                type="daterange"
                start-placeholder="Date de début"
                end-placeholder="Date de fin"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                :min="new Date()"
                :clearable="false"
                :disabled-date="disableSpecificDates"
                :picker-options="{ disabledDate: disableSpecificDates, cellClassName: spotNotAvailableClass }"
                @change="onDateSelected"
            />
          </base-input>
          <validation-error v-if="isSpotUnavailable" :errors="[$t('BOOKINGS.NOT_AVAILABLE_ERROR')]"/>
          <validation-error v-if="this.booking.start_at === this.booking.end_at" :errors="[this.$t('BOOKINGS.DATE_RANGE_ALERT')]"/>
          <validation-error :errors="apiValidationErrors.start_at" />
        </div>

      <!-- Tarif -->
      <div class="form-wrapper width_1-2">
        <base-input :label="$t('SPOTS.CHANGE_RATES')">
          <el-select
              v-model="booking.rate"
              :label="$t('SPOTS.CHANGE_RATES')"
              :placeholder="$t('SPOTS.CHANGE_RATES')"
              @change="onFormChanged"
          >
          </el-select>
          <validation-error :errors="apiValidationErrors.rate" />
        </base-input>
      </div>

      <!-- region Occupants du camping -->
      <div class="form-wrapper width_1">
        <div class="form-wrapper-item width_1-3">
          <!-- adult capacity -->
          <base-input :label="$t('SPOTS.SPOT_ADULT_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
              v-model="booking.adults_count"
              @change="onAdultCapacityChanged"
            >
              <el-option
                v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
                :key="n"
                :value="n"
                :label="n"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.adults_count" />
        </div>

        <!-- children capacity -->
        <div class="form-wrapper-item width_1-3">
          <base-input :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
              v-model="booking.children_count"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in maxChildren + 1"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.children_count" />
        </div>

        <!-- pets capacity -->
        <div class="form-wrapper-item width_1-3">
          <base-input :label="$t('SPOTS.SPOT_PETS_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
              v-model="booking.pets_count"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in maxPets + 1"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.pets_count" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Lits -->
      <div
        class="form-wrapper width_1-2"
        v-if="shouldIDisplayBedsQuantityField"
      >
        <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
          <el-select
            :label="$t('SPOTS.BEDS_TOTAL_QTY')"
            :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
            v-model="booking.beds_qty"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.beds_total_qty + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.beds_qty" />
      </div>
      <!-- endregion -->

      <!-- region Equipements -->
      <div class="form-wrapper width_1-2">
        <!-- Equipments -->
        <base-input
          :label="$t('SPOTS.EQUIPMENT_SELECTION')"
          v-if="isLandOrSeasonalFormType"
        >
          <allowed-equipment-selector
            :value="booking.equipment"
            :options-value="spot.allowed_equipment"
            @valueChanged="(value) => (booking.equipment = value)"
            allowNone
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.equipment" />
      </div>

      <div class="form-wrapper width_1-2">
        <!-- Equipments length -->
        <base-input
          type="text"
          :min="0"
          :max="maxEquipmentLength"
          :label="$t('SPOTS.EQUIPMENT_LENGTH')"
          :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
          :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
          :rules="equipmentLengthValidationRules"
          v-model="booking.equipment_length"
          @change="onFormChanged"
          v-if="
            isLandOrSeasonalFormType &&
            booking.equipment &&
            booking.equipment !== TENT
          "
        >
          <template #label>
            <label class="form-control-label" for="">
              {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
              <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
                ({{
                  $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 })
                }})
              </span>
            </label>
          </template>
        </base-input>
        <validation-error :errors="apiValidationErrors.equipment_length" />
      </div>
      <!-- endregion -->

      <!-- region Extensions -->
      <!-- Passager -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('SPOTS.PASSENGER_EXTENSION')"
          v-if="isLandOrSeasonalFormType"
        >
          <el-select
            :label="$t('SPOTS.PASSENGER_EXTENSION')"
            :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
            v-model="booking.passenger_extensions"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.passenger_extensions + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.passenger_extensions" />
      </div>

      <!-- Conducteur -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('SPOTS.DRIVER_EXTENSION')"
          v-if="isLandOrSeasonalFormType"
        >
          <el-select
            :label="$t('SPOTS.DRIVER_EXTENSION')"
            :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
            v-model="booking.driver_extensions"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.driver_extensions + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.rate" />
      </div>
      <!-- endregion -->

      <!-- region Services inclus et électricité -->
      <!-- Services inclus: should not be rendered -->
      <div
        class="form-wrapper width_1-2"
        v-if="isLandOrSeasonalFormType && false"
      >
        <base-input :label="$t('SPOTS.SERVICES_INCLUDED')">
          <service-included-selector
            :options-value="spot.services_included"
            :value="booking.services_included"
            @valueChanged="(value) => (booking.services_included = value)"
            allow-none
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.services_included" />
      </div>

      <!-- Electricity -->
      <div class="form-wrapper width_1-2" v-if="isLandOrSeasonalFormType">
        <base-input :label="$t('SPOTS.ELECTRICITY')">
          <electricity-selector
            :options-value="spot.electricity"
            :value="booking.electricity"
            @valueChanged="(value) => (booking.electricity = value)"
            allow-none
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.electricity" />
      </div>
      <!-- endregion -->

      <!-- region Notes supps -->
      <!-- Demande client -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('BOOKINGS.SPECIAL_REQUEST')"
          :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
        >
          <el-input
            type="textarea"
            v-model="booking.special_request"
          ></el-input>
        </base-input>
        <validation-error :errors="apiValidationErrors.special_request" />
      </div>

      <!-- Note interne -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('BOOKINGS.INTERNAL_NOTE')"
          :placeholder="$t('BOOKINGS.INTERNAL_NOTE')"
        >
          <el-input type="textarea" v-model="booking.comment"></el-input>
        </base-input>
        <validation-error :errors="apiValidationErrors.comment" />
      </div>
      <!-- endregion -->

      <!-- region blocage de l'emplacement -->
      <div class="form-wrapper width_1-2">
        <base-input :label="$t('BOOKINGS.BLOCK_THIS_SPOT')">
          <div class="radio-group-wrapper">
            <el-radio-group v-model="booking.is_spot_blocked">
              <el-radio :label="false">
                {{ $t("COMMON.NO") }}
              </el-radio>
              <el-radio :label="true">
                {{ $t("COMMON.YES") }}
              </el-radio>
            </el-radio-group>
          </div>
        </base-input>
      </div>
      <!-- endregion -->

      <!-- region action button -->
      <div class="buttons">
        <el-button
          class="cancel"
          type="default"
          @click="onBookingEditionCancelled"
        >
          {{ $t("COMMON.CANCEL") }}
        </el-button>
        <el-button class="submit" type="primary" @click="handleSubmit" :loading="isLoading">
          {{ $t("COMMON.UPDATE_ITEM") }}
        </el-button>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
  Radio,
  RadioGroup,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_READY_TO_CAMP,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";
import { SPOT_STATUS_NOT_AVAILABLE } from "@/constants/common";
import { TENT } from "@/constants/allowedEquipments";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import ServiceIncludedSelector from "@/components/ServiceIncludedSelector.vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import moment from "moment";


export default {
  name: "EditBookingForm",

  components: {
    ServiceIncludedSelector,
    ElectricitySelector,
    AllowedEquipmentSelector,
    ValidationError,
    [DatePicker.name]: DatePicker,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },

  data() {
    let selectedDateRange = [
      this.bookingData.start_at,
      this.bookingData.end_at,
    ];

    const spot = { ...this.bookingData.spot };
    const booking = { ...this.bookingData };
    delete booking.spot;

    const maxAdults = spot.adults_capacity;
    const maxChildren = spot.children_capacity;
    const maxPets = spot.pets_capacity;

    const maxEquipmentLength = this.bookingData.spot.equipment_length ?? 0;
    const equipmentLengthValidationRules = `numeric|min_value:0|max_value:${maxEquipmentLength}`;

    return {
      spot,
      booking,
      maxAdults: maxAdults,
      maxChildren: maxChildren,
      maxPets: maxPets,
      selectedDateRange: selectedDateRange,
      equipmentLengthValidationRules: equipmentLengthValidationRules,
      maxEquipmentLength,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      isLoading: false,
      disabledDates: [],
      bookingPricingChanged: false,
    };
  },

  async created() {
    this.isLoading = true;
    await this.getUnavailableDates();
    this.isLoading = false;
    this.canOrderSpot();
  },

  methods: {
    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
      this.getUnavailableDates(this.spot.id,[this.booking.start_at , this.booking.end_at]);
    },
    onAdultCapacityChanged(capacity) {
      this.booking.adults_count = capacity;
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },
    onBookingEditionCancelled() {
      this.$emit("onBookingEditionCancelled");
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },

    async getUnavailableDates(id = null , dateRange = null)
    {
      try
      {
        id ??=  this.spot.id
        console.log("id =>",id," dateRange => ",dateRange);

        if(id)
        {
          dateRange ??= this.selectedDateRange;

          let bookingId = this.bookingData.id ;
          await this.$store.dispatch("spots/getUnavailableDates", {id, dateRange, bookingId});
          this.disabledDates = this.$store.getters["spots/unavailableDates"];

          if(this.disabledDates?.length) {
            this.disabledDates = this.disabledDates.map(dd => dd.split('T')[0]) ;
          }
        }
      }
      catch (error) {
        console.error(error);
      }
    },

    spotNotAvailableClass(date)
    {
      if(date < this.setDateTimeComponentToZero(new Date())) {
        return '';
      }

      const formattedDate = date.toISOString().split('T')[0];

      return this.disabledDates.includes(formattedDate) ? "not-available" : "";
    },

    disableSpecificDates(date)
    {
      // Convertir la date en format 'YYYY-MM-DD' pour la comparer à la liste
      const formattedDate = date.toISOString().split('T')[0];

      return this.disabledDates.includes(formattedDate) || date < this.setDateTimeComponentToZero(new Date());
    },
    /**
     * Mettre a 0 l'heure d'une date avec heure.
     *
     * @param {Date} date
     * @returns {Date}
     */
    setDateTimeComponentToZero(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);

      return date;
    },

    async handleSubmit() {
      if (this.isSpotUnavailable || !this.canOrderSpot()) {
        return;
      }

      if(this.booking.start_at === this.booking.end_at)
      {
        this.$notify({
          type: "warning",
          message: this.$t("BOOKINGS.DATE_RANGE_ALERT"),
        });
        return;
      }

      // verification du pricing
      if(this.bookingPricingChanged)
      {
        let params = {
          filter: {
            availability_date_range: [
              moment(this.booking.start_at).toISOString(),
              moment(this.booking.end_at).toISOString()
            ],
            adults_capacity: this.booking.adults_count
          }
        }

        await this.$store.dispatch("spots/get", {id: this.spot.id, params: params});
        let spotWithPricing = this.$store.getters["spots/spot"] ;

        if(!spotWithPricing?.pricing?.total)
        {
          this.$notify({
            type: "danger",
            message: this.$t("SPOTS.NO_PRICING_FOUND_FOR_SPOT"),
          });

          return;
        }

      }

      await this.sendInformation();
    },

    async sendInformation()
    {
      this.isLoading = true;
      const bookingData = cloneDeep(this.booking);
      delete bookingData.check_in_at;
      delete bookingData.check_out_at;
      delete bookingData.confirmation_expires_at;

      bookingData.start_at = this.$convertDateToUtc(this.booking.start_at);
      bookingData.end_at = this.$convertDateToUtc(this.booking.end_at);

      try {
        await this.$store.dispatch("bookings/update", bookingData);

        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_UPDATED"),
        });

        const booking = await this.$store.getters["bookings/booking"];

        this.$emit("onBookingEditionDone", booking, true);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response.data.errors;
      } finally {
        this.isLoading = false;
      }
    },

    setModal(config){
      config = {
        ...{
          title: null,
          html: null,
          type: "warning",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: false,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.CLOSE"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        },
        ...config
      };
      return swal.fire(config);
    },

    canOrderSpot() {
      let [start_date,end_date] = [
        moment(this.booking.start_at).toISOString().split('.')[0],
        moment(this.booking.end_at).toISOString().split('.')[0]
      ];

      const disabledDates = this.disabledDates;

      let errorsDates = [];
      let errors = [];

      if (disabledDates)
      {
        // Vérifie si `start` ou `end` est dans les `unavailableDates`
        if(disabledDates.includes(start_date)) {
          errorsDates.push(this.$formatDate(start_date,"DD-MM-YYYY"));
        }
        if(disabledDates.includes(end_date)) {
          errorsDates.push(this.$formatDate(end_date,"DD-MM-YYYY"));
        }
      }

      if(errorsDates.length === 1) {
        errors.push(`L'emplacement n'est pas disponible le ${errorsDates[0]}`);
      }
      else if(errorsDates.length === 2) {
        errors.push(`L'emplacement n'est pas disponible pour les dates du ${errorsDates[0]} au ${errorsDates[1]}`);
      }

      if(errors.length) {
        this.setModal({html : this.$t("BOOKINGS.DATES_NOT_AVAILABLE")});
      }

      return !errors.length;
    }
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },

  computed: {
    shouldIDisplayBedsQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
        this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP &&
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isSeasonalFormType() {
      return (
        this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType;
    },
    isSpotUnavailable() {
      return this.spot.status === SPOT_STATUS_NOT_AVAILABLE;
    },
  },
};
</script>

<style scoped></style>