<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.COSTS") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
<!--      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.BUNDLE')} (*)`">
          <el-select
            v-model="bundleValue"
            :placeholder="`${$t('PRICING_RULES.BUNDLE')}`"
          >
            <el-option
              v-for="bundle in bundles"
              :key="bundle.value"
              :label="bundle.label"
              :value="bundle.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>-->
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.LODGING_TAX')} (*)`">
          <el-radio-group v-model="lodgingTax">
            <el-radio :label="true">{{ $t("COMMON.YES") }}</el-radio>
            <el-radio :label="false">{{ $t("COMMON.NO") }}</el-radio>
          </el-radio-group>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.ADULTADDCOST')} (*)`">
          <el-input-number
            v-model="adultAddCost"
            controls-position="right"
            :min="0"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>
<!--      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.CHILDRENADDCOST')} (*)`">
          <el-input-number
            v-model="childrenAddCost"
            controls-position="right"
            :min="0"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>-->
    </div>
  </div>
</template>
<script>
import { Option, Radio, RadioGroup, Select, InputNumber } from "element-ui";
export default {
  name: "costs-step",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [InputNumber.name]: InputNumber,
  },

  props: {
    bundleId: {
      type: Number,
      default: 0,
      description: "Bundle Id",
    },
    lodgingTaxValue: {
      type: Boolean,
      default: true,
      description: "Taxe d'hébergement",
    },
    adultAddCostValue: {
      type: Number,
      default: 1,
      description: "Frais additionnel adults",
    },
    childrenAddCostValue: {
      type: Number,
      default: 1,
      description: "Frais additionnel enfants",
    },
  },

  mixins: [],

  data() {
    return {
      bundles: [
        {
          value: 1,
          label: "Bundle 1",
        },
        {
          value: 2,
          label: "Bundle 2",
        },
      ],
      bundleValue: this.bundleId,
      lodgingTax: this.lodgingTaxValue,
      adultAddCost: this.adultAddCostValue,
      childrenAddCost: this.childrenAddCostValue,
    };
  },

  computed: {},

  methods: {},

  watch: {
    bundleValue(value) {
      this.$emit("update:bundleId", value);
    },
    lodgingTax(value) {
      this.$emit("update:lodgingTaxValue", !!value);
    },
    adultAddCost(value) {
      this.$emit("update:adultAddCostValue", value);
    },
    childrenAddCost(value) {
      this.$emit("update:childrenAddCostValue", value);
    },
  },
};
</script>
