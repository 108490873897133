import {
  POLICIES_PAYMENT_TYPES_AFTER_CREATE,
  POLICIES_TYPE_PAYMENTS,
  PAYMENT_TYPES_PERCENT
} from "@/constants/common";

export default {
  type: "pricing-seasons",
  id: null,
  name: '',
  start_date: "",
  end_date: "",
  variation: "+",
  pricing_type: "DOLLAR",
  value: 0,
  weekend_variation: "+",
  weekend_pricing_type: "DOLLAR",
  weekend_value: 0,
  minimum_stay: 1,
  maximum_stay: 9999,
  minimum_day_before_arrival: 0,
  maximum_day_before_arrival: 9999,
  active_from: null,
  created_at: 0,
  updated_at: 0,
  spot_type_name: '',
  relationshipNames: [
    "organization",
    "pricingRules",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  pricingRules: [],
};
