<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="configuration-wrapper">
      <h1>{{ $t("APPS.CONFIGURATION_MODULE") }}</h1>
      <div class="kw-breadcrumb">
        <ul>
          <li>
            <img src="/img/kw-home.svg" alt="icon" />
            <span>{{ $t("APPS.CONFIGURATION_MODULE") }}</span>
          </li>
          <li v-if="$route.name === 'List SpotCategories'">
            {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
          </li>
          <li v-if="$route.name === 'List SpotTypes'">
            {{ $t("CONFIGURATION.SPOT_TYPES") }}
          </li>
          <li v-if="$route.name === 'List Equipments'">
            {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
          </li>
          <li v-if="$route.name === 'List EquipmentCategories'">
            {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
          </li>
        </ul>
      </div>
      <ul class="sub-menu-items">
        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_CATEGORIES)"
        >
          <router-link
            :to="{ name: 'List SpotCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_TYPES)">
          <router-link
            :to="{ name: 'List SpotTypes' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_TYPES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES)
          "
        >
          <router-link
            :to="{ name: 'List EquipmentCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)">
          <router-link
            :to="{ name: 'List Equipments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <h3>{{ $t("CONFIGURATION.SPOT_CATEGORIES") }}</h3>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openSpotCategoryCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_SPOT_CATEGORIES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("SPOT_CATEGORIES.ADD_SPOT_CATEGORY")
            }}</span>
          </base-button>
        </div>
      </div>

      <spot-category-list-table
        @onViewSpotCategory="openSpotCategoryViewModal"
        @onEditSpotCategory="openSpotCategoryEditModal"
        @onDeleteSpotCategory="deleteSpotCategory"
        @onAddSpotCategory="openSpotCategoryCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSpotCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSpotCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SPOT_CATEGORY_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_CATEGORIES.VIEW_SPOT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-spot-category-component
              v-if="openSpotCategory"
              :spotCategoryId="openSpotCategory.id"
              @onEditSpotCategory="openSpotCategoryEditModal"
              @onDeleteSpotCategory="deleteSpotCategory"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSpotCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSpotCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SPOT_CATEGORY'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_CATEGORIES.EDIT_SPOT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-spot-category-component
              v-if="openSpotCategory"
              :spotCategoryId="openSpotCategory.id"
              @onViewSpotCategory="openSpotCategoryViewModal(openSpotCategory)"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSpotCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSpotCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SPOT_CATEGORY'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_CATEGORIES.ADD_SPOT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-spot-category-component
              @onViewSpotCategory="openSpotCategoryViewModal"
              @onCloseSpotCategoryModal="closeSpotCategoryModal"
            />
          </div>
        </div>
      </div>
    </div>
    <select-an-organization-message v-if="!globalOrganization?.id"/>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import SpotCategoryListTable from "./partials/SpotCategoryListTable.vue";
import EditSpotCategoryComponent from "./components/EditSpotCategoryComponent.vue";
import AddSpotCategoryComponent from "./components/AddSpotCategoryComponent.vue";
import ViewSpotCategoryComponent from "./components/ViewSpotCategoryComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import {mapGetters} from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    SpotCategoryListTable,
    EditSpotCategoryComponent,
    AddSpotCategoryComponent,
    ViewSpotCategoryComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const spotCategoryId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSpotCategoryModalOpened = false;
    let isEditSpotCategoryModalOpened = false;
    let isAddSpotCategoryModalOpened = false;
    let openSpotCategory = null;
    if (spotCategoryId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSpotCategoryModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSpotCategoryModalOpened = true;
      }
      openSpotCategory = { id: spotCategoryId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSpotCategoryModalOpened = true;
    }
    return {
      isViewSpotCategoryModalOpened: isViewSpotCategoryModalOpened,
      isEditSpotCategoryModalOpened: isEditSpotCategoryModalOpened,
      isAddSpotCategoryModalOpened: isAddSpotCategoryModalOpened,
      openSpotCategory: openSpotCategory,
      renderKey: 1,
    };
  },

  methods: {
    openSpotCategoryCreateModal() {
      this.closeSpotCategoryModal();
      this.isAddSpotCategoryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotCategories",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openSpotCategoryViewModal(spotCategory, reRender = false) {
      this.closeSpotCategoryModal();
      this.openSpotCategory = spotCategory;
      this.isViewSpotCategoryModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotCategories",
          query: { id: this.openSpotCategory.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSpotCategoryEditModal(spotCategory) {
      this.closeSpotCategoryModal();
      this.openSpotCategory = spotCategory;
      this.isEditSpotCategoryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotCategories",
          query: { id: this.openSpotCategory.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeSpotCategoryModal() {
      this.isAddSpotCategoryModalOpened = false;
      this.isViewSpotCategoryModalOpened = false;
      this.isEditSpotCategoryModalOpened = false;
      this.openSpotCategory = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotCategories",
          query: {},
        }).href
      );
    },

    async deleteSpotCategory(spotCategory) {
      const confirmation = await swal.fire({
        text: this.$t("SPOT_CATEGORIES.DELETE_THIS_SPOT_CATEGORY"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("spotCategories/destroy", spotCategory.id);
          this.renderKey++;
          this.closeSpotCategoryModal();
          this.$notify({
            type: "success",
            message: this.$t("SPOT_CATEGORIES.SPOT_CATEGORY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
