<template>
  <div class="elite-tabs-wrapper-content">
    <h3>
      {{ `${file.name}` }} -
      {{ $t("COMMON.LOGS") }}
    </h3>
    <log-list-table :filterSubject="file" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "file-view-modal-logs",

  components: { LogListTable },

  props: ["file"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
