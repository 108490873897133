<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.RESTRICTIONS") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MINIMUM_STAY')}`">
          <el-input-number
            v-model="minimumNight"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MAXIMUM_STAY')}`">
          <el-input-number
            v-model="maximumNight"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MINIMUM_DAY_BEFORE_ARRIVAL')}`">
          <el-input-number
            v-model="minimumDay"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MAXIMUM_DAY_BEFORE_ARRIVAL')}`">
          <el-input-number
            v-model="maximumDay"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MINIMUM_PERSON')}`">
          <el-input-number
            v-model="minimumPerson"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MAXIMUM_PERSON')}`">
          <el-input-number
            v-model="maximumPerson"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>
import { InputNumber } from "element-ui";
export default {
  name: "restrictions-step",

  components: {
    [InputNumber.name]: InputNumber,
  },

  props: {
    minStay: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    maxStay: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    minPerson: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    maxPerson: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    minDay: {
      type: Number,
      default: 0,
      description: "minimum_day_before_arrival",
    },
    maxDay: {
      type: Number,
      default: 0,
      description: "maximum_day_before_arrival",
    },
  },

  mixins: [],

  data() {
    const minimumNight = this.minStay;
    const maximumNight = this.maxStay;
    const minimumPerson = this.minPerson;
    const maximumPerson = this.maxPerson;
    const minimumDay = this.minDay;
    const maximumDay = this.maxDay;
    return {
      minimumNight,
      maximumNight,
      minimumPerson,
      maximumPerson,
      minimumDay,
      maximumDay
    };
  },

  computed: {},

  methods: {},

  watch: {
    minimumNight(value) {
      this.$emit("update:minStay", value);
    },
    maximumNight(value) {
      this.$emit("update:maxStay", value);
    },
    minimumPerson(value) {
      this.$emit("update:minPerson", value);
    },
    maximumPerson(value) {
      this.$emit("update:maxPerson", value);
    },
    minimumDay(value) {
      this.$emit("update:minDay", value);
    },
    maximumDay(value) {
      this.$emit("update:maxDay", value);
    },
  },
};
</script>
