<template>
  <modal :show.sync="isOpen" class="out-of-service">
    <div class="modal-body-inner">
      <span class="modal-body-inner-icon"> </span>
      <h2 class="modal-body-inner-title">
        {{ $t("SPOTS.OUT_OF_SERVICE_REASON") }}
      </h2>
      <div class="modal-body-inner-form">
        <base-input>
          <el-select v-model="reason">
            <el-option
              v-for="option in outOfServiceReasonOptions"
              :key="option"
              :value="option"
              :label="$t(`BOOKINGS.REASON_${option}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="modal-body-inner-footer">
        <base-button outline @click="isOpen = false">
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button @click="submit" :disabled="!reason">
          {{ $t("COMMON.ADD") }}
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { Select, Option } from "element-ui";

import { outOfServiceReasonOptions } from "../constants/bookings";

export default {
  name: "out-of-service-confirmation-modal",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const isOpen = this.open ?? false;
    return {
      isOpen,
      reason: null,
      outOfServiceReasonOptions,
    };
  },

  methods: {
    submit() {
      if (!this.reason) return;

      this.onConfirm(this.reason);
    },
  },

  watch: {
    open(value) {
      this.isOpen = value;
    },

    isOpen(value) {
      this.reason = null;
      this.$emit("update:open", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.w-60px {
  width: 60px;
}
.h-60px {
  height: 60px;
}
.border-15px {
  border-width: 15px !important;
}
</style>
