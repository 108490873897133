<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!organization">
      <span class="loader"></span>
    </span>
    <div v-if="organization" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.ORGANIZATION_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("ORGANIZATIONS.ORGANIZATIONS_LIST") }}</span>
              </li>
              <li>
                <span>{{ organization.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="default" @click="showSpots">
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.28544 17.6428C4.54901 17.6428 3.14258 16.2363 3.14258 14.4999C3.14258 12.7635 4.54901 11.3571 6.28544 11.3571C8.02186 11.3571 9.42829 12.7635 9.42829 14.4999C9.42829 16.2363 8.02186 17.6428 6.28544 17.6428ZM6.28544 12.9285C5.42115 12.9285 4.71401 13.6356 4.71401 14.4999C4.71401 15.3642 5.42115 16.0713 6.28544 16.0713C7.14972 16.0713 7.85686 15.3642 7.85686 14.4999C7.85686 13.6356 7.14972 12.9285 6.28544 12.9285Z"
                fill="#fff"
              />
              <path
                d="M18.0714 15.2857H8.64286C8.21071 15.2857 7.85714 14.9321 7.85714 14.5C7.85714 14.0678 8.21071 13.7143 8.64286 13.7143H17.2857V4.28569C17.2857 2.98926 16.225 1.92855 14.9286 1.92855H3.92857C2.63214 1.92855 1.57143 2.98926 1.57143 4.28569V12.9285C1.57143 13.3607 1.925 13.7143 2.35714 13.7143H3.92857C4.36071 13.7143 4.71429 14.0678 4.71429 14.5C4.71429 14.9321 4.36071 15.2857 3.92857 15.2857H2.35714C1.06071 15.2857 0 14.225 0 12.9285V4.28569C0 2.11712 1.76 0.357117 3.92857 0.357117H14.9286C17.0971 0.357117 18.8571 2.11712 18.8571 4.28569V14.5C18.8571 14.9321 18.5036 15.2857 18.0714 15.2857Z"
                fill="#fff"
              />
              <path
                d="M14.1426 12.1428H11.7854C10.9211 12.1428 10.214 11.4357 10.214 10.5714V5.07137C10.214 4.20708 10.9211 3.49994 11.7854 3.49994H14.1426C15.0069 3.49994 15.714 4.20708 15.714 5.07137V10.5714C15.714 11.4357 15.0069 12.1428 14.1426 12.1428ZM11.7854 5.07137V10.5714H14.1426V5.07137H11.7854ZM7.07115 8.99994H4.71401C3.84972 8.99994 3.14258 8.2928 3.14258 7.42851V5.07137C3.14258 4.20708 3.84972 3.49994 4.71401 3.49994H7.07115C7.93544 3.49994 8.64258 4.20708 8.64258 5.07137V7.42851C8.64258 8.2928 7.93544 8.99994 7.07115 8.99994ZM4.71401 5.07137V7.42851H7.07115V5.07137H4.71401ZM21.214 15.2857H18.0712C17.639 15.2857 17.2854 14.9321 17.2854 14.4999C17.2854 14.0678 17.639 13.7142 18.0712 13.7142H21.214C21.6462 13.7142 21.9997 14.0678 21.9997 14.4999C21.9997 14.9321 21.6462 15.2857 21.214 15.2857Z"
                fill="#fff"
              />
            </svg>

            <span>{{ $t("COMMON.SPOTS") }}</span>
          </button>
          <button class="delete" @click="deleteOrganization">
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE") }}</span>
          </button>
          <button class="edit" @click="editOrganization">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <organization-view-global :organization="organization" />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
          </span>
          <organization-view-authorizations :organization="organization" />
        </tab-pane>

        <tab-pane
          title="options"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_OPTIONS)"
        >
          <span slot="title">
            {{ $t("COMMON.OPTIONS") }}
          </span>
          <organization-view-options :organization="organization" />
        </tab-pane>

        <tab-pane
          title="subscriptions"
          id="4"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUBSCRIPTIONS)"
        >
          <span slot="title">
            {{ $t("COMMON.SUBSCRIPTIONS") }}
          </span>
          <organization-view-subscriptions :organization="organization" />
        </tab-pane>

        <tab-pane
          title="users"
          id="5"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <span slot="title">
            {{ $t("COMMON.USERS") }}
          </span>
          <organization-view-users :organization="organization" />
        </tab-pane>

        <tab-pane
          title="roles"
          id="6"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <span slot="title">
            {{ $t("COMMON.ROLES") }}
          </span>
          <organization-view-roles :organization="organization" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="getFilesObject()" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <organization-view-logs :organization="organization" />
        </tab-pane>
      </tabs>
    </div>

    <modal
      :show.sync="showSubscriptionAddModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="col-12 text-center mb-2 mt-5">
        <h2>{{ $t("ORGANIZATIONS.SELECT_A_PACKAGE") }}</h2>
      </div>

      <form class="" @submit.prevent="generateSubscriptionInvoice">
        <base-input :label="$t('COMMON.PACKAGES')">
          <package-selector
            @packageChanged="
              (packageId) => (addSubscriptionPackageId = packageId)
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.package" />

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ $t("ORGANIZATIONS.ADD_SUBSCRIPTION") }}
          </base-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
// import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import OrganizationViewGlobal from "../partials/OrganizationViewGlobal.vue";
import OrganizationViewUsers from "../partials/OrganizationViewUsers.vue";
import OrganizationViewRoles from "../partials/OrganizationViewRoles.vue";
import OrganizationViewLogs from "../partials/OrganizationViewLogs.vue";
import OrganizationViewSubscriptions from "../partials/OrganizationViewSubscriptions.vue";
import OrganizationViewAuthorizations from "../partials/OrganizationViewAuthorizations.vue";
import OrganizationViewOptions from "../partials/OrganizationViewOptions.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    OrganizationViewGlobal,
    OrganizationViewUsers,
    OrganizationViewRoles,
    OrganizationViewLogs,
    OrganizationViewSubscriptions,
    OrganizationViewAuthorizations,
    ListFileComponent,
    PackageSelector,
    ValidationError,
    OrganizationViewOptions,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      organization: null,
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("organizations/get", this.organizationId);
        this.organization = this.$store.getters["organizations/organization"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async generateSubscriptionInvoice() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "organizations/generateSubscriptionInvoice",
          {
            organizationId: this.organization.id,
            data: { package: this.addSubscriptionPackageId },
          }
        );

        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push(this.$objectViewRoute(invoice));
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },

    editOrganization() {
      this.$emit("onEditOrganization", this.organization);
    },

    deleteOrganization() {
      this.$emit("onDeleteOrganization", this.organization);
    },

    showSpots() {
      this.$store.dispatch("organizations/setOrganization", this.organization);
      this.$router.push({
        name: "List Spots",
        query: {
          org_id: this.organization.id,
        },
      });
    },

    getFilesObject() {
      return {
        ...cloneDeep(this.organization),
        organization: cloneDeep(this.organization),
      };
    },
  },
};
</script>
