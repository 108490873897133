<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!policy">
      <span class="loader"></span>
    </span>
    <div v-if="policy" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ policyName }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("POLICIES.POLICIES") }}</span>
              </li>
              <li>
                <span>{{ $t("POLICIES.POLICIES_LIST") }}</span>
              </li>
              <li>
                <span>{{ policyName }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deletePolicy"
            v-if="$currentUserCan($permissions.PERM_DELETE_POLICIES)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editPolicy"
            v-if="$currentUserCan($permissions.PERM_EDIT_POLICIES)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <policy-view-global :policy="policy" />
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import PolicyViewGlobal from "@/views/Pages/PolicyModule/PolicyManagement/partials/PolicyViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PolicyViewGlobal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    policyId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      policy: null,
    };
  },

  computed: {
    policyName() {
      return this.policy?.name;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("policies/get", { id: this.policyId });
        this.policy = this.$store.getters["policies/policy"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPolicy() {
      this.$emit("onEditPolicy", this.policy);
    },

    deletePolicy() {
      this.$emit("onDeletePolicy", this.policy);
    },
  },
};
</script>
