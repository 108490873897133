<template>
  <div class="container-fluid">
    <template-mail-form
      :loading="loading"
      :templateMailData="templateMail"
      :formErrors="formErrors"
      @templateMailSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseTemplateMailModal="onCloseTemplateMailModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultTemplateMail from "../defaultTemplateMail.js";
import TemplateMailForm from "../partials/TemplateMailForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TemplateMailForm,
  },

  mixins: [alertLeave],

  data() {
    const templateMail = cloneDeep(defaultTemplateMail);
    const me = this.$store.getters["profile/me"];
    return {
      templateMail: templateMail,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(templateMail) {
      this.loading = true;

      const templateMailData = cloneDeep(templateMail);
      delete templateMailData.id;

      try {
        await this.$store.dispatch("templatesMails/add", templateMailData);
        this.$notify({
          type: "success",
          message: this.$t("TEMPLATES_MAILS.TEMPLATE_MAIL_ADDED"),
        });
        const templateMail =
          this.$store.getters["templatesMails/templatesMail"];
        this.$emit("onViewTemplateMail", templateMail, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseTemplateMailModal() {
      this.$emit("onCloseTemplateMailModal");
    },
  },
};
</script>
