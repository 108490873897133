<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ salesInvoice.code }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ salesInvoice.created_at | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("SALES_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd>
            {{ salesInvoice.expiration_time | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesInvoice.allowedLocations" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesInvoice.organization" />
          </dd>
        </dl>
        <dl class="row" v-if="salesInvoice.salesInvoice">
          <dt>{{ $t("COMMON.SALES_ORDER") }}</dt>
          <dd>
            <object-link :object="salesInvoice.salesInvoice" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="salesInvoice.recipient" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <sales-invoice-status-badge :salesInvoice="salesInvoice" />
          </dd>
        </dl>
      </div>
    </div>
    <div class="all-infos">
      <div class="all-infos-text">
        <!-- region pricing -->
        <!-- total -->
        <dl class="row" v-if="salesInvoice.pricing">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd>
            <span> {{ $formatCurrency(salesInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <!-- Payee -->
        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL_PAID") }}</dt>
          <dd>
            <span>
              {{
                $formatCurrency(
                  salesInvoice.total_paied ? salesInvoice.total_paied : 0
                )
              }}
            </span>
          </dd>
        </dl>
        <!-- refund -->
        <dl v-if="false && salesInvoice.total_refund" class="row">
          <dt>{{ $t("COMMON.TOTAL_REFUND") }}</dt>
          <dd>
            <span>
              {{
                $formatCurrency(
                  salesInvoice.total_refund ? salesInvoice.total_refund : 0
                )
              }}
            </span>
          </dd>
        </dl>
        <!-- payer la difference -->
        <dl v-if="this.salesInvoice?.amount_difference" class="row">
          <dt>
            {{ $t("SALES_PAYMENTS.VIEW_SALES_PAYMENT") }}
          </dt>
          <dd>
            <el-button
              @click="onPaymentBtnClick"
              :disabled="paymentProcessing"
              class="payment"
              type="primary"
            >
              <span v-if="salesInvoice.amount_difference > 0">
                {{
                  $t("SALES_INVOICES.PAIEMENT_DUE_LABEL", {
                    amount: $formatCurrency(salesInvoice.amount_difference),
                  })
                }}
              </span>
              <span v-else>
                {{
                  $t("SALES_INVOICES.REFUND_LABEL", {
                    amount: $formatCurrency(salesInvoice.amount_difference),
                  })
                }}
              </span>
            </el-button>
          </dd>
          <!-- endregion Formulaire de paiement -->
          <sales-invoice-view-add-payment-modal
            v-if="salesInvoice && paymentStep"
            :salesInvoice="salesInvoice"
            :showModal="paymentStep"
            :amount-to-pay="salesInvoice.amount_difference"
            @onCloseModal="onPaymentCancelled"
            @paymentAdded="onChargeDifferenceDone"
          />
          <!-- endregion -->
        </dl>
        <!-- endregion -->
      </div>
    </div>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="salesInvoice.excerpt">
          <dt>
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd>
            <div v-html="salesInvoice.excerpt"></div>
          </dd>
        </dl>
        <div class="col-12 mb-3 mt-4 ml-2">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_lastname == "N/A" ? "" : salesInvoice.billing_lastname  }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_firstname == "N/A" ? "" :   salesInvoice.billing_firstname}}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_company_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ salesInvoice.billing_country }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ salesInvoice.billing_state }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ salesInvoice.billing_city }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ salesInvoice.billing_zipcode }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ salesInvoice.billing_address }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ salesInvoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "element-ui";
import SalesInvoiceStatusBadge from "./SalesInvoiceStatusBadge.vue";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesInvoiceViewAddPaymentModal from "@/views/Pages/SalesModule/SalesInvoiceManagement/partials/SalesInvoiceViewAddPaymentModal.vue";
import { STATUS_DRAFT, STATUS_CANCELED } from "@/constants/bookings";

export default {
  name: "sales-invoice-view-global",

  components: {
    SalesInvoiceStatusBadge,
    SalesInvoiceViewAddPaymentModal,
    [Button.name]: Button,
  },

  props: ["salesInvoice"],

  data() {
    return {
      paymentStep: false,
      paymentProcessing: false,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      INVOICE_STATUS_PARTIALLY_PAID: INVOICE_STATUS_PARTIALLY_PAID,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_CANCELED: STATUS_CANCELED,
    };
  },

  computed: {},

  created() {},

  methods: {
    // region paiement
    onPaymentBtnClick() {
      this.paymentStep = !this.paymentStep;
      this.paymentProcessing = false;
    },
    onPaymentCancelled() {
      this.paymentStep = false;
      this.paymentProcessing = false;
    },
    onChargeDifferenceProcessing() {
      this.paymentProcessing = true;
    },
    onChargeDifferenceFailed() {
      this.paymentProcessing = false;
    },
    onChargeDifferenceDone() {
      this.onPaymentCancelled();
      this.$emit("onChargeDifferenceDone");
    },
    // endregion
  },

  mounted() {},

  watch: {
    salesInvoice(salesInvoice) {},
  },
};
</script>
