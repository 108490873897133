<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Admin Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("IMPORTS.IMPORTS_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.ADMIN_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("IMPORTS.IMPORTS_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openImportCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_IMPORTS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{ $t("IMPORTS.ADD_IMPORT") }}</span>
          </base-button>
        </div>
      </div>

      <import-list-table
        @onViewImport="openImportViewModal"
        @onEditImport="openImportEditModal"
        @onDeleteImport="deleteImport"
        @onAddImport="openImportCreateModal"
        :key="renderKey * 100"
      />
      <div
        v-if="isViewImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'IMPORT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeImportModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("IMPORTS.VIEW_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-import-page v-if="openImport" :importId="openImport.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'IMPORT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeImportModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("IMPORTS.EDIT_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-import-page
              v-if="openImport"
              :importId="openImport.id"
              @onViewImport="openImportViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'IMPORT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeImportModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("IMPORTS.ADD_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <!--<button class="close" @click="closeImportModal">
                <i class="fal fa-times"></i>
              </button>-->
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-import-page
              @onViewImport="openImportViewModal"
              @onCloseImportModal="closeImportModal"
            />
          </div>
        </div>
      </div>
    </div>
    <select-an-organization-message v-if="!globalOrganization?.id"/>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ImportListTable from "@/views/Pages/AdminModule/ImportModule/partials/ImportListTable.vue";
import EditImportPage from "./components/EditImportComponent.vue";
import AddImportPage from "./components/AddImportComponent.vue";
import ViewImportPage from "./components/ViewImportComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import {mapGetters} from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    ImportListTable,
    EditImportPage,
    AddImportPage,
    ViewImportPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const importId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewImportModalOpened = false;
    let isEditImportModalOpened = false;
    let isAddImportModalOpened = false;
    let openImport = null;
    if (importId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewImportModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditImportModalOpened = true;
      }
      openImport = { id: importId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddImportModalOpened = true;
    }
    return {
      isViewImportModalOpened: isViewImportModalOpened,
      isEditImportModalOpened: isEditImportModalOpened,
      isAddImportModalOpened: isAddImportModalOpened,
      openImport: openImport,
      renderKey: 1,
    };
  },

  methods: {
    openImportCreateModal() {
      this.closeImportModal();
      this.isAddImportModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Imports",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openImportViewModal(importName, reRender = false) {
      this.closeImportModal();
      this.openImport = importName;
      this.isViewImportModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Imports",
          query: { id: this.openImport.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openImportEditModal(importation) {
      this.closeImportModal();
      this.openImport = importation;
      this.isEditImportModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Imports",
          query: { id: this.openImport.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeImportModal() {
      this.isAddImportModalOpened = false;
      this.isViewImportModalOpened = false;
      this.isEditImportModalOpened = false;
      this.openImport = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Imports",
          query: {},
        }).href
      );
    },

    async deleteImport(importation) {
      const confirmation = await swal.fire({
        text: this.$t("IMPORTS.DELETE_THIS_IMPORT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("imports/destroy", importation.id);
          this.renderKey++;
          this.closeImportModal();
          this.$notify({
            type: "success",
            message: this.$t("IMPORTS.IMPORT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
