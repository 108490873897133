import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/bookings`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/bookings/${id}?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      options
    )
    .then((response) => {
      let booking = jsona.deserialize(response.data);
      delete booking.links;
      return booking;
    });
}

function add(booking) {
  const payload = jsona.serialize({
    stuff: booking,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/bookings?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(booking) {
  const payload = jsona.serialize({
    stuff: booking,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/bookings/${booking.id}?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/bookings/${id}`, options);
}

function checkIn(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/bookings/${id}/check-in?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function checkOut(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/bookings/${id}/check-out?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function cancel(bookingId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/bookings/${bookingId}/cancel?include=organization,spot,spot.spotType,spot.category,customer,createdBy,orderItem`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

/**
 * Determine si un booking deja recupere a un client contenant un mot cle ou non.
 *
 * @author jordy jordy[at]latactik.com
 * @param {Object} booking
 * @param {string} keyword
 * @returns {boolean}
 */
function hasCustomer(booking, keyword)
{
  if(!booking?.customer) {
    return false;
  }

  let customer = booking.customer;

  let fullName = customer.firstname.trim().toLowerCase() + ' ' + customer.lastname.trim().toLowerCase();
  let email = customer.email.trim().toLowerCase();
  let phone = customer.phone.trim().toLowerCase();

  return fullName.includes(keyword.trim().toLowerCase()) || email.includes(keyword.trim().toLowerCase()) || phone.includes(keyword.trim().toLowerCase());
}

/**
 * Determine si un booking deja recupere a un code en mot cle ou non.
 *
 * @author jordy jordy[at]latactik.com
 * @param {Object} booking
 * @param {string} code le code recherche
 * @returns {boolean}
 */
function hasCode(booking, code)
{
  if (booking?.code)
  {
    let bookingCode = booking.code.trim().toLowerCase();
    return bookingCode.includes(code.trim().toLowerCase());
  }

  return false;
}

function hasCustomerOrCode(booking, keyword) {
  return hasCode(booking, keyword) || hasCustomer(booking, keyword);
}

function filterBookingsByCustomerOrCode(bookings, keyword)
{
  if(bookings?.length) {
    return bookings.filter(booking => hasCustomerOrCode(booking, keyword));
  }

  return [];
}

export default {
  list,
  get,
  add,
  update,
  cancel,
  destroy,
  checkIn,
  checkOut,
  hasCustomer,
  hasCode,
  hasCustomerOrCode,
  filterBookingsByCustomerOrCode
};
