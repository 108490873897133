import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "organizations",
  name: null,
  excerpt: "",
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  address: null,
  logo: null,
  taxes: [],
  tsh_number: '',
  tsh_value: 3.5,
  activePermissions: [],
  relationshipNames: ["owner", "reseller"],
  owner: {
    type: "users",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  billing_entity_type: BILLING_ENTITY_TYPE_COMPANY,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_company_name: null,
  billing_firstname: "N/A",
  billing_lastname: "N/A",
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
  clover_ecom_public_token: null,
  clover_ecom_private_token: null,
};
