export const PHONE_TYPE_MOBILE = "MOBILE";
export const PHONE_TYPE_HOME = "HOME";
export const PHONE_TYPE_OFFICE = "OFFICE";
export const PHONE_TYPE_BUSINESS = "BUSINESS";
export const PHONE_TYPE_FAX = "FAX";
export const PHONE_TYPE_PAGER = "PAGER";
export const PHONE_TYPE_OTHER = "OTHER";

export const phoneTypesOptions = {
  [PHONE_TYPE_MOBILE]: "MOBILE",
  [PHONE_TYPE_HOME]: "HOME",
  [PHONE_TYPE_OFFICE]: "OFFICE",
  [PHONE_TYPE_BUSINESS]: "BUSINESS",
  [PHONE_TYPE_FAX]: "FAX",
  [PHONE_TYPE_PAGER]: "PAGER",
  [PHONE_TYPE_OTHER]: "OTHER",
};

export const SPOT_STATUS_AVAILABLE = "AVAILABLE";
export const SPOT_STATUS_NOT_AVAILABLE = "NOT_AVAILABLE";
export const SPOT_STATUS_PARTIALY_AVAILABLE = "PARTIALY_AVAILABLE";

export const spotStatusOptions = {
  [SPOT_STATUS_AVAILABLE]: "AVAILABLE",
  [SPOT_STATUS_NOT_AVAILABLE]: "NOT_AVAILABLE",
  [SPOT_STATUS_PARTIALY_AVAILABLE]: "PARTIALY_AVAILABLE",
};

export const SPOT_CONDITION_CLEAN = "CLEAN";
export const SPOT_CONDITION_DIRTY = "DIRTY";

export const SPOTS_CONDITIONS = [
  SPOT_CONDITION_CLEAN,
  SPOT_CONDITION_DIRTY
];

export const spotConditionOptions = {
  [SPOT_CONDITION_CLEAN]: "CLEAN",
  [SPOT_CONDITION_DIRTY]: "DIRTY",
};

export const FORM_TYPE_DEFAULT = "DEFAULT";
export const FORM_TYPE_LAND = "LAND";
export const FORM_TYPE_PROPERTY = "PROPERTY";
export const FORM_TYPE_SEASONAL = "SEASONAL";

export const FORM_TYPES = [
  FORM_TYPE_DEFAULT,
  FORM_TYPE_LAND,
  FORM_TYPE_PROPERTY,
  FORM_TYPE_SEASONAL
];

export const TENT = "TENT";
export const TENT_TRAILER = "TENT_TRAILER";
export const TRAILER = "TRAILER";
export const VAN = "VAN";
export const MOTORHOME = "MOTORHOME";

export const ALLOWED_CUSTOMER_EQUIPMENT = [
  TENT,
  TENT_TRAILER,
  TRAILER,
  VAN,
  MOTORHOME,
];

export const BOOKING_STATUS_DRAFT = "DRAFT";
export const BOOKING_STATUS_PENDING = "DRAFT";
export const BOOKING_STATUS_GUARANTEED = "GUARANTEED";
export const BOOKING_STATUS_NOT_GUARANTEED = "NOT_GUARANTEED";
export const BOOKING_STATUS_FINISHED = "FINISHED";
export const BOOKING_STATUS_OUT_OF_SERVICE = "OUT_OF_SERVICE";
export const BOOKING_STATUS_ARRIVED = "ARRIVED";
export const BOOKING_STATUS_CANCELED = 'CANCELED';

export const BOOKING_STATUS = [
  BOOKING_STATUS_DRAFT,
  BOOKING_STATUS_PENDING,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
  BOOKING_STATUS_FINISHED,
  BOOKING_STATUS_OUT_OF_SERVICE,
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_CANCELED
];

export const QUERY_ACTIONS_VIEW = "view";
export const QUERY_ACTIONS_EDIT = "edit";
export const QUERY_ACTIONS_ADD = "add";
export const QUERY_ACTIONS_CONFIRM = "confirm";

export const POLICIES_TYPE_PAYMENTS = 'PAYMENTS';
export const POLICIES_TYPE_CANCELLATION = 'CANCELLATION';
export const POLICIES_TYPE_GUARANTEE = 'GUARANTEE';

export const POLICIES_TYPES = [
  POLICIES_TYPE_CANCELLATION,
  POLICIES_TYPE_GUARANTEE,
  POLICIES_TYPE_PAYMENTS,
];


export const POLICIES_PAYMENT_TYPES_AFTER_CREATE = 'AFTER_CREATE';
export const POLICIES_PAYMENT_TYPES_AFTER_CANCEL = 'AFTER_CANCEL';
export const POLICIES_PAYMENT_TYPES_AFTER_ARRIVE = 'AFTER_ARRIVE';
export const POLICIES_PAYMENT_TYPES_BEFORE_ARRIVE = 'BEFORE_ARRIVE';
export const POLICIES_PAYMENT_TYPES_BEFORE_DEPARTURE = 'BEFORE_DEPARTURE';
export const POLICIES_PAYMENT_TYPES_AFTER_DEPARTURE = 'AFTER_DEPARTURE';

export const POLICIES_PAYMENT_TYPES = [
  POLICIES_PAYMENT_TYPES_AFTER_CREATE,
  POLICIES_PAYMENT_TYPES_AFTER_CANCEL,
  POLICIES_PAYMENT_TYPES_BEFORE_ARRIVE,
  POLICIES_PAYMENT_TYPES_AFTER_ARRIVE,
  POLICIES_PAYMENT_TYPES_BEFORE_DEPARTURE,
  POLICIES_PAYMENT_TYPES_AFTER_DEPARTURE,
];

export const POLICIES_GUARANTEE_TYPES = [
  POLICIES_PAYMENT_TYPES_AFTER_CREATE,
  POLICIES_PAYMENT_TYPES_BEFORE_ARRIVE,
];

export const PAYMENT_TYPES_PERCENT = 'PERCENT';
export const PAYMENT_TYPES_DOLLAR = 'DOLLAR';
export const PAYMENT_TYPES_NIGHT = 'NIGHT';

export const PAYMENT_TYPES = [
  PAYMENT_TYPES_PERCENT,
  PAYMENT_TYPES_DOLLAR,
  PAYMENT_TYPES_NIGHT,
];


export const PRICING_SEASON_TYPES_PERCENT = 'PERCENT';
export const PRICING_SEASON_TYPES_DOLLAR = 'DOLLAR';
export const PRICING_SEASON_TYPES = [
  PRICING_SEASON_TYPES_PERCENT,
  PRICING_SEASON_TYPES_DOLLAR,
];


export const PAYMENT_METHOD_CLOVER = "CLOVER";
export const PAYMENT_METHOD_CASH = "CASH";
export const PAYMENT_METHOD_DEBIT = "DEBIT";
export const PAYMENT_METHOD_VISA = "VISA";
export const PAYMENT_METHOD_MASTERCARD = "MASTERCARD";
export const PAYMENT_METHOD_AMEX = "AMEX";
export const PAYMENT_METHOD_E_TRANSFERT = "E_TRANSFERT";
export const PAYMENT_METHOD_CHECK = "CHECK";
export const PAYMENT_METHOD_OTHER = "OTHER";

export const paymentMethodsOptions = {
  PAYMENT_METHOD_CLOVER: "CLOVER",
  PAYMENT_METHOD_CASH: "CASH",
  PAYMENT_METHOD_DEBIT: "DEBIT",
  PAYMENT_METHOD_VISA: "VISA",
  PAYMENT_METHOD_MASTERCARD: "MASTERCARD",
  PAYMENT_METHOD_AMEX: "AMEX",
  PAYMENT_METHOD_E_TRANSFERT: "E_TRANSFERT",
  PAYMENT_METHOD_CHECK: "CHECK",
  PAYMENT_METHOD_OTHER: "OTHER",
};
