export default {
  SALES_INVOICES_LIST: "Liste des facturations",
  SALES_INVOICES: "Factures",
  SALES_INVOICE: "Facture",
  SALES_INVOICES_LIST_SHORT: "Liste des factures",
  SALES_INVOICES_CODE_LABEL: "Nº de facture",
  SALES_INVOICES_FOLIOS_LIST: "Liste des folios",
  FOLIOS_CODE_LABEL: "Nº de folio",
  SALES_INVOICES_TRANSFERTS_LIST: "Liste des paiements",
  ADD_SALES_INVOICE: "Ajouter une facture",
  ADD_SALES_INVOICE_ITEM: "Ajouter une ligne",
  SALES_INVOICE_ADDED: "Facture ajoutée",
  ASSOCIATED_BOOKING: "Numéros de réservation associés",
  OWNER: "Propriétaire",
  EDIT_SALES_INVOICE: "Modifier une facture",
  SALES_INVOICE_UPDATED: "Facture mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_SALES_INVOICE_ITEM: "Supprimer la ligne",
  UPDATE_SALES_INVOICE_ITEM: "Modifier la ligne",
  SALES_INVOICE_ITEM_DELETED: "Ligne supprimée",
  SALES_INVOICE_ITEM_ADDED: "Ligne ajoutée",
  SALES_INVOICE_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  PAY: "Payer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  SALES_INVOICE_SENT: "Facture envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir le camping",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_SALES_INVOICE: "Supprimer cette facture ?",
  SALES_INVOICE_DELETED: "Facture supprimée",
  CANCEL_SALES_INVOICE_QUESTION: "Annuler la facture ?",
  SALES_INVOICE_CANCELED: "Facture annulée",
  SALES_INVOICE_NOT_FOUND: "Cette réservation semble ne pas avoir de facture, veuillez lui en créer une avant de continuer.",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  SALES_INVOICE_VALIDATED: "Facture à payer",
  INVOICE_STATUS_VALIDATED: "À Payer",
  INVOICE_STATUS_CANCELLED: "Annulée",
  INVOICE_STATUS_PAID: "Payée",
  INVOICE_STATUS_PARTIALLY_PAID: "Partiellement payée",
  INVOICE_STATUS_REFUND: "À Rembourser",
  INVOICE_STATUS_DRAFT: "En cours",
  VIEW_ORDER: "Voir la commande",
  VIEW_SALES_INVOICE: "Détails facturation",
  CLOSE_FOLIO: "Fermer le folio",
  CUSTOMER_NAME: "Nom du client",
  MODIFY: "Modifier",
  CHANGE: "Changer",
  // region couriel facture
  INVOICE_EMAIL_TITLE: "Notification par couriel",
  INVOICE_EMAIL_HTML: "Voulez vous envoyer cette facture par couriel au client ?",
  INVOICE_EMAIL_SUCCESS: "La facture a été envoyée par couriel au client.",
  // endregion

  // region remboursement/payer la diff
  REFUND_LABEL: "Rembourser la différence: {amount}",
  CONFIRM_REFUND: "Confirmer le remboursement.",
  REFUND_SUCCESS: "Le client a bien été remboursée",
  REFUND_MESSAGE_BODY: "Vous êtes sur le point de rembourser {amount} au client <span class='text-muted'>{customer_name} | {customer_email}.</span>",

  PAIEMENT_DUE_LABEL: "Payer la différence: {amount}",
  CONFIRM_PAIEMENT_DUE: "Confirmer le paiement du restant.",
  PAIEMENT_DUE_SUCCESS: "Le client a bien été débité.",
  PAYMENT_MESSAGE_BODY: "Vous êtes sur le point faire payer {amount} de dû au client <span class='text-muted'>{customer_name} | {customer_email}.</span>",

  SALES_INVOICE_DUE_PAYMENT: "Vous devez un remboursement à ce client.</br></br> Il doit se faire rembourser {0} en tout.<br/><br/><a href='{1}'>Voir la facture</a>",
  SALES_INVOICE_CANCELLING_PAYMENT: "<p>Ce client a déja payé sa réservation.</p><p>Il devra se faire rembourser {0} en tout.<p/><p><a href='{1}'>Voir la facture</a></p>",
  SALES_INVOICE_REMAINING_PAYMENT: "<p>Ce client n'a pas totalement réglé sa facture.</p><p>Il lui reste {0} à payer.</p><p><a href='{1}'>Voir la facture</a></p>"
  // endregin
};
