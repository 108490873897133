<template>
  <div>
    <div class="page-wrapper-table">
      <div class="radio-tabs">
        <radio-tab-selector
        :optionsTabs="optionsTabs"
        @update:selected="handleSelectedOption"
        />
      </div>
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="bookingSearchCriteria.query"
            class="search"
            max-width="220"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <!-- region les dates -->
          <el-select
            v-model="selectedMonth"
            @change="onCalendarMonthChange"
            :placeholder="$t('DATES.TODAY')"
          >
            <el-option :label="$t('DATES.TODAY')" value="0"></el-option>
            <el-option
              :label="$t('DATES.PLUS_31_DAYS')"
              value="31"
            ></el-option>
            <el-option
              :label="$t('DATES.JANUARY')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('DATES.FEBRUARY')"
              value="2"
            ></el-option>
            <el-option :label="$t('DATES.MARCH')" value="3"></el-option>
            <el-option :label="$t('DATES.APRIL')" value="4"></el-option>
            <el-option :label="$t('DATES.MAY')" value="5"></el-option>
            <el-option :label="$t('DATES.JUNE')" value="6"></el-option>
            <el-option :label="$t('DATES.JULY')" value="7"></el-option>
            <el-option
              :label="$t('DATES.AUGUST')"
              value="8"
            ></el-option>
            <el-option
              :label="$t('DATES.SEPTEMBER')"
              value="9"
            ></el-option>
            <el-option
              :label="$t('DATES.OCTOBER')"
              value="10"
            ></el-option>
            <el-option
              :label="$t('DATES.NOVEMBER')"
              value="11"
            ></el-option>
            <el-option
              :label="$t('DATES.DECEMBER')"
              value="12"
            ></el-option>
          </el-select>

          <base-input class="dates" placeholder="Dates">
            <flat-picker
              :config="{
                allowInput: true,
                mode: 'range',
                locale: flatPickrLocale,
              }"
              class="form-control datepicker"
              v-model="dateRange"
              placeholder="Dates"
            >
            </flat-picker>
          </base-input>
          <!-- region status booking -->
          <el-select
            v-model="bookingSearchCriteria.statusModel"
            :filterable="true"
            :multiple="true"
            :clearable="true"
            collapse-tags
            @change="onStatusChange"
            :placeholder="$t('COMMON.STATUS')"
          >
            <el-option
              :label="$t(`BOOKINGS.${status}`)"
              :value="status"
              v-for="status in bookingsStatusOptions"
              :key="status"
            />
          </el-select>
          <!-- endregion -->

          <!-- region filtre categorie -->
          <spot-category-selector
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
              :showAll="false"
              :multiple="true"
              @spotCategoryChanged="(spotCategories) => (selectedSpotCategories = spotCategories)"
          />
          <!-- endregion-->
          <el-button
            class="reset"
            type="text"
            icon="far fa-redo-alt"
            @click="resetFilters"
          >
            <span>{{ $t("COMMON.RESET_FILTERS") }}</span>
          </el-button>
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <div class="table-loading" slot="empty" v-show="loading">
          <img src="/img/loading.gif" />
        </div>

        <div
          class="page-wrapper-table-body-inner"
          v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }"
        >
          <el-table
            class="align-items-center table-flush clickable"
            header-row-class-name="thead-light"
            :data="bookings"
            @sort-change="sortChange"
            @row-click="selectRow"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
          >
            <el-table-column
              :label="$t('BOOKINGS.BOOKING_CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            />
            <el-table-column
              :label="$t('SPOTS.SPOT_NUMBER')"
              prop="spot.code"
              sortable="custom"
              min-width="200"
            />
            <el-table-column
              :label="$t('COMMON.CATEGORY')"
              prop="spot.category.name"
              min-width="200"
            />
            <el-table-column
              :label="$t('COMMON.CUSTOMER')"
              prop="client.name"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span>
                  {{
                    (row.customer?.firstname ?? "") +
                    " " +
                    (row.customer?.lastname ?? "")
                  }}
                </span>
                <small>
                  {{ row.customer?.email }}
                </small>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('BOOKINGS.DURATION')"
              prop="duration"
              min-width="120"
            />
            <el-table-column
              :label="$t('BOOKINGS.PRICE_PER_DAY')"
              min-width="140"
            >
              <template v-slot="{ row }">
                {{ $formatCurrency(row.pricing_data?.avg ?? 0) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="end_at"
              sortable="custom"
              :label="$t('BOOKINGS.ARRIVING_AT')"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $timeZoneDateFormat(row.start_at, "LL") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="end_at"
              sortable="custom"
              :label="$t('BOOKINGS.LEAVING_AT')"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $timeZoneDateFormat(row.end_at, "LL") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('COMMON.STATUS')" min-width="140">
              <template v-slot="{ row }">
                {{ $t(`BOOKINGS.${row.status}`) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item v-if="canCheckInBooking(row)">
                      <a
                        type="text"
                        @click="checkInBooking(row)"
                        class="table-action check-in"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-arrival.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("BOOKINGS.CHECK_IN") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="canCheckOutBooking(row)">
                      <a
                        type="text"
                        @click="checkOutBooking(row)"
                        class="table-action check-out"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-exit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("BOOKINGS.CHECK_OUT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_BOOKINGS)"
                    >
                      <a
                        type="text"
                        @click="editBooking(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item> -->
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_BOOKINGS)"
                    >
                      <a
                        type="text"
                        @click="deleteBooking(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import {
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
  BOOKING_STATUS_FINISHED,
  BOOKING_STATUS_PENDING,
  BOOKING_STATUS_CANCELED,
} from "../../../../../constants/common";

import {
  STATUS_ARRIVED,
  STATUS_NOT_GUARANTEED,
  STATUS_CANCELED,
  statusOptions,
  STATUS_DRAFT,
  STATUS_OUT_OF_SERVICE,
  STATUS_GUARANTEED,
  ALL_STATUS,
} from "@/constants/bookings";
import SpotCategorySelector from "@/components/SpotCategorySelector.vue";
import RadioTabSelector from "@/components/RadioTabSelector.vue";
import { French } from "flatpickr/dist/l10n/fr.js";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "booking-list-table",

  components: {
    SpotCategorySelector,
    BasePagination,
    // OrganizationSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    RadioTabSelector,
    flatPicker,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterBookingableType: {
      type: String,
      default: null,
      description: "Bookingable class",
    },
    filterBookingableId: {
      type: String,
      default: null,
      description: "Bookingable id",
    },
  },
  created(){
    this.optionsTabs = [
      { label: "Tous", value: "all" },
      { label: "Arrivées", value: "arrived" },
      { label: "Départs", value: "departs" },
      { label: "Résidents", value: "residents" },
    ];
  },

  data() {
    const StatusOptions = {
      [STATUS_DRAFT]: STATUS_DRAFT,
      [STATUS_GUARANTEED]: STATUS_GUARANTEED,
      [STATUS_NOT_GUARANTEED]: STATUS_NOT_GUARANTEED,
      [STATUS_CANCELED]: STATUS_CANCELED,
      [STATUS_DRAFT]: STATUS_DRAFT,
      [STATUS_OUT_OF_SERVICE]: STATUS_OUT_OF_SERVICE,
      [STATUS_CANCELED]: STATUS_CANCELED,
      [STATUS_DRAFT]: STATUS_DRAFT,
      [STATUS_OUT_OF_SERVICE]: STATUS_OUT_OF_SERVICE,
    };

    return {
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      bookingSearchCriteria: {
        status: [],
        query: null,
        statusModel: null,
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      bookingsStatusOptions: StatusOptions,
      bookings: [],
      loading: true,
      selectedOrganization: null,
      selectedSpotCategories: null,
      selectedOptionTabId: 'all',
      selectedMonth: null,
      dateRange: null,
      flatPickrLocale: French,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    bookingSearchCriteria: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotCategories: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOptionTabId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedMonth: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    resetFilters() {
      this.selectedMonth = null;
      this.dateRange = null;
    },
    onCalendarMonthChange(selectedMonth) {
      selectedMonth = Number.parseInt(selectedMonth);
      let _start_at = null;
      let _end_at = null;
      if (selectedMonth >= 1 && selectedMonth <= 12) {
        _start_at = moment()
          .month(selectedMonth - 1)
          .startOf("month")
          .toISOString();
        _end_at = moment()
          .month(selectedMonth - 1)
          .endOf("month")
          .toISOString();
      } else if (selectedMonth > 12) {
        // move forward
        _end_at = moment(this.calendarFilter.end_at)
          .utc()
          .add(selectedMonth, "days")
          .toISOString();
        _start_at = moment(this.calendarFilter.start_at)
          .utc()
          .add(selectedMonth, "days")
          .toISOString();
      } else if (selectedMonth === 0) {
        let currentDate = moment().utc();
        _start_at = currentDate
          .subtract(5, "days")
          .toISOString();
        _end_at = currentDate.add(30, "days").toISOString();
        this.calendarRenderKey++;
      }
      if(_start_at && _end_at){
        this.dateRange = [_start_at, _end_at];
        console.log(this.dateRange);
      }
    },
    handleSelectedOption(value) {
      this.selectedOptionTabId = value; // Met à jour la valeur dans le parent
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    getTodayDateRange() {
      const now = new Date();

      // Début de la journée (minuit)
      let month = now.getMonth() < 9 ? '0'+(now.getMonth()+1) : (now.getMonth()+1);
      const startOfDay = now.getFullYear()+'-'+month+'-'+now.getDate()+' '+'00:00:00';

      // Fin de la journée (23:59:59)
      const endOfDay = now.getFullYear()+'-'+month+'-'+now.getDate()+' '+'23:59:59';

      return [startOfDay, endOfDay];
    },
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {},
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,spot,spot.category,customer,order,orderItem,createdBy",
        };

        if (this.bookingSearchCriteria?.status?.length) {
          params.filter.in_status = this.bookingSearchCriteria.status;
        }

        if (this.bookingSearchCriteria.query) {
          params.filter.search = this.bookingSearchCriteria.query;
        }

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if(this.selectedSpotCategories?.length) {
          params.filter.in_categories = this.selectedSpotCategories ;
        }

        if(this.selectedOptionTabId) {
          const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
          params = {
            ...(this.sort ? { sort: this.sort } : {}),
            filter: {
              ...(vDateRange.length == 2
              ? {
                  start_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
            },
            page: {
              number: this.pagination.currentPage,
              size: this.pagination.perPage,
            },
            include: "organization,spot,spot.category,customer,order,orderItem,createdBy",
          };
          if(this.selectedOptionTabId == 'arrived'){
            params.filter.in_status = ['ARRIVED', 'GUARANTEED', 'NOT_GUARANTEED'];
            params.filter.start_at = this.getTodayDateRange();
          }
          if(this.selectedOptionTabId == 'departs'){
            params.filter.status = 'FINISHED';
            params.filter.end_at = this.getTodayDateRange();
          }
          if(this.selectedOptionTabId == 'residents'){
            params.filter.status = 'ARRIVED';
          }
        }

        await this.$store.dispatch("bookings/list", params);
        this.bookings = this.$store.getters["bookings/list"];
        this.total = this.$store.getters["bookings/listTotal"];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    /**
     *
     * @param {array} newStatus
     */
    onStatusChange(newStatus) {
      this.bookingSearchCriteria.status = newStatus;
    },

    canCheckInBooking(booking) {
      return (
        !!booking &&
        [BOOKING_STATUS_GUARANTEED, BOOKING_STATUS_NOT_GUARANTEED].includes(
          booking.status
        )
      );
    },

    canCheckOutBooking(booking) {
      return !!booking && [BOOKING_STATUS_ARRIVED].includes(booking.status);
    },

    async deleteBooking(id) {
      const confirmation = await swal.fire({
        text: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("bookings/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    checkInBooking(booking) {
      this.$emit("onCheckInBooking", { booking: booking, invoice: null });
    },

    checkOutBooking(booking) {
      this.$emit("onCheckOutBooking", { booking: booking, invoice: null });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    selectRow(row, ...args) {
      const target = args[1]?.target;

      if (target && target.classList.contains("fa-ellipsis-v")) {
        return false;
      }

      this.$emit("onViewBooking", row);
    },
  },
};
</script>
<style lang="scss" scoped>
  .reset{

  }
</style>
