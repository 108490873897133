export default {
  SEASONS: "Saisons",
  PRICING_SEASONS_LIST: "Liste des saisons",
  ADD_PRICING_SEASON: "Ajouter une saison",
  PRICING_SEASON_DETAILS: "Détails saison",
  PRICING_SEASON_ADDED: "Saison ajouté",
  EDIT_PRICING_SEASON: "Modifier une saison",
  PRICING_SEASON_UPDATED: "Saison mis à jour",
  DELETE_THIS_PRICING_SEASON: "Voulez-vous supprimer cette saison sélectionné ?",
  DELETE_PRICING_SEASON_SUCCESS: "La suppression de la saison a été effectuée avec succès",
  PRICING_SEASON_DELETED: "Saison supprimé",
  PRICING_SEASON_CLEANED: "",
  CLEAN_THIS_PRICING_SEASON: "",
  NO_DATA: "",
  PRICE: "Tarif",
  PRICE$: "Prix",
  SELECT_PRICE: "Sélection à faire parmi tout les tarif existants",
  SELECT_START_DATE: "Sélectionner la date du début de la saison",
  SELECT_END_DATE: "Sélectionner la date de le fin de la saison",
  WEEK_PRICE: "Prix en semaine",
  WEEKEND_PRICE: "Prix en weekend",
  SELECT_TYPE_LABEL: "Variation en Dollar ($) ou Pourcentage (%)",
  PRICE_LABEL: "Variation Plus + ou Moin -",
  DOLLAR: "Dollar",
  PERCENT: "Pourcentage",
  RESTRICTION: "Restriction",
  MINIMUM_STAY: "Séjour minimum",
  MAXIMUM_STAY: "Séjour maximum",
  MINIMUM_DAY_BEFORE: "Minimum de jour avant l'arrivée",
  MAXIMUM_DAY_BEFORE: "Maximum de jour avant l'arrivée",
  ACTIVE_FROM: "Actif à partir de",
  PRICING_MODULE: "Gestion de prix",
  CREATE_SEASON: "Créer une saison",
  SEASON_YEAR: "Année de la saison",
  NAME: 'Nom de la saison',
  PRICE_NAME: "Nom de tarif",
  PRICE_TYPE: "Type de tarif",
  PLACE_TYPE: "Type d'emplacement",
  DATE: "Date de début ou période",
  standard: "Tarif normal",
  derived: "Tarif dérivé (%)",
};
