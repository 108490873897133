<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ $t("REPORTS.STATISCTICS_LIST") }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ $t("REPORTS.STATISCTICS_LIST") }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
        <statistics-list-table
          :key="renderKey * 100"
        />
  
      </div>

    </div>
  </template>
  <script>
  import swal from "sweetalert2";
  import { Button } from "element-ui";

  import {
    QUERY_ACTIONS_VIEW,
  } from "@/constants/common";

  import { mapGetters } from "vuex";
  import requestErrorMixin from "@/mixins/request-error-mixin";
  import StatisticsListTable from "./partials/StatisticsListTable.vue";


  
  export default {
    layout: "DashboardLayout",
  
    components: {
       StatisticsListTable,   
    },
  
    mixins: [requestErrorMixin],
  
    computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },
  
    watch: {},
  
    data() {
      const locationId = this.$route.query.id;
      const action = this.$route.query.action;
      return {
        renderKey: 1,
      };
    },
  
    methods: {
 
    },
  };
  </script>
  