import moment from "moment-timezone";

const utcDatePlugin = {
  install: (Vue) => {
    Vue.prototype.$convertDateToUtc = (value) =>
    {
      const userTimezone = moment.tz.guess();
      const now = moment(); // Heure actuelle

      const dateWithTime = moment(value)
          .hour(now.hour())
          .minute(now.minute())
          .second(now.second())

      return moment.tz(dateWithTime, userTimezone).utc().format();

      // const userTimezone = moment.tz.guess();
      // const momentDate = moment.tz(value, userTimezone).utc();
      // return momentDate.toISOString();
    };
  },
};

export default utcDatePlugin;
