<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ `${$t("PRICING_SEASONS.PRICING_SEASONS_LIST")}` }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ `${$t("PRICING_SEASONS.PRICING_SEASONS_LIST")}` }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openPricingSeasonCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_PRICING_SEASONS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{ $t("PRICING_SEASONS.ADD_PRICING_SEASON") }}</span>
          </base-button>
        </div>
      </div>

      <pricing-seasons-list-table
        @onViewPricingSeason="openPricingSeasonViewModal"
        @onEditPricingSeason="openPricingSeasonEditModal"
        @onDeletePricingSeason="deletePricingSeason"
        @onAddPricingSeason="openPricingSeasonCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPricingSeasonModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPricingSeasonModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PRICING_SEASON_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closePricingSeasonModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>{{ $t("PRICING_SEASONS.PRICING_SEASON_DETAILS") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-pricing-season-component
              v-if="openPricingSeason"
              :pricing-season-id="openPricingSeason.id"
              @onEditPricingSeason="openPricingSeasonEditModal"
              @onDeletePricingSeason="deletePricingSeason"
              @onReopen="reopenPricingSeason"
              @onCleanPricingSeason="cleanPricingSeason"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPricingSeasonModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPricingSeasonModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'SPOT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closePricingSeasonModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("PRICING_SEASONS.EDIT_PRICING_SEASON") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-pricing-season-component
              v-if="openPricingSeason"
              :pricingSeasonId="openPricingSeason.id"
              @onViewPricingSeason="openPricingSeasonViewModal"
              @onClosePricingSeasonModal="closePricingSeasonModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPricingSeasonModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPricingSeasonModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'SPOT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closePricingSeasonModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("PRICING_SEASONS.ADD_PRICING_SEASON") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-pricing-season-component
              @onViewPricingSeason="openPricingSeasonViewModal"
              @onClosePricingSeasonModal="closePricingSeasonModal"
            />
          </div>
        </div>
      </div>
    </div>
    <select-an-organization-message v-else/>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import permissions from "@/constants/permissions";
import PricingSeasonsListTable from "./partials/PricingSeasonsListTable.vue";
import EditPricingSeasonComponent from "./components/EditPricingSeasonComponent.vue";
import AddPricingSeasonComponent from "./components/AddPricingSeasonComponent.vue";
import ViewPricingSeasonComponent from "./components/ViewPricingSeasonComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import { mapGetters } from "vuex";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    PricingSeasonsListTable,
    EditPricingSeasonComponent,
    AddPricingSeasonComponent,
    ViewPricingSeasonComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const pricingSeasonId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPricingSeasonModalOpened = false;
    let isEditPricingSeasonModalOpened = false;
    let isAddPricingSeasonModalOpened = false;
    let openPricingSeason = null;
    if (pricingSeasonId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPricingSeasonModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPricingSeasonModalOpened = true;
      }
      openPricingSeason = { id: pricingSeasonId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPricingSeasonModalOpened = true;
    }
    return {
      isViewPricingSeasonModalOpened: isViewPricingSeasonModalOpened,
      isEditPricingSeasonModalOpened: isEditPricingSeasonModalOpened,
      isAddPricingSeasonModalOpened: isAddPricingSeasonModalOpened,
      openPricingSeason: openPricingSeason,
      renderKey: 1,
    };
  },

  mounted() {},

  methods: {
    openPricingSeasonCreateModal() {
      this.closePricingSeasonModal();
      this.isAddPricingSeasonModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingSeasons",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openPricingSeasonViewModal(pricingSeason, reRender = false) {
      this.closePricingSeasonModal();
      this.openPricingSeason = pricingSeason;
      this.isViewPricingSeasonModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      console.log('openPricingSeasonViewModal', this.openPricingSeason);

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingSeasons",
          query: { id: this.openPricingSeason.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openPricingSeasonEditModal(pricingSeason) {
      this.closePricingSeasonModal();
      this.openPricingSeason = pricingSeason;
      this.isEditPricingSeasonModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingSeasons",
          query: { id: this.openPricingSeason.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closePricingSeasonModal() {
      this.isAddPricingSeasonModalOpened = false;
      this.isViewPricingSeasonModalOpened = false;
      this.isEditPricingSeasonModalOpened = false;
      this.openPricingSeason = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingSeasons",
          query: {},
        }).href
      );
    },

    reopenPricingSeason(pricingSeason) {
      this.closePricingSeasonModal();
      this.renderKey++;
      this.openPricingSeasonEditModal(pricingSeason);
    },

    async deletePricingSeason(pricingSeason) {
      const confirmation = await swal.fire({
        text: this.$t("PRICING_SEASONS.DELETE_THIS_PRICING_SEASON"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      const deleteConform = await swal.fire({
        text: this.$t("PRICING_SEASONS.DELETE_PRICING_SEASON_SUCCESS"),
        type: "info",
        customClass: {
          popup: "delete-confirm",
        },
        buttonsStyling: false,
        showCancelButton: false,
        confirmButtonText: this.$t("COMMON.END"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("pricingSeasons/destroy", pricingSeason.id);
          this.renderKey++;
          this.closePricingSeasonModal();
          deleteConform;
          /* this.$notify({
            type: "success",
            message: this.$t("PRICING_SEASONS.PRICING_SEASON_DELETED"),
          }); */
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cleanPricingSeason(pricingSeason) {
      const confirmation = await swal.fire({
        title: this.$t("PRICING_SEASONS.CLEAN_THIS_PRICING_SEASON"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("pricingSeasons/clean", pricingSeason.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("PRICING_SEASONS.PRICING_SEASON_CLEANED"),
          });
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
