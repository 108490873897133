<template>
  <modal :show="showModal" modal-classes="kw-modal file-view" size="lg">
    <template slot="header">
      <h2>{{ $t("FILES.VIEW_FILE") }}</h2>
      <button @click="closeModal()">
        <i class="fal fa-times"></i>
      </button>
    </template>
    <div class="file-view-actions">
      <div class="file-view-actions-title">
        <a target="_blank" :href="fileData.url">
          {{ `${fileData.name}` }}
        </a>
      </div>
      <div class="file-view-actions-list">
        <button class="btn edit" @click="onEditFile">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn delete" @click="onDeleteFile">
          <i class="fas fa-trash"></i>
        </button>
        <a target="_blank" :href="fileData.url" class="btn download">
          <i class="fa fa-download"></i>
          {{ $t("FILES.DOWNLOAD") }}
        </a>
      </div>
      <div class="elite-tabs-wrapper">
        <tabs
          fill
          class="flex-column flex-md-row"
          tabNavWrapperClasses="nav-wrapper"
          tabNavClasses="nav  elite-tabs"
          value="global"
        >
          <tab-pane title="global" id="1" :active="true">
            <span slot="title">
              <i class="ni ni-cloud-upload-96"></i>
              {{ $t("COMMON.GLOBAL") }}
            </span>
            <file-view-modal-global
              :file="fileData"
              @fileUpdated="fileUpdated"
              @editFile="onEditFile"
              @deleteFile="onDeleteFile"
            />
          </tab-pane>

          <tab-pane
            title="logs"
            id="999"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-file"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <file-view-modal-logs :file="fileData" />
          </tab-pane>
        </tabs>
      </div>
    </div>
    <template slot="footer">
      <button type="button" class="btn close" @click="closeModal()">
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import FileViewModalGlobal from "./FileViewModalGlobal.vue";
import FileViewModalLogs from "./FileViewModalLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    FileViewModalLogs,
    FileViewModalGlobal,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
      description: "Files linked object",
    },
    fileData: {
      type: Object,
      default: null,
      description: "File data",
    },
  },

  data() {
    return {
      tagsUpdated: false,
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh || this.tagsUpdated);
    },

    fileUpdated() {
      this.tagsUpdated = true;
      this.$emit("fileUpdated", true);
    },

    onEditFile() {
      this.$emit("editFile", this.fileData.id);
    },

    onDeleteFile() {
      this.$emit("deleteFile", this.fileData.id);
    },
  },
};
</script>
