<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <base-input class="dates" placeholder="Dates">
          <flat-picker
            :config="{
              allowInput: true,
              mode: 'range',
              locale: flatPickrLocale,
            }"
            class="form-control datepicker"
            v-model="dateRange"
            :placeholder="$t('COMMON.DATE')"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="list"
            show-summary
            :summary-method="getSummaries"
            :default-sort="{ prop: 'code', order: 'ascending' }"
            :loading="loading"
          
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.LOCATION')"
              prop="code"
              min-width="80"
            >
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="spot_name"
              min-width="100"
            />

            <el-table-column
              :label="$t('COMMON.PRICE_PER_NIGHT_AVERAGE')"
              prop="average_price_per_night"
              min-width="100"
              align="center"
              :formatter="(row, column, value) => formatNumber(value, 2, '$')"
            >
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.REVENUE')"
              prop="total_revenue"
              align="center"
              min-width="100"
              :formatter="(row, column, value) => formatNumber(value, 2, '$')"
            >
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.RESERVED')"
              prop="reservation_percentage"
              align="center"
              min-width="100"
              :formatter="(row, column, value) => formatNumber(value, 2, '$')"
            >
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NIGHTS_RESERVED')"
              prop="nights_reserved"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NIGHTS_TOTAL')"
              prop="nights_by_periode"
              min-width="80"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-binvoice"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @input="getList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";

import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "statistics-list-table",

  components: {
    BasePagination,
    flatPicker,
    OrganizationSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      list: [],
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      total: 0,
      pagination: {
        perPage: 3,
        currentPage: 1,
        perPageOptions: [3, 10, 50, 100, 500],
      },
      loading: true,
      dateRange: null,
      selectedOrganization: null,
      selectedLocations: null,
    };
  },

  computed: {
    // from() {
    //   return this.pagination.perPage * (this.pagination.currentPage - 1);
    // },

    // to() {
    //   let highBound = this.from + this.pagination.perPage;
    //   if (this.total < highBound) {
    //     highBound = this.total;
    //   }
    //   return highBound;
    // },

    from() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage;
    },
    to() {
      return Math.min(this.from + this.pagination.perPage, this.total);
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: true,
      deep: true,
    },

    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },

    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          // ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
            ...(vDateRange.length == 2
              ? {
                  dates_between: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations",
        };
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        await this.$store.dispatch("reportStatistics/list", params);

        this.list = this.$store.getters["reportStatistics/list"];
        this.total = this.$store.getters["reportStatistics/listTotal"];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    sortChange({ prop, order }) {
      this.sort = order === "ascending" ? prop : `-${prop}`;
      this.getList();
    },
    formatNumber(value, decimals = 2, symbol = "", locale = "en-US") {
      // Vérifie si la valeur est un nombre valide
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return "0";
      }

      // Formatage avec séparateur de milliers et décimales
      const formattedValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(numberValue);
      // Retourne la valeur formatée avec le symbole (si fourni)
      return symbol ? `${formattedValue} ${symbol}` : formattedValue;
    },

    getSummaries({ columns, data }) {
      const sums = [];

      const calculateSum = (prop) =>
        data.reduce((sum, row) => {
          const value = Number(row[prop]);
          return !isNaN(value) ? sum + value : sum;
        }, 0);

      const calculateAverage = (prop) => {
        const total = calculateSum(prop);
        const count = data.filter((row) => !isNaN(Number(row[prop]))).length;
        return count > 0 ? total / count : 0;
      };

      columns.forEach((column, index) => {
        const prop = column.property;

        if (index === 0) {
          sums[index] = this.$t("COMMON.TOTAL");
        } else if (prop === "spot_name") {
          sums[index] = ""; // Ignorer cette colonne
        } else if (prop === "average_price_per_night") {
          const average = calculateAverage(prop).toFixed(2);
          sums[index] = this.formatNumber(average, 2, "$");
        } else if (prop === "total_revenue") {
          const total = calculateSum(prop);
          sums[index] = this.formatNumber(total, 2, "$");
        } else if (prop === "reservation_percentage") {
          const average = calculateAverage(prop).toFixed(2);
          sums[index] = this.formatNumber(average, 2, "%");
        } else {
          const total = calculateSum(prop);
          sums[index] = this.formatNumber(total, 0, "");
        }
      });

      return sums;
    },
  },
};
</script>
