<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.DETAILS") }}</h3>
      <base-button
        class="elite-button add"
        @click="openSalesInvoiceItemModal()"
        type="button"
        v-if="salesInvoice.status === INVOICE_STATUS_DRAFT"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("SALES_INVOICES.ADD_SALES_INVOICE_ITEM") }}
        </span>
      </base-button>
    </div>

    <sales-invoice-view-details-sales-invoice-item-table
      :salesInvoice="salesInvoice"
      @onOpenSalesInvoiceItemModal="openSalesInvoiceItemModal"
    />

    <!-- region total et facturation -->
    <div class="total-wrapper" v-if="salesInvoice.pricing">
      <div class="total-wrapper-inner">
        <dl class="row sub-total">
          <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.pricing.subtotal) }}
          </dd>
        </dl>
        <dl class="row" v-if="salesInvoice.pricing.discounts.total">
          <dt>{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.pricing.discounts.total * -1) }}
          </dd>
          <dl
            class="row"
            v-for="(discount, index) in salesInvoice.pricing.discounts.details"
            v-bind:key="index"
          >
            <dt>{{ discount.name }}</dt>
            <dd>
              {{ $formatCurrency(discount.amount) }}
            </dd>
          </dl>
        </dl>
        <dl class="row" v-if="salesInvoice.pricing.taxes.total">
          <dt>{{ $t("COMMON.TAXES") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.pricing.taxes.total) }}
          </dd>
        </dl>
        <dl class="row"
            v-bind:key="index"
            v-for="(tax, index) in salesInvoice.pricing.taxes.details">
          <dt class="col-sm-6 text-muted">
            {{ tax.name }} <span v-if="tax.number">( {{ tax.number }} )</span>
          </dt>
          <dd>{{ $formatCurrency(tax.amount) }}</dd>
        </dl>
        <dl class="row total">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.pricing.total) }}
          </dd>
        </dl>
        <dl class="row total">
          <dt>{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.total_paied) }}
          </dd>
        </dl>
        <dl class="row total">
          <dt>{{ $t("COMMON.REMAINING_PAYMENT_AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(salesInvoice.total_remaining_payment) }}
          </dd>
        </dl>
      </div>
    </div>
    <!-- endregion -->

    <!-- Modal add slot -->
    <sales-invoice-view-details-sales-invoice-item-form
      :salesInvoice="salesInvoice"
      :salesInvoiceItem="salesInvoiceItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onCloseSalesInvoiceItemModal="closeSalesInvoiceItemModal"
      @onSubmitSalesInvoiceItemForm="handleSalesInvoiceItemFormSubmit"
      @onDeleteSalesInvoiceItem="deleteSalesInvoiceItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import SalesInvoiceViewDetailsSalesInvoiceItemForm from "./SalesInvoiceViewDetailsSalesInvoiceItemForm.vue";
import SalesInvoiceViewDetailsSalesInvoiceItemTable from "./SalesInvoiceViewDetailsSalesInvoiceItemTable.vue";
import defaultSalesInvoiceItem from "../defaultSalesInvoiceItem";

export default {
  name: "sales-invoice-view-details",

  components: {
    SalesInvoiceViewDetailsSalesInvoiceItemForm,
    SalesInvoiceViewDetailsSalesInvoiceItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: ["salesInvoice"],

  data() {
    return {
      salesInvoiceItem: cloneDeep(defaultSalesInvoiceItem),
      loading: false,
      showModal: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openSalesInvoiceItemModal(salesInvoiceItem = null) {
      if (!salesInvoiceItem) {
        this.salesInvoiceItem = cloneDeep(defaultSalesInvoiceItem);
      } else {
        this.salesInvoiceItem = cloneDeep(salesInvoiceItem);
      }
      this.showModal = true;
    },

    closeSalesInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleSalesInvoiceItemFormSubmit(salesInvoiceItemData) {
      if (!salesInvoiceItemData.id) {
        this.addSalesInvoiceItem(salesInvoiceItemData);
      } else {
        this.editSalesInvoiceItem(salesInvoiceItemData);
      }
    },

    async addSalesInvoiceItem(salesInvoiceItemData) {
      this.loading = true;
      try {
        salesInvoiceItemData.salesInvoice.id = this.salesInvoice.id;

        await this.$store.dispatch(
          "salesInvoiceItems/add",
          salesInvoiceItemData
        );
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editSalesInvoiceItem(salesInvoiceItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "salesInvoiceItems/update",
          salesInvoiceItemData
        );
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deleteSalesInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("salesInvoiceItems/destroy", id);
        this.$emit("salesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
