<template>
  <el-select
    v-model="mailModel"
    @change="mailChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="$t('COMMON.TEMPLATES_MAILS')"
    :filterable="filterable"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getMails"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_ORGANIZATIONS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="mail in mails"
      :key="mail.id"
      :value="mail.id"
      :label="`${mail.title}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "mail-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    mail: {
      type: String,
      default: null,
      description: "Mail id",
    },
    filterType: {
      type: String,
      default: null,
      description: "Type de mail",
    },
  },

  data() {
    return {
      mailModel: this.mail,
      mails: {},
      loading: false
    };
  },

  setup() {},

  created() {
    this.getMails(null, this.mails);
  },

  methods: {
    async getMails(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              mail_type: this.filterType,
            },
          };
        }

        await this.$store.dispatch("templatesMails/list", params);
        const mailsArr = await this.$store.getters[
          "templatesMails/list"
        ];
        this.mails = {};
        mailsArr.forEach((mail) => {
          this.mails[mail.id] = mail;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    mailChanged(mailId) {
      const mail = Object.values(this.mails).find(
        (item) => item.id === mailId
      );
      this.$emit("mailChanged", mailId, mail);
    },
  },

  watch: {
    mail(mail) {
      if (mail) {
        this.mailModel = mail;
        if (mail !== this.mailModel) {
          this.getMails(null, mail);
        }
      } else {
        this.mailModel = null;
      }
    }
  },
};
</script>
