import service from "@/store/services/pricing-seasons-service";

export const state = () => ({
  list: {},
  pricingSeason: {},
  meta: {},
  url: null,
  unavailableDates : [],
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, pricingSeason) => {
    state.pricingSeason = pricingSeason;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_UNAVAILABLE_DATES: (state, dates) => {
    state.unavailableDates = dates;
  },
};

export const actions = {

  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, { id, params = {} }) {
    return service.get({ id, params }, this.$axios).then((pricingSeason) => {
                    commit("SET_RESOURCE", pricingSeason);
                  });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((pricingSeason) => {
      commit("SET_RESOURCE", pricingSeason);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((pricingSeason) => {
      commit("SET_RESOURCE", pricingSeason);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  clean({ commit, dispatch }, params) {
    return service.clean(params, this.$axios).then((pricingSeason) => {
      commit("SET_RESOURCE", pricingSeason);
    });
  },

};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  pricingSeason: (state) => state.pricingSeason,
  url: (state) => state.url,
  // unavailableDates: (state) => state.unavailableDates,
  dropdown: (state) => {
    return state.list.map((pricingSeason) => ({
                        id: pricingSeason.id,
                        name: pricingSeason.name,
                      }));
  },
};

const pricingSeasons = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default pricingSeasons;
