var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.TEMPLATE_MAIL_INFOS")))])]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.templateMail.organization.id || _vm.globalOrganization.id,"filterable":true,"showAll":false,"disabled":!!_vm.globalOrganization.id},on:{"organizationChanged":(organizationId, organization) => {
              _vm.templateMail.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.TITLE')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.TITLE')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.templateMail.title),callback:function ($$v) {_vm.$set(_vm.templateMail, "title", $$v)},expression:"templateMail.title"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.title}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.SUBJECT')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.SUBJECT')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.templateMail.subject),callback:function ($$v) {_vm.$set(_vm.templateMail, "subject", $$v)},expression:"templateMail.subject"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.subject}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.FROM')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.FROM')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.templateMail.from),callback:function ($$v) {_vm.$set(_vm.templateMail, "from", $$v)},expression:"templateMail.from"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.from}})],1),_c('div',{staticClass:"tags-wrapper"},[_c('div',{staticClass:"form-wrapper full"},[_c('tags-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.TO')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.TO')},on:{"change":(mails) => {
              _vm.selectedUsersTo = mails;
            }},model:{value:(_vm.templateMail.recipients),callback:function ($$v) {_vm.$set(_vm.templateMail, "recipients", $$v)},expression:"templateMail.recipients"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.recipients}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('tags-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.CC')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.CC')},on:{"change":(mails) => {
              _vm.selectedUsersCc = mails;
            }},model:{value:(_vm.templateMail.cc),callback:function ($$v) {_vm.$set(_vm.templateMail, "cc", $$v)},expression:"templateMail.cc"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cc}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('tags-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.BCC')}`,"placeholder":_vm.$t('TEMPLATES_MAILS.BCC')},on:{"change":(mails) => {
              _vm.selectedUsersBcc = mails;
            }},model:{value:(_vm.templateMail.bcc),callback:function ($$v) {_vm.$set(_vm.templateMail, "bcc", $$v)},expression:"templateMail.bcc"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.bcc}})],1)]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('TEMPLATES_MAILS.TYPE')} (*)`,"placeholder":_vm.$t('TEMPLATES_MAILS.TYPE')}},[_c('types-mail-selector',{attrs:{"typesMail":_vm.templateMail.typesMail.id,"filterable":true,"showAll":false,"disabled":!_vm.globalOrganization.id,"filterOrganization":_vm.templateMail.organization.id},on:{"typesMailChanged":(typesMailId, typesMail) => {
              _vm.templateMail.typesMail.id = typesMailId;
              _vm.getTypeVariables(typesMailId);
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.typesMail}})],1),_c('div',{staticClass:"form-wrapper full type-variables-wrapper"},_vm._l((_vm.typeVariables),function(variable,key){return _c('div',{key:key,staticClass:"type-variables"},[_c('span',[_c('b',[_vm._v("["),_c('span',{key:key},[_vm._v(_vm._s(key))]),_vm._v("] :")])]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(variable))])])}),0),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('TEMPLATES_MAILS.CONTENT'),"placeholder":_vm.$t('TEMPLATES_MAILS.CONTENT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.templateMail.content),callback:function ($$v) {_vm.$set(_vm.templateMail, "content", $$v)},expression:"templateMail.content"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.content}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('button',{staticClass:"btn cancel",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.onCloseTemplateMail}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.templateMail.id ? _vm.$t("TEMPLATES_MAILS.EDIT_TEMPLATE_MAIL") : _vm.$t("TEMPLATES_MAILS.ADD_TEMPLATE_MAIL"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }