<template>
  <div class="kw-tabs-wrapper-content no-margin">
    <div class="all-infos">
      <div class="all-infos-text">
        <div class="all-infos-text-group">
          <h3>{{ $t("POLICIES.POLICY_INFORMATION_GENERAL") }}</h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.POLICY_NAME") }}</dt>
            <dd>
              {{ policy.name }}
            </dd>
          </dl>

          <dl class="row status">
            <dt>{{ $t("POLICIES.STATUS") }} :</dt>
            <dd :class="policy.status ? 'active' : 'inactive'">
              {{
                policy.status
                  ? $t("POLICIES.POLICY_ACTIVE")
                  : $t("POLICIES.POLICY_INACTIVE")
              }}
            </dd>
          </dl>

          <dl class="row">
            <dt>{{ $t("POLICIES.POLICY_TYPE_DETAILS") }}</dt>
            <dd>
              {{ $t(`POLICIES.POLICY_${policy.policies_type}`) }}
            </dd>
          </dl>

          <dl class="row" v-if="isPaymentType">
            <dt>{{ $t("POLICIES.GUARANTEES_POLICY") }}</dt>
            <dd v-for="guarantee in policy.guarantees" v-bind:key="guarantee">
              {{ guarantee.name }}
            </dd>
          </dl>

          <dl class="row" v-if="isGuaranteeType">
            <dt>{{ $t("POLICIES.CANCELLATION_POLICY") }}</dt>
            <dd v-for="cancellation in policy.cancellations" v-bind:key="cancellation">
              {{ cancellation.name }}
            </dd>
          </dl>

          <dl class="row full">
            <dt>{{ $t("POLICIES.POLICY_DESCRIPTION") }}</dt>
            <dd>
              <div v-html="policy.description"></div>
            </dd>
          </dl>
        </div>

        <div class="all-infos-text-group" v-if="isPaymentType">
          <h3 v-if="isPaymentType">{{ $t("POLICIES.POLICY_PAYMENTS") }}</h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
            <dd>
              <div>
                {{
                  $t(`POLICIES.POLICY_PAYMENT_TYPE_${policy.payment_policies}`)
                }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.POLICY_HOURS") }}</dt>
            <dd>
              <div>
                {{ policy.payment_policies_hours + " " + $t(`POLICIES.HOURS`) }}
              </div>
            </dd>
          </dl>
        </div>

        <div class="all-infos-text-group" v-if="isPaymentType">
          <h3>{{ $t("POLICIES.PAYMENT") }}</h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
            <dd>
              <div>
                {{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>
              {{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}
            </dt>
            <dd>
              <div>{{ policy.payment_value }}</div>
            </dd>
          </dl>
        </div>

        <div class="all-infos-text-group" v-if="isCancellationType">
          <h3>
            {{ $t("POLICIES.POLICY_CANCELLATION") }}
          </h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
            <dd>
              <div>
                {{
                  $t(`POLICIES.POLICY_PAYMENT_TYPE_${policy.payment_policies}`)
                }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.POLICY_HOURS") }}</dt>
            <dd>
              <div>
                {{ policy.payment_policies_hours + " " + $t(`POLICIES.HOURS`) }}
              </div>
            </dd>
          </dl>
        </div>
        <div class="all-infos-text-group" v-if="isCancellationType">
          <h3>{{ $t("POLICIES.CANCELLATION_TITLE") }}</h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
            <dd>
              <div>
                {{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>
              {{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}
            </dt>
            <dd>
              <div v-if="policy.payment_type === PAYMENT_TYPES_DOLLAR()">{{ $formatCurrency(policy.payment_value) }}</div>
              <div v-if="policy.payment_type === PAYMENT_TYPES_PERCENT()">%{{ (policy.payment_value) }}</div>
              <div v-if="policy.payment_type === PAYMENT_TYPES_NIGHT()">{{parseInt(policy.payment_value)}} {{$t('POLICIES.PAYMENT_TYPE_NIGHT')}}</div>
            </dd>
          </dl>
        </div>
        <div class="all-infos-text-group" v-if="!isGuaranteeType">
          <h3>{{ $t("POLICIES.COMMUNICATION") }}</h3>
          <dl class="row">
            <dt>{{ $t("POLICIES.SUCCESS") }}</dt>
            <dd>
              <div>
                {{
                  policy.alert_success
                    ? $t(`POLICIES.SEND_EMAIL`)
                    : $t(`POLICIES.NO_SEND_EMAIL`)
                }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.SELECTED_EMAIL") }}</dt>
            <dd>
              <div>
                {{ policy.successMail?.title ?? $t("COMMON.NONE") }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.ERROR") }}</dt>
            <dd>
              <div>
                {{
                  policy.alert_error
                    ? $t(`POLICIES.SEND_EMAIL`)
                    : $t(`POLICIES.NO_SEND_EMAIL`)
                }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.SELECTED_EMAIL") }}</dt>
            <dd>
              <div>
                {{ policy.errorMail?.title ?? $t("COMMON.NONE") }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.ERROR") }}</dt>
            <dd>
              <div>
                {{
                  policy.alert_error_camping
                    ? $t(`POLICIES.SEND_EMAIL_CAMPING`)
                    : $t(`POLICIES.NO_SEND_EMAIL_CAMPING`)
                }}
              </div>
            </dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("POLICIES.SELECTED_EMAIL") }}</dt>
            <dd>
              <div>
                {{ policy.errorCampingMail?.title ?? $t("COMMON.NONE") }}
              </div>
            </dd>
          </dl>
        </div>
      </div>

      <!--<h3 v-if="isGuaranteeType">{{ $t("POLICIES.POLICY_GUARANTEE") }}</h3>

      <div class="all-infos-text" v-if="isGuaranteeType"></div>-->
    </div>
  </div>
</template>

<script>
import {
  POLICIES_TYPE_CANCELLATION,
  POLICIES_TYPE_GUARANTEE,
  POLICIES_TYPE_PAYMENTS,
  PAYMENT_TYPES_PERCENT,
  PAYMENT_TYPES_DOLLAR,
  PAYMENT_TYPES_NIGHT,
} from "@/constants/common";


export default {
  name: "policy-view-global",

  props: ["policy"],

  components: {},

  data() {
    return {};
  },

  computed: {
    isPaymentType() {
      return this.policy?.policies_type === POLICIES_TYPE_PAYMENTS;
    },
    isCancellationType() {
      return this.policy?.policies_type === POLICIES_TYPE_CANCELLATION;
    },
    isGuaranteeType() {
      return this.policy?.policies_type === POLICIES_TYPE_GUARANTEE;
    },
  },

  created() {},

  methods: {
    PAYMENT_TYPES_NIGHT() {
      return PAYMENT_TYPES_NIGHT
    },
    PAYMENT_TYPES_PERCENT() {
      return PAYMENT_TYPES_PERCENT
    },
    PAYMENT_TYPES_DOLLAR() {
      return PAYMENT_TYPES_DOLLAR
    }
    // spotUpdated() {
    //   this.$emit("spotUpdated", true);
    // },
  },

  mounted() {},

  watch: {
    policy(policy) {},
  },
};
</script>
