<template>
  <div class="credit-card selector-wrapper">
    <span class="resize-loading" v-if="this.loading">
      <span class="loader"></span>
    </span>
    <div v-if="!this.loading" class="credit-card-list">
      <el-radio
        v-for="cc in creditCards"
        :class="'credit-card-choice' + (cc.is_default ? ' selected' : '')"
        :key="cc.id"
        v-model="creditCardModel"
        @change="creditCardChanged"
        :label="cc.id"
      >
        <div class="credit-card__inner">
          <div class="credit-card__inner-left">
            <div class="credit-card__inner-left-icon">
              <img src="../../public/img/kw-credit-card.svg" alt="icon" />
            </div>
            <div class="credit-card__inner-left-info">
              <span class="credit-card__inner-left-info-number"
                >**************{{ cc.last_digits }}</span
              >
              <span class="credit-card__inner-left-info-holder">{{
                cc.holder_name
              }}</span>
            </div>
          </div>
          <div class="credit-card__inner-right">
            <el-button type="text" @click="() => deleteCreditCard(cc.id)">{{
              $t("COMMON.DELETE")
            }}</el-button>
            <span>
              <img
                v-if="cc.cc_type === 'VISA'"
                src="../../public/img/visa-logo.svg"
                alt="icon"
              />
              <!-- TODO  uncomment this if icons provided <img src="/img/card-{{ cc.type }}.svg" alt="shopping-cart"/>-->
              <!-- {{ cc.cc_type }} -->
            </span>
          </div>
        </div>
      </el-radio>
    </div>

    <div class="credit-card-new">
      <div v-if="!formDisplayed" class="new-card-button">
        <el-button
          type="text"
          @click="
            () => {
              formDisplayed = true;
              creditCardModel = null;
            }
          "
        >
          <i class="far fa-plus"></i>
          <span class="text">{{ $t("COMMON.ADD_NEW_CARD") }}</span>
        </el-button>
      </div>
      <div v-if="formDisplayed" class="credit-card-new__form">
        <label class="form-control-label">{{ $t("COMMON.CARD_NUMBER") }}</label>
        <div class="credit-card-informations">
          <div class="cc-number">
            <el-input
              v-model="newCreditCard.number"
              pattern="\d{1,17}|\d{1,18}|\d{1,19}|"
              placeholder="4242424242424242"
              :maxlength="19"
              :minlength="10"
            />
          </div>
          <div class="cc-codes">
            <span class="cc-expiration">
              <el-date-picker
                v-model="newCreditCard.expiration"
                type="month"
                format="MM / yy"
                placeholder="MM/AA"
              >
              </el-date-picker>
            </span>
            <span class="cc-cvv">
              <el-input
                v-model="newCreditCard.cvv"
                :maxlength="3"
                :minlength="3"
                placeholder="***"
                type="password"
                show-password
              >
              </el-input>
            </span>
          </div>
        </div>

        <div class="card-holder form-wrapper">
          <base-input
            :label="$t('COMMON.CARDHOLDER_NAME')"
            v-model="newCreditCard.holder_name"
          />
          <validation-error :errors="apiValidationErrors.holder_name" />
        </div>

        <el-checkbox v-model="newCreditCard.is_default">
          {{ $t("COMMON.USE_AS_PRINCIPAL_CARD") }}
        </el-checkbox>

        <!-- region boutons d'actions -->
        <div class="buttons-wrapper">
          <el-button
            class="add"
            type="primary"
            @click="addCreditCard"
            :loading="loading"
          >
            {{ $t("COMMON.ADD") }}
          </el-button>

          <el-button
            class="cancel"
            type="secondary"
            @click="() => (formDisplayed = false)"
          >
            {{ $t("COMMON.CANCEL") }}
          </el-button>
        </div>
        <!-- endregion -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Radio,
  RadioGroup,
  InputNumber,
  Checkbox,
  DatePicker,
} from "element-ui";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "credit-card-selector",

  components: {
    ValidationError,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [DatePicker.name]: DatePicker,
    [Checkbox.name]: Checkbox,
  },

  props: {
    creditCard: {
      type: String,
      default: null,
      description: "creditCard id",
    },
    customerId: {
      type: String,
      default: null,
      description: "customer id",
    },
  },

  mixins: [formMixin],

  data() {
    return {
      creditCardModel: this.creditCard,
      creditCards: {},
      newCreditCard: this.getDefaultCreditCard(),
      formDisplayed: false,
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getCreditCards();
  },

  methods: {
    async getCreditCards() {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            customer_id: this.customerId,
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("creditCards/list", params);
        const creditCardsArr = await this.$store.getters["creditCards/list"];
        this.creditCards = {};

        let defaultCreditCard = null;
        creditCardsArr.forEach((creditCard) => {
          this.creditCards[creditCard.id] = creditCard;

          if (creditCard.is_default) {
            defaultCreditCard = creditCard.id;
          }
        });

        if (defaultCreditCard) {
          this.creditCardModel = defaultCreditCard;
          this.creditCardChanged(defaultCreditCard);
        }

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async addCreditCard() {
      try {
        this.loading = true;
        if (this.newCreditCard.expiration) {
          this.newCreditCard.exp_month = (
            this.newCreditCard.expiration.getMonth() + 1
          )
            .toString()
            .padStart(2, "0");
          this.newCreditCard.exp_year =
            this.newCreditCard.expiration.getFullYear();
        }

        await this.$store.dispatch("creditCards/add", {
          customerId: this.customerId,
          creditCard: this.newCreditCard,
        });

        this.loading = false;
        this.newCreditCard = this.getDefaultCreditCard();
        this.formDisplayed = false;

        await this.getCreditCards();
      } catch (error) {
        console.warn(error);
        this.setApiValidation(error.response.data.errors);

        let notificationOptions = {
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        };

        if (error.response.data.errors[0]?.detail) {
          notificationOptions.title = this.$t("ERRORS.SOMETHING_WENT_WRONG");
          notificationOptions.message = error.response.data.errors[0]?.detail;
        }
        this.$notify(notificationOptions);
        this.loading = false;
      }
    },
    async deleteCreditCard(cardId) {
      try {
        this.loading = true;

        await this.$store.dispatch("creditCards/destroy", cardId);

        this.loading = false;
        this.formDisplayed = false;

        await this.getCreditCards();
      } catch (error) {
        console.warn(error);
        this.setApiValidation(error.response.data.errors);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
    getDefaultCreditCard() {
      return {
        number: "4242424242424242",
        expiration: null,
        exp_month: null,
        exp_year: null,
        cvv: "565",
        holder_name: "karl hams",
        is_default: false,
      };
    },
    creditCardChanged(creditCard) {
      this.$emit("creditCardChanged", creditCard);
    },
  },

  watch: {
    creditCardModel(creditCard) {
      this.$emit("creditCardChanged", creditCard);
    },
  },
};
</script>
