<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Admin Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("ROLES.ROLES_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.ADMIN_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("ROLES.ROLES_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openRoleCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_ROLES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{ $t("ROLES.ADD_ROLE") }}</span>
          </base-button>
        </div>
      </div>

      <role-list-table
        @onViewRole="openRoleViewModal"
        @onEditRole="openRoleEditModal"
        @onDeleteRole="deleteRole"
        @onAddRole="openRoleCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeRoleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("ROLES.VIEW_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-role-page
              v-if="openRole"
              :roleId="openRole.id"
              @onEditRole="openRoleEditModal"
              @onDeleteRole="deleteRole"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="openRoleViewModal(openRole)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("ROLES.EDIT_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-role-page
              v-if="openRole"
              :roleId="openRole.id"
              @onViewRole="openRoleViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeRoleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("ROLES.ADD_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-role-page
              @onViewRole="openRoleViewModal"
              @onCloseRoleModal="closeRoleModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import RoleListTable from "./partials/RoleListTable.vue";
import EditRolePage from "./components/EditRoleComponent.vue";
import AddRolePage from "./components/AddRoleComponent.vue";
import ViewRolePage from "./components/ViewRoleComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import {mapGetters} from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    RoleListTable,
    // NotificationSubscription,
    EditRolePage,
    AddRolePage,
    ViewRolePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
  },

  watch: {},

  data() {
    const roleId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewRoleModalOpened = false;
    let isEditRoleModalOpened = false;
    let isAddRoleModalOpened = false;
    let openRole = null;
    if (roleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewRoleModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditRoleModalOpened = true;
      }
      openRole = { id: roleId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddRoleModalOpened = true;
    }
    return {
      isViewRoleModalOpened: isViewRoleModalOpened,
      isEditRoleModalOpened: isEditRoleModalOpened,
      isAddRoleModalOpened: isAddRoleModalOpened,
      openRole: openRole,
      renderKey: 1,
    };
  },

  methods: {
    openRoleCreateModal() {
      this.closeRoleModal();
      this.isAddRoleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openRoleViewModal(role, reRender = false) {
      this.closeRoleModal();
      this.openRole = role;
      this.isViewRoleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { id: this.openRole.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openRoleEditModal(role) {
      this.closeRoleModal();
      this.openRole = role;
      this.isEditRoleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { id: this.openRole.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeRoleModal() {
      this.isAddRoleModalOpened = false;
      this.isViewRoleModalOpened = false;
      this.isEditRoleModalOpened = false;
      this.openRole = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: {},
        }).href
      );
    },

    async deleteRole(role) {
      const confirmation = await swal.fire({
        text: this.$t("ROLES.DELETE_THIS_ROLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("roles/destroy", role.id);
          this.renderKey++;
          this.closeRoleModal();
          this.$notify({
            type: "success",
            message: this.$t("ROLES.ROLE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
