<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!policy">
      <span class="loader"></span>
    </span>
    <policy-form
      v-if="policy"
      :loading="loading"
      :policyData="policy"
      :formErrors="formErrors"
      @policySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePolicyModal="onClosePolicyModal"
    />
  </div>
</template>

<script>
import PolicyForm from "../partials/PolicyForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { PolicyForm },

  mixins: [alertLeave],

  props: {
    policyId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      policy: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("policies/get", {id:this.policyId});
        this.policy = this.$store.getters["policies/policy"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(policy) {
      this.loading = true;
      const policyData = cloneDeep(policy);

      try {
        await this.$store.dispatch("policies/update", policyData);
        this.$notify({
          type: "success",
          message: this.$t("POLICIES.POLICY_UPDATED"),
        });
        const policy = await this.$store.getters["policies/policy"];
        this.$emit("onViewPolicy", policy, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onClosePolicyModal() {
      this.policy = null;
      this.$emit("onClosePolicyModal");
    },
  },
};
</script>
