<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!pricingRule">
      <span class="loader"></span>
    </span>
    <div v-if="pricingRule" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("PRICING_RULES.DETAILS_PRICING_RULE") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("CONFIGURATION.PRICING_RULES") }}</span>
              </li>
              <li>
                <span> {{ pricingRule.name }} </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="" @click="copyAllInfo">
            <i class="fal fa-copy"></i>
            <span>{{ $t("PRICING_RULES.COPY_ALL_FIELDS") }}</span>
          </button>
          <button
            class="delete"
            @click="deletePricingRule"
            v-if="$currentUserCan($permissions.PERM_DELETE_PRICING_RULES)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editPricingRule"
            v-if="$currentUserCan($permissions.PERM_EDIT_PRICING_RULES)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="pricingRule"
      >
        <tab-pane title="pricingRule" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <pricing-rule-view-global
            :pricingRule="pricingRule"
            @onContractUpdated="get"
          />
        </tab-pane>
        <tab-pane title="pricingRule" id="2">
          <span slot="title">
            {{ $t("PRICING_RULES.COSTS") }}
          </span>
          <pricing-rule-view-costs
            :pricingRule="pricingRule"
            @onContractUpdated="get"
          />
        </tab-pane>
        <tab-pane title="pricingRule" id="3">
          <span slot="title">
            {{ $t("PRICING_RULES.RESTRICTIONS") }}
          </span>
          <pricing-rule-view-restrictions
            :pricingRule="pricingRule"
            @onContractUpdated="get"
          />
        </tab-pane>
        <tab-pane title="pricingRule" id="4">
          <span slot="title">
            {{ $t("PRICING_RULES.MARKETING") }}
          </span>
          <pricing-rule-view-marketing
            :pricingRule="pricingRule"
            @onContractUpdated="get"
          />
        </tab-pane>
        <tab-pane title="pricingRule" id="5">
          <span slot="title">
            {{ $t("PRICING_RULES.WEB") }}
          </span>
          <pricing-rule-view-web
            :pricingRule="pricingRule"
            @onContractUpdated="get"
          />
        </tab-pane>
        <tab-pane title="pricingRule" id="6">
          <span slot="title">
            {{ $t("COMMON.EXCERPT") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <h3>{{ pricingRule.name }}</h3>
            <div class="all-infos">
              <div class="all-infos-text mt-3">
                <dl class="row">
                  <dt>{{ $t("COMMON.EXCERPT") }}</dt>
                  <dd>
                    {{
                      pricingRule.description ??
                      $t("PRICING_RULES.EMPTY_DESCRIPTION")
                    }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import PricingRuleViewGlobal from "../partials/PricingRuleViewGlobal.vue";
import PricingRuleViewCosts from "../partials/PricingRuleViewCosts.vue";
import PricingRuleViewRestrictions from "../partials/PricingRuleViewRestrictions.vue";
import PricingRuleViewMarketing from "../partials/PricingRuleViewMarketing.vue";
import PricingRuleViewWeb from "../partials/PricingRuleViewWeb.vue";
import { cloneDeep } from "lodash";
export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PricingRuleViewGlobal,
    PricingRuleViewCosts,
    PricingRuleViewRestrictions,
    PricingRuleViewMarketing,
    PricingRuleViewWeb,
  },

  mixins: [],

  props: {
    pricingRuleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      pricingRule: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    copyAllInfo() {
      const pricingRuleData = cloneDeep(this.pricingRule);
      const pricingRuleString = JSON.stringify(pricingRuleData, null, 2);

      navigator.clipboard
        .writeText(pricingRuleString)
        .then(() => {
          this.$notify({
            type: "success",
            message: this.$t("PRICING_RULES.PRICING_RULE_COPIED"),
          });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: this.$t("PRICING_RULES.COPY_FAILURE"),
          });
        });
    },
    async get() {
      try {
        await this.$store.dispatch("pricingRules/get", this.pricingRuleId);
        this.pricingRule = this.$store.getters["pricingRules/pricingRule"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPricingRule() {
      this.$emit("onEditPricingRule", this.pricingRule);
    },

    deletePricingRule() {
      this.$emit("onDeletePricingRule", this.pricingRule);
    },
  },
};
</script>
