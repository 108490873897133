<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ templateMail.title }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="templateMail.organization" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.TYPE") }}</dt>
          <dd>
            <div v-html="templateMail.typesMail.label"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.TITLE") }}</dt>
          <dd>
            <div v-html="templateMail.title"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.SUBJECT") }}</dt>
          <dd>
            <div v-html="templateMail.subject"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.FROM") }}</dt>
          <dd>
            <div v-html="templateMail.from"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.TO") }}</dt>
          <dd>
            <div
              v-for="recipient in templateMail.recipients"
              :key="recipient.id"
            >
              <div v-html="recipient"></div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.CC") }}</dt>
          <dd>
            <div v-for="c in templateMail.cc" :key="c.id">
              <div v-html="c"></div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_MAILS.BCC") }}</dt>
          <dd>
            <div v-for="bc in templateMail.bcc" :key="bc.id">
              <div v-html="bc"></div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="templateMail.created_at">
            {{ $formatDate(templateMail.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="templateMail.updated_at">
            {{ $formatDate(templateMail.updated_at) }}
          </dd>
        </dl>
        <dl class="row full custom-content">
          <dt>{{ $t("TEMPLATES_MAILS.CONTENT") }}</dt>
          <dd>
            <div v-html="templateMail.content"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
export default {
  name: "template-mail-view-global",

  components: {},

  props: ["templateMail"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    templateMail(templateMail) {},
  },
};
</script>
<style scoped>
.mail-tag {
  background-color: #c5f4f9;
  border: 1px solid #179197;
  text-align: center;
  color: #179197;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 7px;
  margin: 5px;
}
</style>
