import permissions from "../constants/permissions";
import PricingSeasonsModuleLayout from "@/views/Pages/PricingSeasonsModule/PricingSeasonsModuleLayout.vue";
import PricingSeasonsModuleMenu from "@/views/Pages/PricingSeasonsModule/PricingSeasonsModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const PricingSeasonsPage = () =>
    import("@/views/Pages/PricingSeasonsModule/PricingSeasonsManagement/ListPricingSeasonsPage.vue");

let pricingSeasonsManagementPages = {
    path: "pricing-seasons",
    components: {
        default: PricingSeasonsModuleLayout,
        SidebarSecondLevelMenu: PricingSeasonsModuleMenu,
    },
    children: [
        {
            path: "",
            name: "List PricingSeasons",
            components: { default: PricingSeasonsPage },
            meta: {
                middleware: authorized,
                permission: permissions.PERM_VIEW_ANY_PRICING_SEASONS,
                hasLevel2: true,
            },
        },
    ],
};

const pricingSeasonsModuleRoutes = [
    {
        path: "",
        components: {
            default: PricingSeasonsModuleLayout,
            SidebarSecondLevelMenu: PricingSeasonsModuleMenu,
        },
        children: [
            {
                path: "",
                name: "Pricing Seasons Page",
                components: { default: PricingSeasonsPage },
                meta: {
                    middleware: authenticated,
                    hasLevel2: true,
                },
            },
        ],
    },
    pricingSeasonsManagementPages
];

export default pricingSeasonsModuleRoutes;
