<template>
  <el-select
    v-model="policiesModel"
    @change="policiesChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="$t('COMMON.POLICIES')"
    :filterable="filterable"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getPolicies"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_ORGANIZATIONS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="policy in policiesList"
      :key="policy.id"
      :value="policy.id"
      :label="`${policy.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "policy-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    policies: {
      type: Array,
      default: null,
      description: "Policies id",
    },
    filterType: {
      type: String,
      default: null,
      description: "Type de politique",
    },
  },

  data() {
    return {
      policiesModel: this.policies?.length ? this.policies.map((item) => item.id) : [],
      policiesList: {},
    };
  },

  setup() {
  },

  created() {
    this.getPolicies(null, this.policies);
  },

  methods: {
    async getPolicies(query = null, id = null) {
      try {
        this.loading = true;

        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            // ...(id ? { id: id.map(_=>_.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              policies_type: this.filterType,
            },
          };
        }

        await this.$store.dispatch("policies/list", params);
        const policiesArr = await this.$store.getters[
          "policies/list"
          ];
        this.policiesList = {};
        policiesArr.forEach((policy) => {
          this.policiesList[policy.id] = policy;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    policiesChanged(policies) {
      this.$emit(
        "policiesChanged",
        policies.map((item) => {
          return { id: item, type: "policies" };
        })
      );
    },
  },

  watch: {
    policies(policies) {
      this.policiesModel = policies.map((item) => item.id);
    }
  },
};
</script>
