export default {
  PACKAGES_LIST: "Packages List",
  ADD_PACKAGE: "Add Package",
  PACKAGE_ADDED: "Package Added",
  EDIT_PACKAGE: "Edit Package",
  PACKAGE_UPDATED: "Package Updated",
  FREQUENCY: "Frequency",
  DEFAULT_ROLE: "Default Role",
  MAXIMUM_USERS: "Max Users",
  DELETE_THIS_PACKAGE: "Delete This Package?",
  PACKAGE_DELETED: "Package Deleted",
  BOOKINGS_INVOICED: "Bookings charge",
  BOOKINGS_INVOICED_NAME: "Name",
  BOOKINGS_INVOICED_CODE: "Code",
  BOOKINGS_INVOICED_AMOUNT: "Amount",
};
