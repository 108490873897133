import service from "@/store/services/spots-service";

export const state = () => ({
  list: {},
  spot: {},
  spotState: {},
  meta: {},
  url: null,
  unavailableDates : [],
  lockedInDraft: null
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, spot) => {
    state.spot = spot;
  },
  SET_STATE: (state, spotState) => {
    state.spotState = spotState;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_UNAVAILABLE_DATES: (state, dates) => {
    state.unavailableDates = dates;
  },
  SET_LOCKED_IN_DRAFT: (state, status) => {
    state.lockedInDraft = status;
  },
};

export const actions = {

  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, { id, params = {} }) {
    return service.get({ id, params }, this.$axios).then((spot) => {
                    commit("SET_RESOURCE", spot);
                  });
  },

  getState({ commit, dispatch }, { id, params = {} })
  {
    return service.getState({ id, params }, this.$axios)
        .then((spot) => {
          commit("SET_STATE", spot);
        });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((spot) => {
      commit("SET_RESOURCE", spot);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((spot) => {
      commit("SET_RESOURCE", spot);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  clean({ commit, dispatch }, params) {
    return service.clean(params, this.$axios).then((spot) => {
      commit("SET_RESOURCE", spot);
    });
  },

  dirty({ commit, dispatch }, params) {
    return service.dirty(params, this.$axios).then((spot) => {
      commit("SET_RESOURCE", spot);
    });
  },

  getUnavailableDates({commit, dispatch}, {id, dateRange, bookingId = null}) {
    const [start_date, end_date] = dateRange;

    dateRange = [
      new Date(start_date).toISOString(),
      new Date(end_date).toISOString(),
    ];
    
    return service.getUnavailableDates({id, dateRange, bookingId}, this.$axios).then((dates) => {
      commit("SET_UNAVAILABLE_DATES", dates);
    });
  },

  getSpotCartStatus({commit, dispatch}, spotId)
  {
    return service.getSpotCartStatus(spotId, this.$axios)
        .then(locked_in_draft => {
          commit("SET_LOCKED_IN_DRAFT", locked_in_draft)
        });
  }
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  spot: (state) => state.spot,
  getState: (state) => state.spotState,
  url: (state) => state.url,
  unavailableDates: (state) => state.unavailableDates,
  getSpotCartStatus: (state) => state.lockedInDraft,
  dropdown: (state) => {
    return state.list.map((spot) => ({
                        id: spot.id,
                        name: spot.name,
                      }));
  },
};

const spots = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default spots;
