export default {
  BOOKING_LIST: "Réservations",
  TITLE: "Réservation",
  PENDING_BOOKING_LIST: "Réservations en cours",
  ADD_BOOKING: "Ajouter réservation",
  BOOKING_ADDED: "Réservation confirmée",
  EDIT_BOOKING: "Modifier la réservation",
  BOOKING_UPDATED: "Réservation mis à jour",
  DELETE_THIS_BOOKING: "Supprimer cette réservation ?",
  BOOKING_DELETED: "Réservation supprimée",
  CAPACITY: "Nombre de personnes",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Animaux",
  CHILDREN_CAPACITY: "Enfants",
  BOOK_SPOT: "Réserver place",
  STARTING_FROM: "À partir de",
  PRICE_PER_NIGHT: "par nuit",
  AVAILABILITY: "Disponibilité",
  AVAILABLE: "Disponible",
  NOT_AVAILABLE: "Non-disponible",
  NOT_AVAILABLE_ERROR: "Cet emplacement est non-disponible",
  PARTIALLY_AVAILABLE: "Partiellement disponible",
  FILTER_BY_SPOT_CATEGORY: "Terrain ou emplacement",
  FILTER_BY_SPOT_TYPE: "Type d’emplacement",
  FILTER_BY_SPOT_LENGTH: "Dimensions de l'équipement",
  FILTER_BY_EQUIPMENT: "Service",
  UNIT_FEET: "pieds",
  SPOTS_LIST_FOUND: "{total} emplacements trouvés",
  NEW_BOOKING: "Nouvelle réservation",
  BOOKING_CODE: "N° de confirmation",
  DURATION: "Nbr nuit",
  PRICE_PER_DAY: "Tarif/jour",
  ARRIVING_AT: "Arrivée",
  LEAVING_AT: "Départ",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Garantir",
  ADD_TO_CART: "Ajout",
  SPECIAL_REQUEST: "Demande du client",
  INTERNAL_NOTE: "Note interne",
  SELECT_CONFIRMATION_EXPIRATION_DATE:
    "Sélectionner une date d'expiration pour la confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Envoyer à l'adresse courriel du client",
  CONFIRM_AND_SEND: "Confirmer et envoyer",
  BLOCK_THIS_SPOT: "Bloquer cet emplacement",
  VIEW_BOOKING: "Voir la reservation",
  PENDING: "En attente",
  BOOKINGS_LIST: "Liste des reservations",
  BOOKING_DETAILS: "Détails réservation",
  BOOKING_NUMBER: "Numéro de réservation",
  SERVICE: "Service",
  CONFIRM_THIS_BOOKING:
    "<p>Voulez-vous vraiment confirmer et envoyer cette réservation à <span class='link'>{email}</span> ?</p>",
  CONFIRM_BOOKING: "Confirmation",
  BOOKING_CONFIRMED: "Reservation confirmée",
  CONFIRMED: "Confirmé",
  ARRIVED: "Arrivée",
  FINISHED: "Partie",
  DATE_RANGE_ALERT: "Veuillez choisir 2 dates différentes pour votre réservation.",
  BOOKING_DAYS: "Jours réservés",
  BOOKING_SPOT: "Emplacement réservé",
  BOOKING_INFORMATION: "Information de réservation",
  OCCUPATION: "Occupation",
  SPOT_INFORMATION: "Information emplacement",
  SELECTED_PERIOD: "Période sélectionné",
  TOTAL_BOOKINGS: "{total} réservations",
  CONFORM_PAYMENT: "Confirmer paiement",
  BOOKING_IDENTIFIER: "# Réservation {code}",
  BOOKING_DATE: "Date de réservation",
  MY_CURRENT_BOOKINGS: "Ma liste de réservations",
  OTHERS_CURRENT_BOOKINGS: "Autre réservations",
  CONFIRM_THE_BOOKING: "Confirmer la réservation",
  BOOK_WITH_PAYMENT: "Réserver avec garantie",
  BOOK_WITHOUT_PAYMENT: "Réserver sans garantie",
  TAKE_CONTROL_OF_BOOKING:
    "<p>Un autre utilisateur ({email}) est entrain de travailler sur cette réservation</p><br> <b>Voulez vous quand même vous attribuer le contrôle ?</b>",
  BEDS_QTY: "Nombre de lits",
  ROOMS_QTY: "Nombre de chambres",
  SELECTED_EQUIPMENT: "Équipement choisi",
  REASON_REPAIR: "Réparation",
  REASON_UNAVAILABLE: "Indisponible",
  SELECT_DATE_RANGE: "Veuillez Sélectionner une date",
  CHECK_IN: "Arrivé(e)",
  CHECK_OUT: "Sorti(e)",
  CANCELED: "Annulé(e)",
  CANT_CANCEL: "Le politiques d'annulation ne vous permettent pas d'annuler cette réservation.",
  OUT_OF_SERVICE: "Hors service",
  CANNOT_CHECK_IN: "Impossible de mettre votre réservation à arrivée !",
  CANNOT_CHECK_IN_MESSAGE: "Vous ne pouvez votre mettre réservation à arrivée plus de {0} h avant la date d'arrivée prévue.",
  CHECK_IN_THIS_BOOKING: "Voulez vous vraiment mettre la réservation à arrivée ?",
  CHECK_OUT_THIS_BOOKING: "Voulez vous vraiment mettre la réservation à sortie ?",
  CANCEL_THIS_BOOKING: "Voulez vous vraiment annuler la réservation ?",
  BOOKING_CHECKED_IN: "Reservation mise à <i>arrivée<i>",
  BOOKING_CHECKED_OUT: "Reservation terminée",
  BOOKING_CANCELLED: "Reservation annulée",
  CANCELLATION_WITH_FEES: `<br/>
                           <span>Vous vous apprêter à annuler cette réservation, il est possible que des frais s'appliquent.</span>
                           <br/> <a href="{0}">Voir la politique d'annulation.</a>`,
  CANCEL_FEE: "Frais d'annulation: {0}",
  REFUND_AMOUNT: "Montant du remboursement: {0}",
  BALANCE_OUTSTANDING_AMOUNT: "Solde dû: {0}",
  IS_GUARANTEED: "Est garantie",
  SPOT_NO_LONGER_AVAILABLE: "L'emplacement n'est plus disponible",
  GUARANTEED_RESERVATION: "Réservation garantie",
  NOT_GUARANTEED_RESERVATION: "Réservation non garantie",
  GUARANTEED: "Réservation Garantie",
  NOT_GUARANTEED: "Réservation non garantie",
  IN_PROGRESS_RESERVATION: "En cours",
  DRAFT: "En cours",
  CLEAN: "Propre",
  DIRTY: "Sale",
  BLOCKED: "Bloqué",
  REFERENCE: "référence",
  DATES_NOT_AVAILABLE: "<p>Attention cet emplacement est partiellement disponible pour les dates selectionnées.</p><p>Veuillez consulter le calendrier.</p>"
};
