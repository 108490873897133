<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("CUSTOMERS.CUSTOMER_DETAILS") }}</h3>

    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <h4 class="title-red">{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</h4>

        <dl class="row" v-if="booking.customer">
          <dt>
            {{ $t("CUSTOMERS.LAST_NAME") }}
          </dt>
          <dd>
            {{ booking.customer.lastname }} {{ booking.customer.firstname }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("CUSTOMERS.EMAIL") }}
          </dt>
          <dd>
            {{ booking.customer.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("CUSTOMERS.ADDRESS") }}
          </dt>
          <dd>
            {{ booking.customer.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.STATE") }}
          </dt>
          <dd>
            {{ booking.customer.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.COUNTRY") }}
          </dt>
          <dd>
            {{ booking.customer.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.ZIPCODE") }}
          </dt>
          <dd>
            {{ booking.customer.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{ booking.customer.phone }}
          </dd>
        </dl>

        <dl class="row" v-if="booking.customer.other_phones?.length">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            {{ booking.customer.other_phones?.join(", ") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.LOCALE") }}
          </dt>
          <dd>
            {{ booking.customer.phone }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "booking-view-customer",

  props: ["booking"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
