<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("PRICING_RULES.RESTRICTIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <dl class="row" v-if="pricingRule.minimum_stay !== null">
          <dt>{{ $t("PRICING_RULES.MINIMUM_STAY") }}</dt>
          <dd>
            {{ pricingRule.minimum_stay }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_stay !== null">
          <dt>{{ $t("PRICING_RULES.MAXIMUM_STAY") }}</dt>
          <dd>
            {{ pricingRule.maximum_stay }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.minimum_day_before_arrival !== null">
          <dt>{{ $t("PRICING_RULES.MINIMUM_DAY_BEFORE_ARRIVAL") }}</dt>
          <dd>
            {{ pricingRule.minimum_day_before_arrival }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_day_before_arrival !== null">
          <dt>{{ $t("PRICING_RULES.MAXIMUM_DAY_BEFORE_ARRIVAL") }}</dt>
          <dd>
            {{ pricingRule.maximum_day_before_arrival }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.minimum_person !== null">
          <dt>{{ $t("PRICING_RULES.MINIMUM_PERSON") }}</dt>
          <dd>
            {{ pricingRule.minimum_person }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_person !== null">
          <dt>{{ $t("PRICING_RULES.MAXIMUM_PERSON") }}</dt>
          <dd>
            {{ pricingRule.maximum_person }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-restrictions",

  props: ["pricingRule"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    copyAllInfo() {
      const pricingRuleData = cloneDeep(this.pricingRule);
      const pricingRuleString = JSON.stringify(pricingRuleData, null, 2);

      navigator.clipboard
        .writeText(pricingRuleString)
        .then(() => {
          this.$notify({
            type: "success",
            message: this.$t("PRICING_RULES.PRICING_RULE_COPIED"),
          });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: this.$t("PRICING_RULES.COPY_FAILURE"),
          });
        });
    },
    contactUpdated() {
      this.$emit("pricingRuleUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
