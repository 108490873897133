<template>
  <div class="container-fluid">
    <spot-form
      :loading="loading"
      :spotData="spot"
      :formErrors="formErrors"
      :action="action"
      @spotSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotModal="onCloseSpotModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSpot from "../defaultSpot";
import SpotForm from "../partials/SpotForm.vue";
import {QUERY_ACTIONS_ADD, QUERY_ACTIONS_EDIT} from "@/constants/common";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SpotForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    let spotData = cloneDeep(defaultSpot);
    spotData.id = -2;
    spotData = this.$fillUserOrganizationData(spotData);

    return {
      spot: spotData,
      formErrors: null,
      loading: false,
      action: QUERY_ACTIONS_ADD
    };
  },

  created() {
    this.spot.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(spot) {
      this.loading = true;

      const spotData = cloneDeep(spot);
      delete spotData.id;

      try {
        await this.$store.dispatch("spots/add", spotData);
        this.$notify({
          type: "success",
          message: this.$t("SPOTS.SPOT_ADDED"),
        });
        const spot = await this.$store.getters["spots/spot"];
        this.$emit("onViewSpot", spot, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseSpotModal() {
      this.$emit("onCloseSpotModal");
    },
  },
};
</script>
