import permissions from "../constants/permissions";
import StatiscticsModuleLayout from "@/views/Pages/Reports/ReportsModule/ListReportsStatistics.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";


let statisticsManagementPages = {
  path: "statistics",
  components: {
    default: StatiscticsModuleLayout,
  },
   children: [
       {
         path: "",
         name: "List Statistics",
         components: { default: StatiscticsModuleLayout },
         meta: {
           middleware: authenticated,
           hasLevel2: false,
         },
       },
     ],
};



 const reportsModuleRoutes = [
  {
    path: "reports/statisctics",
    components: {
      default: StatiscticsModuleLayout,
     // SidebarSecondLevelMenu: SalesModuleMenu,
    },
 
  },
  
  statisticsManagementPages,
  // invoiceManagementPages,
  // deliveryManagementPages,
  // paymentManagementPages,
];

export default reportsModuleRoutes;
