<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ pricingRule.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <dl class="row" v-if="pricingRule.name">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_NAME") }}</dt>
          <dd>
            {{ pricingRule.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_TYPE") }}</dt>
          <dd>
            {{ pricingRule.spotType?.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_PRICING_TYPE") }}</dt>
          <dd>
            {{ pricingRule.pricing_rule_type }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.GUARANTEE_LABEL") }}</dt>
          <dd>
            {{ pricingRule.guarantee?.name }}
          </dd>
        </dl>

        <dl class="row" v-if="pricingRule.pricing_rule_type === 'derived'">
          <dt>{{ $t("PRICING_RULES.DERIVED") }}</dt>
          <dd>
            {{ pricingRule.derivedFrom?.name }}
          </dd>
        </dl>

        <dl class="row" v-if="pricingRule.pricing_rule_type === 'derived'">
          <dt>{{ $t("PRICING_RULES.VARIATION_IN_PRICE_OR_PERCENT") }}</dt>
          <dd>
            {{ $t("PRICING_RULES." + pricingRule.pricing_type) }}
          </dd>
        </dl>

        <dl class="row" v-if="pricingRule.pricing_rule_type === 'derived'">
          <dt>{{ $t("PRICING_RULES.VARIATION_PLUS_OR_MINUS") }}</dt>
          <dd>
            {{
              pricingRule.variation ??
              $t("PRICING_RULES.EMPTY_VARIATION_PLUS_OR_MINUS")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_VALUE") }}</dt>
          <dd v-if="pricingRule.pricing_type === 'price'">
            {{ $formatCurrency(pricingRule.value) }}
          </dd>
          <dd v-if="pricingRule.pricing_type === 'percent'">
            %{{ pricingRule.value }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.END_OF_WEEK") }}</dt>
          <dd v-if="pricingRule.pricing_type === 'price'">
            {{ $formatCurrency(pricingRule.weekend_value) }}
          </dd>
          <dd v-if="pricingRule.pricing_type === 'percent'">
            %{{ pricingRule.weekend_value }}
          </dd>
        </dl>

        <dl class="row" v-if="pricingRule.per_person !== null">
          <dt>{{ $t("PRICING_RULES.PER_PERSON") }}</dt>
          <dd>
            {{ pricingRule.per_person ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-global",

  props: ["pricingRule"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    copyAllInfo() {
      const pricingRuleData = cloneDeep(this.pricingRule);
      const pricingRuleString = JSON.stringify(pricingRuleData, null, 2);

      navigator.clipboard
        .writeText(pricingRuleString)
        .then(() => {
          this.$notify({
            type: "success",
            message: this.$t("PRICING_RULES.PRICING_RULE_COPIED"),
          });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: this.$t("PRICING_RULES.COPY_FAILURE"),
          });
        });
    },
    contactUpdated() {
      this.$emit("pricingRuleUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
