<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ pricingRule.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PROMO_CODE") }}</dt>
          <dd>
            {{ codes[pricingRule.code_id] }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PUBLISH_ONLINE_RESERVATION") }}</dt>
          <dd>
            {{
              pricingRule.publish_online ? $t("COMMON.YES") : $t("COMMON.NO")
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.BADGE") }}</dt>
          <dd>
            {{ badges[pricingRule.badge_id] }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.DISCOUNT_RATES_DISPLAY") }}</dt>
          <dd>%{{ pricingRule.discount_rate }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-web",

  props: ["pricingRule"],

  components: {},

  data() {
    return {
      codes: {
        1: "Id 1",
        2: "Id 2",
      },
      badges: {
        1: "CAA",
        2: "CAB",
      },
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
