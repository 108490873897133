<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!pricingSeason">
      <span class="loader"></span>
    </span>
    <pricing-season-form
      v-if="pricingSeason"
      :loading="loading"
      :pricingSeasonData="pricingSeason"
      :formErrors="formErrors"
      @pricingSeasonSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePricingSeasonModal="onClosePricingSeasonModal"
    />
  </div>
</template>

<script>
import PricingSeasonForm from "../partials/PricingSeasonForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { PricingSeasonForm },

  mixins: [alertLeave],

  props: {
    pricingSeasonId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      pricingSeason: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("pricingSeasons/get", {id:this.pricingSeasonId});
        this.pricingSeason = this.$store.getters["pricingSeasons/pricingSeason"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(pricingSeason) {
      this.loading = true;
      const pricingSeasonData = cloneDeep(pricingSeason);

      try {
        await this.$store.dispatch("pricingSeasons/update", pricingSeasonData);
        this.$notify({
          type: "success",
          message: this.$t("PRICING_SEASONS.PRICING_SEASON_UPDATED"),
        });
        const pricingSeason = await this.$store.getters["pricingSeasons/pricingSeason"];
        this.$emit("onViewPricingSeason", pricingSeason, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onClosePricingSeasonModal() {
      this.$emit("onClosePricingSeasonModal");
    },
  },
};
</script>
