export default {
  PERM_VIEW_MODULE_PAYMENTS: "Access the payments module",
  PERM_VIEW_ANY_PAYMENTS: "See all payments",
  PERM_VIEW_PAYMENTS: "See payment details",
  PERM_CREATE_PAYMENTS: "Create a payment",
  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Access the subscriptions module",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "See all subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "See subscription details",
  PERM_CREATE_SUBSCRIPTIONS: "Create a subscription",
  PERM_EDIT_SUBSCRIPTIONS: "Edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Delete a subscription",
  PERM_VIEW_MODULE_PACKAGES: "Access the packages module",
  PERM_VIEW_ANY_PACKAGES: "See all packages",
  PERM_VIEW_PACKAGES: "See package details",
  PERM_CREATE_PACKAGES: "Create a package",
  PERM_EDIT_PACKAGES: "Edit packages",
  PERM_DELETE_PACKAGES: "Delete a package",
  PERM_VIEW_MODULE_CUSTOMERS: "Access the customers module",
  PERM_VIEW_ANY_CUSTOMERS: "See all customers",
  PERM_VIEW_CUSTOMERS: "See customer details",
  PERM_CREATE_CUSTOMERS: "Create a customer",
  PERM_EDIT_CUSTOMERS: "Edit customers",
  PERM_DELETE_CUSTOMERS: "Delete a customer",
  PERM_VIEW_MODULE_INVOICES: "Access the invoices module",
  PERM_VIEW_ANY_INVOICES: "See all invoices",
  PERM_VIEW_INVOICES: "See invoice details",
  PERM_CREATE_INVOICES: "Create an invoice",
  PERM_EDIT_INVOICES: "Edit invoices",
  PERM_DELETE_INVOICES: "Delete an invoice",
  PERM_VIEW_MODULE_PRODUCTS: "Access the products module",
  PERM_VIEW_ANY_PRODUCTS: "See all products",
  PERM_VIEW_PRODUCTS: "See product details",
  PERM_CREATE_PRODUCTS: "Create a product",
  PERM_EDIT_PRODUCTS: "Edit products",
  PERM_EDIT_OWN_PRODUCTS: "Edit own product",
  PERM_DELETE_PRODUCTS: "Delete products",
  PERM_VIEW_MODULE_ORGANIZATIONS: "Access the campings module",
  PERM_VIEW_ANY_ORGANIZATIONS: "See all campings",
  PERM_VIEW_ORGANIZATIONS: "See camping details",
  PERM_CREATE_ORGANIZATIONS: "Create an camping",
  PERM_EDIT_ORGANIZATIONS: "Edit campings",
  PERM_EDIT_OWN_ORGANIZATIONS: "Edit own camping",
  PERM_VIEW_OWN_ORGANIZATIONS: "View own camping",
  PERM_DELETE_ORGANIZATIONS: "Delete campings",
  PERM_VIEW_MODULE_RESELLERS: "Access the resellers module",
  PERM_VIEW_ANY_RESELLERS: "View all resellers",
  PERM_VIEW_RESELLERS: "View resellers details",
  PERM_CREATE_RESELLERS: "Create a reseller",
  PERM_EDIT_RESELLERS: "Edit resellers",
  PERM_EDIT_OWN_RESELLERS: "Edit own reseller",
  PERM_DELETE_RESELLERS: "Delete resellers",
  PERM_VIEW_MODULE_USERS: "Access the users module",
  PERM_VIEW_ANY_USERS: "View all users",
  PERM_VIEW_USERS: "View users details",
  PERM_CREATE_USERS: "Create a user",
  PERM_EDIT_USERS: "Edit users",
  PERM_DELETE_USERS: "Delete users",
  PERM_VIEW_MODULE_ROLES: "Access the roles module",
  PERM_VIEW_ANY_ROLES: "View all roles",
  PERM_VIEW_ROLES: "View roles details",
  PERM_CREATE_ROLES: "Create a role",
  PERM_EDIT_ROLES: "Edit roles",
  PERM_DELETE_ROLES: "Delete roles",
  PERM_VIEW_ANY_PERMISSIONS: "View all permissions",
  PERM_VIEW_PERMISSIONS: "View permissions details",
  PERM_VIEW_ANY_LOGS: "View all logs",
  PERM_VIEW_LOGS: "View log details",

  PERM_VIEW_MODULE_BOOKINGS: "Access the booking module",
  PERM_VIEW_ANY_BOOKINGS: "See all bookings",
  PERM_VIEW_BOOKINGS: "See booking details",
  PERM_CREATE_BOOKINGS: "Create a booking",
  PERM_EDIT_BOOKINGS: "Edit bookings",
  PERM_EDIT_OWN_BOOKINGS: "Edit own booking",
  PERM_DELETE_BOOKINGS: "Delete bookings",

  PERM_VIEW_MODULE_OPTIONS: "Access the settings module",
  PERM_VIEW_ANY_OPTIONS: "View any settings",
  PERM_EDIT_OPTIONS: "Edit settings",

  PERM_VIEW_MODULE_PRICING_RULES: "Access the pricing rule module",
  PERM_VIEW_PRICING_RULES: "See pricing rule details",
  PERM_VIEW_ANY_PRICING_RULES: "See all pricing rules",
  PERM_CREATE_PRICING_RULES: "Create a pricing rule",
  PERM_EDIT_PRICING_RULES: "Edit pricing rule",
  PERM_DELETE_PRICING_RULES: "Delete pricing rules",

  PERM_VIEW_MODULE_POLICIES: "Access the payment policy module",
  PERM_VIEW_POLICIES: "See payment policy details",
  PERM_VIEW_ANY_POLICIES: "See all payment policy",
  PERM_CREATE_POLICIES: "See all payment policy",
  PERM_EDIT_POLICIES: "Edit payment policy",
  PERM_DELETE_POLICIES: "Delete payment policy",

  PERM_VIEW_MODULE_TEMPLATES_MAILS: "Access the mail template module",
  PERM_VIEW_ANY_TEMPLATES_MAILS: "View any templates",
  PERM_VIEW_TEMPLATES_MAILS: "View mail template",
  PERM_CREATE_TEMPLATES_MAILS: "Create mail template",
  PERM_EDIT_TEMPLATES_MAILS: "Edit mail template",
  PERM_DELETE_TEMPLATES_MAILS: "Delete mail template",
};
