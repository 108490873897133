<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!templateMail">
      <span class="loader"></span>
    </span>
    <template-mail-form
      v-if="templateMail"
      :loading="loading"
      :templateMailData="templateMail"
      :formErrors="formErrors"
      @templateMailSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseTemplateMailModal="onCloseTemplateMailModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import TemplateMailForm from "../partials/TemplateMailForm.vue";

export default {
  layout: "DashboardLayout",

  components: { TemplateMailForm },

  mixins: [alertLeave],

  props: {
    templateMailId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      templateMail: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("templatesMails/get", this.templateMailId);
        this.templateMail = this.$store.getters["templatesMails/templatesMail"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(templateMail) {
      this.loading = true;
      let templateMailData = cloneDeep(templateMail);

      try {
        await this.$store.dispatch("templatesMails/update", templateMailData);
        this.$notify({
          type: "success",
          message: this.$t("TEMPLATES_MAILS.TEMPLATE_MAIL_UPDATED"),
        });
        const templateMail =
          this.$store.getters["templatesMails/templatesMail"];
        this.$emit("onViewTemplateMail", templateMail, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseTemplateMailModal() {
      this.$emit("onCloseTemplateMailModal");
    },
  },
};
</script>
