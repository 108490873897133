export default {
  POLICIES_LIST: "List of policies",
  ADD_POLICY: "Add a policy",
  POLICY_DETAILS: "Policy details",
  POLICY_ADDED: "Policy added",
  EDIT_POLICY: "Edit a policy",
  POLICY_UPDATED: "Updated policy",
  DELETE_THIS_POLICY: "Do you want to delete this selected policy ?",
  DELETE_POLICY_SUCCESS: "Deletion of policy was completed successfully",
  POLICY_DELETED: "Policy deleted",
  NO_DATA: "You have not added any clients yet, click on the button to add.",
  POLICY_NUMBER: "N°",
  POLICY_NAME: "Name",
  POLICY_TYPE: "Selecting the policy type\n",
  POLICY_TYPE_DETAILS: "Policy type",
  POLICY_DESCRIPTION: "Description",
  POLICY_CREATED_AT: "Create at",
  POLICY_MODIFIED_AT: "Modified at",
  POLICY_GENERAL_INFORMATION: "General\n",
  POLICY_INFORMATION_GENERAL: "General information",
  POLICY_PAYMENT: "Payment Policies",
  POLICY_PAYMENT_TYPE_LABEL: "Type",
  POLICY_PAYMENT_TYPE: "Select 1 from the following options",
  POLICY_PAYMENT_TYPE_AFTER_CREATE: "After creating the reservation",
  POLICY_PAYMENT_TYPE_AFTER_CANCEL: "After canceling the reservation",
  POLICY_PAYMENT_TYPE_AFTER_ARRIVE: "After the reservation arrives",
  POLICY_PAYMENT_TYPE_BEFORE_ARRIVE: "Before and until the reservation arrives",
  POLICY_PAYMENT_TYPE_BEFORE_DEPARTURE: "Before departure of the reservation",
  POLICY_PAYMENT_TYPE_AFTER_DEPARTURE: "After departure of the reservation",
  PASTE: "Paste",
  POLICY_STATUS: "Policy Status",
  STATUS: "Status",
  POLICY_SELECT: "Select from list",
  POLICY_ACTIVE: "Active",
  POLICY_INACTIVE: "Inactive",
  POLICY_SELECT_GUARANTEE: "Select one or more guarantee policies",
  POLICY_PAYMENTS: "Payment policy",
  POLICY_CANCELLATION: "Cancellation policy",
  POLICY_GUARANTEE: "Warranty Policy",
  POLICY_GUARANTEE_LABEL: "Select one or more warranty policies",
  POLICY_CANCEL_LABEL: "Select a cancellation policy",
  POLICY_HOURS: "Number of hours",
  HOURS: "hours",
  POLICY_AUTOMATIQUE: "Automatique",
  PAYMENT: "Payment",
  PAYMENT_TYPE: "Selection",
  PAYMENT_TYPE_PERCENT: "Percentage",
  PAYMENT_TYPE_DOLLAR: "Dollar",
  PAYMENT_TYPE_NIGHT: "Night(s)",
  PAYMENT_TYPE_PERCENT_TITLE: "Percentage to charge",
  PAYMENT_TYPE_DOLLAR_TITLE: "Amount to charge",
  PAYMENT_TYPE_NIGHT_TITLE: "Number of nights to charge",
  CANCELLATION_TITLE: "Cancellation fees",
  COMMUNICATION: "Communication",
  SUCCESS: "If successful",
  ERROR: "In case of failure",
  SELECT_EMAIL: "Select an email",
  SELECTED_EMAIL: "Email selectionned",
  SELECT_EMAIL_PLACEHOLDER: "Select an email from the list",
  NO_SEND_EMAIL: "Do not send emails",
  SEND_EMAIL: "Send an email to the customer",
  NO_SEND_EMAIL_CAMPING: "Do not send emails to the campsite",
  SEND_EMAIL_CAMPING: "Send an email to the campsite",
  SEND_EMAIL_NB: "NB: To inform you that the campsite that the cancellation fees could not be applied",
  ADD: "Add",
  CANCEL: "Cancel",
  COPY_FAILURE: "Failed to copy information.",
  INVALID_PASTE_FORMAT: "Failed to paste information. Invalid format.",
  ERROR_FETCHING_DATA_IN_CLIPBOARD: "Failed to retrieve information from clipboard.",
  CREATE_MODEL: "Create a new template",
  GUARANTEES_POLICY:"Guarantee(s) policy(ies)",
  CANCELLATION_POLICY:"Cancellation policy",
};
