<template>
  <div class="section">
    <dl class="row">
      <dt>
        {{ $formatCurrency(item.unit_price) }} ( x {{ $t("COMMON.UNIT_DAYS", { days: item.quantity }) }} )
      </dt>
      <dd>{{ $formatCurrency(item.pricing.subtotal) }}</dd>
    </dl>
    <dl class="row" v-if="item.salesOrder.package != null">
      <dt>
        {{ $t("PACKAGES.PLATEFORME_CHARGE") }}
      </dt>
      <dd>{{ $formatCurrency(item.salesOrder.package.price) }}</dd>
    </dl>
    <dl class="row" v-for="(tax, key) in item.pricing.taxes.details" :key="key">
      <dt>{{ tax.name }}</dt>
      <dd>{{ $formatCurrency(tax.amount) }}</dd>
    </dl>
    <dl class="row">
      <dt>{{ $t("COMMON.TOTAL_TAXES") }}</dt>
      <dd>{{ $formatCurrency(item.pricing.taxes.total ?? 0) }}</dd>
    </dl>
    <dl class="row subtotal">
      <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
      <dd>{{ $formatCurrency(item.pricing.subtotal) }}</dd>
    </dl>
  </div>
</template>
<script>

export default {
  name: "order-item-summary",
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
      description: "SalesOrderItem object",
    },
  },
  data() {
    return {};
  },
};
</script>
