import common from "./common";
import apps from "./apps";
import errors from "./errors";
import organizations from "./organizations";
import resellers from "./resellers";
import users from "./users";
import roles from "./roles";
import permissions from "./permissions";
import products from "./products";
import resellerInvoices from "./resellerInvoices";
import resellerPayments from "./resellerPayments";
import resellerProduct from "./resellerProduct";
import resellerServices from "./resellerServices";
import invoices from "./invoices";
import customers from "./customers";
import packages from "./packages";
import subscriptions from "./subscriptions";
import payments from "./payments";
import profile from "./profile";
import contacts from "./contacts";
import suppliers from "./suppliers";
import locations from "./locations";
import files from "./files";
import notifications from "./notifications";
import purchasesOrders from "./purchasesOrders";
import purchasesInvoices from "./purchasesInvoices";
import purchasesDeliveries from "./purchasesDeliveries";
import purchasesPayments from "./purchasesPayments";
import salesOrders from "./salesOrders";
import salesInvoices from "./salesInvoices";
import salesDeliveries from "./salesDeliveries";
import salesPayments from "./salesPayments";
import warehouses from "./warehouses";
import stockMovements from "./stockMovements";
import pricingRules from "./pricingRules";
import imports from "./imports";
import properties from "./properties";
import propertyTransactions from "./propertyTransactions";
import auctions from "./auctions";
import bids from "./bids";
import spots from "./spots";
import spotCategories from "./spotCategories";
import equipmentCategories from "./equipmentCategories";
import equipments from "./equipments";
import configuration from "./configuration";
import spotTypes from "./spotTypes";
import bookings from "./bookings";
import options from "./options";
import dates from "./dates";
import validationMessages from 'vee-validate/dist/locale/fr';
import policies from "@/i18n/fr/policies";
import pricingSeasons from "@/i18n/fr/pricingSeasons";
import templatesMail from "@/i18n/fr/templatesMails";
import reports from "./reports";

export default {
  COMMON: common,
  APPS: apps,
  ERRORS: errors,
  USERS: users,
  ORGANIZATIONS: organizations,
  RESELLERS: resellers,
  ROLES: roles,
  PERMISSIONS: permissions,
  PRODUCTS: products,
  INVOICES: invoices,
  CUSTOMERS: customers,
  SUBSCRIPTIONS: subscriptions,
  PACKAGES: packages,
  PAYMENTS: payments,
  PROFILE: profile,
  CONTACTS: contacts,
  SUPPLIERS: suppliers,
  LOCATIONS: locations,
  FILES: files,
  NOTIFICATIONS: notifications,
  PURCHASES_ORDERS: purchasesOrders,
  PURCHASES_INVOICES: purchasesInvoices,
  PURCHASES_DELIVERIES: purchasesDeliveries,
  PURCHASES_PAYMENTS: purchasesPayments,
  SALES_ORDERS: salesOrders,
  SALES_INVOICES: salesInvoices,
  SALES_DELIVERIES: salesDeliveries,
  SALES_PAYMENTS: salesPayments,
  RESELLER_PRODUCTS: resellerProduct,
  RESELLER_SERVICES: resellerServices,
  RESELLER_INVOICES: resellerInvoices,
  RESELLER_PAYMENTS: resellerPayments,
  WAREHOUSES: warehouses,
  STOCK_MOVEMENTS: stockMovements,
  IMPORTS: imports,
  PROPERTIES: properties,
  AUCTIONS: auctions,
  BIDS: bids,
  PROPERTY_TRANSACTIONS: propertyTransactions,
  SPOTS: spots,
  SPOT_CATEGORIES: spotCategories,
  EQUIPMENT_CATEGORIES: equipmentCategories,
  EQUIPMENTS: equipments,
  CONFIGURATION: configuration,
  SPOT_TYPES: spotTypes,
  POLICIES: policies,
  BOOKINGS: bookings,
  OPTIONS: options,
  PRICING_RULES: pricingRules,
  PRICING_SEASONS: pricingSeasons,
  DATES: dates,
  REPORTS: reports,
  TEMPLATES_MAILS: templatesMail,
  validations: {
    ...validationMessages
  }
};
