import qs from "qs";
import axios from "axios";
import Jsona from "jsona";
//import {ORDER_STATUS_DRAFT} from "@/constants/orders";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
 

  return axios.get(`${url}/reports/statistics`, options).then((response) => {
    console.log("report-statistics-service.js total() response!!!!!!!!!!!!!!!!!: ", response.data.total);
    const statisticsArray = Object.values(response.data.statistics); 
    return {
      list: statisticsArray,
      total: response.data.total,
    };
  });
}



export default {
  list,
};
