<template>
  <div>
    <router-view v-if="appConfig"></router-view>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert2";
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/store/eventBus";

const _30_seconds = 30 * 1000 ;
const _500_millis = 1000 / 2 ;

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        return [
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [{ rel: "icon", type: "image/png", href: "/favicon.ico" }],
      script: (function () {})(),
    };
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters("salesOrders", { cart: "cart", notifications: "cartNotifications" }),
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("salesOrders", {getCart: "cart"}),
    async showPopUp()
    {
      if(this.notifications?.length)
      {
        for(let i = 0; i < this.notifications?.length; i++)
        {
          let notification = this.notifications[i] ;

          let nowMoment = moment.utc(moment()) ;

          notification.seconds = notification.blockedAt.diff(nowMoment, 'seconds') ;

          let remainingMinutes = Math.floor(notification.seconds / 60);
          let secondes_restantes = notification.seconds % 60;

          if(secondes_restantes === 0 &&
              notification.expirations.includes(remainingMinutes) &&
              !notification.notifiedMinutes.includes(remainingMinutes))
          {
            notification.notifiedMinutes.push(remainingMinutes) ;
            localStorage.setItem(
                `booking-draft-notification-cart-${notification.id}`,
                JSON.stringify(notification.notifiedMinutes)
            );

            swal.fire({
              title: notification.seconds ? this.$t("COMMON.CART_CHRONO_TITLE") : this.$t("COMMON.CART_CHRONO_END_TITLE"),
              type: 'info',
              confirmButtonText: this.$t("COMMON.CLOSE"),
              html: notification.seconds  ? this.$t("COMMON.MINUTES_WARNING", [notification.code, remainingMinutes]) : this.$t("COMMON.MINUTES_EXPIRED", [notification.code]),
              customClass: {
                popup: "default-popup",
              },
              buttonsStyling: false,
            });

            // reservation expriree, on supprime
            if(!notification.seconds)
            {
              // recuperation du order
              await this.$store.dispatch("salesOrders/get", notification.orderId);
              let orderToDelete = await this.$store.getters["salesOrders/salesOrder"];

              // suppression | TODO : ne pas oublier que le salesOrder dans le futur va contenir plusieurs booking
              orderToDelete.items.forEach((item) => {
                this.$store.dispatch("salesOrderItems/destroy", item.id);
                this.$store.dispatch("bookings/destroy", item.salesOrderable.id);
              });
              await this.$store.dispatch("salesOrders/destroy", notification.orderId);

              // mise a jour du panier
              await this._getCart();

              // Rechercher les sales orders si on est sur la page du panier
              EventBus.$emit("onReloadSalesOrdersPage") ;
            }
          }
        }
      }
    },
    _getCart() {
      this.getCart(this.me.id)
    }
  },

  destroyed() {
    clearInterval(this.showPopUp) ;
    clearInterval(this._getCart) ;
  },

  async mounted()
  {
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      },
    });

    if (this.$store.getters["isAuthenticated"])
    {
      await this.$store.dispatch("profile/me");
      await this.$store.dispatch("organizations/initState");

      setInterval(this.showPopUp, _500_millis) ;
      setInterval(this._getCart, _30_seconds) ;
    }
    else {
      console.log("NOT LOGGED");
    }
  },

  watch: {
    appConfig: {
      handler: function (val) {
        if (val) {
          swal.close();
        }
      },
      deep: true,
    },
    me: {
      handler: function (val)
      {
        if(val) {
          this.getCart(val.id) ;
        }
      }
    },
    deep: true
  },
};
</script>
