<script>
export default {
  name: "select-an-organization-message"
}
</script>

<template>
  <div class="page-wrapper orga-select">
    <img src="/img/kw-fire.svg" alt="icon"/>
    <p>{{ $t("COMMON.PLEASE_SELECT_ORGANIZATION") }}</p>
  </div>
</template>