export default {
  SALES_PAYMENTS_LIST: "Liste des paiements",
  SALES_PAYMENTS_CODE_LABEL: "Nº de paiement",
  ADD_SALES_PAYMENT: "Ajouter un paiement",
  SALES_PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_SALES_PAYMENT: "Modifier le paiement",
  SALES_PAYMENT_SOURCE_MANUAL: "Manuel",
  SALES_PAYMENT_SOURCE_PAYPAL: "Paypal",
  SALES_PAYMENT_SOURCE_STRIPE: "Stripe",
  SALES_PAYMENT_SOURCE_CLOVER: "Clover",
  SALES_PAYMENT_SOURCE_CASH: "Cash",
  SALES_PAYMENT_SOURCE_DEBIT: "Débit",
  SALES_PAYMENT_SOURCE_VISA: "Visa",
  SALES_PAYMENT_SOURCE_MASTERCARD: "MasterCard",
  SALES_PAYMENT_SOURCE_AMEX: "AMEX",
  SALES_PAYMENT_SOURCE_E_TRANSFERT: "E-Transfert",
  SALES_PAYMENT_SOURCE_CHECK: "Chèque",
  SALES_PAYMENT_SOURCE_OTHER: "Autre",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  VIEW_INVOICE: "Voir la facture",
  VIEW_SALES_PAYMENT: "Paiement",
};
