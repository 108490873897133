var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),(!_vm.filterStatus)?_c('el-select',{attrs:{"placeholder":_vm.$t('COMMON.STATUS')},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},[_c('el-option',{attrs:{"value":0,"label":_vm.$t('COMMON.ALL_STATUSES')}}),_vm._l((_vm.statusesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2):_vm._e(),(
          !_vm.filterOrganization &&
          !_vm.filterRecipient &&
          !_vm.filterSalesOrder &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(
          !_vm.filterSalesOrder &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
        )?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e(),_c('base-input',{staticClass:"dates",attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            mode: 'range',
            locale: _vm.flatPickrLocale,
          },"placeholder":"Dates"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.salesDeliveries},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CODE'),"prop":"code","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.STATUS'),"prop":"status","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('sales-delivery-status-badge',{attrs:{"salesDelivery":row}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.RECIPIENT'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.recipient}})]}}])}),(
              !_vm.filterSalesOrder &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
              !_vm.filterOrganization &&
              !_vm.filterSalesOrder &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_DELIVERIES.EXPIRATION_TIME'),"prop":"expiration_time","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"far fa-ellipsis-v"})]),_c('el-dropdown-menu',{staticClass:"actions-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_SALES_DELIVERIES)
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action view",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewSalesDelivery(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-eye.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("COMMON.VIEW_DETAILS")))])])]):_vm._e(),(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_EDIT_SALES_DELIVERIES
                      ) && row.status == _vm.DELIVERY_STATUS_DRAFT
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action edit",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editSalesDelivery(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-edit.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])])]):_vm._e(),(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_DELETE_SALES_DELIVERIES
                      ) && row.status == _vm.DELIVERY_STATUS_DRAFT
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action delete",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteSalesDelivery(row.id)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-delete.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])])]):_vm._e()],1)],1)],1)}}])})],1)],1)]),_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-bdelivery",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }