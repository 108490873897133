export default {
    REPORTS: "Rapports",
    STATISCTICS_LIST: "Statistiques",
    OWNER: "Organisation",
    CAPACITY: "Capacité",
    CAPACITY_RESERVED: "Capacité réservée",
    RESERVED: "Réservée %",
    INCOME: "Revenu",
    NIGHTS: "Nuitées",
  };
  