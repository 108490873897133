<template>
  <el-select
    v-model="spotTypeModel"
    @change="spotTypeChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="placeholder ?? $t('COMMON.TYPE')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getTypes"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option v-if="showAll" :value="null" :label="$t(showAllLabelKey)">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="spotType in spotTypes"
      :key="spotType.id"
      :value="spotType.id"
      :label="spotType.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "spot-type-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    showAllLabelKey: {
      type: String,
      default: "COMMON.ALL",
      required: false,
      description:
        "Le code du texte d'internationalisation pour le libelle de @shawAll",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    spotType: {
      type: String,
      default: null,
      description: "Spot type id",
    },
    filterSpotCategory: {
      type: String,
      default: null,
      description: "SpotCategory id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
    used: {
      type: Boolean,
      default: false,
      description:
        "Mettre à true pour indiquer que l'on veut les types d'emplacements pour lesquelles il existe un emplacement.",
    },
  },

  data() {
    return {
      spotTypeModel: this.spotType,
      spotTypes: {},
    };
  },

  setup() {},

  created() {
    this.getTypes();
  },

  methods: {
    async getTypes(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
            used: this.used,
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterSpotCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_category: this.filterSpotCategory,
            },
          };
        }

        await this.$store.dispatch("spotTypes/list", params);
        const spotTypesArr = await this.$store.getters["spotTypes/list"];
        this.spotTypes = {};
        spotTypesArr.forEach((spotType) => {
          this.spotTypes[spotType.id] = spotType;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    spotTypeChanged(spotType) {
      this.$emit("spotTypeChanged", spotType);
    },
  },

  watch: {
    spotType(spotType) {
      if (spotType) {
        this.spotTypeModel = spotType;
        if (spotType !== this.spotTypeModel) {
          this.getTypes(null, spotType);
        }
      } else {
        this.spotTypeModel = null;
      }
    },
    filterSpotCategory() {
      this.getTypes();
    },
  },
};
</script>
