import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  methods: {
    async showRequestError(error) {
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.errors) {
            for (const err of error.response.data.errors) {
              const errorText = err.detail ? err.detail : err.title;
              await swal.fire({
                title: this.$te(`ERRORS.${errorText}`)
                  ? this.$t(`ERRORS.${errorText}`)
                  : errorText,
                icon: "error",
                customClass: {
                  popup: "default-popup",
                },
                confirmButtonText: this.$t("COMMON.CLOSE"),
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-warning",
              });
            }
          }
        }
      }
    },
  },
};
