<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!templateMail">
      <span class="loader"></span>
    </span>
    <div v-if="templateMail" class="elite-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("TEMPLATES_MAILS.TEMPLATE_DETAIL") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("TEMPLATES_MAILS.TEMPLATES_MAILS_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("TEMPLATES_MAILS.COMMUNICATION") }}</span>
              </li>
              <li>
                <span>{{ $t("TEMPLATES_MAILS.TEMPLATE_DETAIL") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            v-if="$currentUserCan($permissions.PERM_DELETE_TEMPLATES_MAILS)"
            @click="deleteTemplateMail"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE") }}</span>
          </button>
          <button
            class="edit"
            v-if="$currentUserCan($permissions.PERM_EDIT_TEMPLATES_MAILS)"
            @click="editTemplateMail"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <template-mail-view-global :templateMail="templateMail" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import TemplateMailViewGlobal from "../partials/TemplateMailViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    TemplateMailViewGlobal,
  },

  mixins: [requestErrorMixin],

  props: {
    templateMailId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      templateMail: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("templatesMails/get", this.templateMailId);
        this.templateMail = this.$store.getters["templatesMails/templatesMail"];
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editTemplateMail() {
      this.$emit("onEditTemplateMail", this.templateMail);
    },
    deleteTemplateMail() {
      this.$emit("onDeleteTemplateMail", this.templateMail);
    },
  },
};
</script>
