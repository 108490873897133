<template>
  <div class="wrapper">
    <!-- <h1>{{ $t("COMMON.DASHBOARD") }}</h1> -->
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},
  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
