<template>
  <div class="container-fluid">
    <pricing-season-form
      :loading="loading"
      :pricingSeasonData="pricingSeason"
      :formErrors="formErrors"
      @pricingSeasonSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePricingSeasonModal="onClosePricingSeasonModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPricingSeason from "../defaultPricingSeason";
import PricingSeasonForm from "../partials/PricingSeasonForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PricingSeasonForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    let pricingSeasonData = cloneDeep(defaultPricingSeason);
    pricingSeasonData.id = -2;
    pricingSeasonData = this.$fillUserOrganizationData(pricingSeasonData);

    return {
      pricingSeason: pricingSeasonData,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.pricingSeason.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(pricingSeason) {
      this.loading = true;

      const pricingSeasonData = cloneDeep(pricingSeason);
      delete pricingSeasonData.id;

      try {
        await this.$store.dispatch("pricingSeasons/add", pricingSeasonData);
        this.$notify({
          type: "success",
          message: this.$t("PRICING_SEASONS.PRICING_SEASON_ADDED"),
        });
        const pricingSeason = await this.$store.getters["pricingSeasons/pricingSeason"];
        this.$emit("onViewPricingSeason", pricingSeason, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onClosePricingSeasonModal() {
      this.$emit("onClosePricingSeasonModal");
    },
  },
};
</script>
