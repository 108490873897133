export const ELECTRICITY_15_AMP = "15 amp";
export const ELECTRICITY_20_AMP = "20 amp";
export const ELECTRICITY_30_AMP = "30 amp";
export const ELECTRICITY_50_AMP = "50 amp";

export const electricityOptions = [
  ELECTRICITY_15_AMP,
  ELECTRICITY_20_AMP,
  ELECTRICITY_30_AMP,
  ELECTRICITY_50_AMP,
];
