export default {
    REPORTS: "Reports",
    STATISCTICS_LIST: "Statistics List",
    OWNER: "Owner",
    CAPACITY: "Capacity",
    CAPACITY_RESERVED: "Capacity Reserved",
    RESERVED: "Reserved %",
    INCOME: "Income",
    NIGHTS: "Nights",
  };
  