<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img v-if="pack.picture" :src="`${pack.picture}`" class="argon-image" />
        <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        />
      </div>
      <div class="all-infos-text">
        <!--<dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ pack.name }}
          </dd>
        </dl>-->
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="pack.excerpt"></div>
          </dd>
        </dl>
        <!--<dl class="row">
          <dt>{{ $t("COMMON.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(pack.price) }}
          </dd>
        </dl>-->
        <dl class="row">
          <dt>{{ $t("PACKAGES.FREQUENCY") }}</dt>
          <dd>
            {{ pack.frequency }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PACKAGES.MAXIMUM_USERS") }}</dt>
          <dd>
            {{ pack.maximum_users }}
          </dd>
        </dl>
        
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ROLES) &&
            pack.default_role
          "
        >
          <dt>{{ $t("PACKAGES.DEFAULT_ROLE") }}</dt>
          <dd>
            <router-link :to="$objectViewRoute(pack.default_role)">
              {{ `${pack.default_role.name}` }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.TAXES") }}</dt>
          <dd>
            <dl class="row" v-for="(tax, key) in pack.taxes" :key="key">
              <dt class="col-sm-4">{{ tax.name }}</dt>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PACKAGES.BOOKINGS_INVOICED") }}</dt>
          <dd>
            {{ pack.is_invoiced ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </dl>
        <dl class="row" v-if="pack.is_invoiced">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ pack.name }}
          </dd>
        </dl>
        <dl class="row" v-if="pack.is_invoiced">
          <dt>{{ $t("COMMON.CODE") }}</dt>
          <dd>
            {{ pack.code }}
          </dd>
        </dl>
        <dl class="row" v-if="pack.is_invoiced">
          <dt>{{ $t("COMMON.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(pack.price) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="pack.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="pack.created_at">
            {{ $formatDate(pack.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="pack.updated_at">
            {{ $formatDate(pack.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "package-view-global",

  components: {},

  mixins: [],

  props: ["pack"],

  data() {
    return {
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    pack(pack) {},
  },
};
</script>
