import service from "@/store/services/report-statistics-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  statistics: [],
  total: 0,
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, statistics) => {
    state.statistics = statistics;
  },
  SET_TOTAL: (state, total) => {
    state.total = total;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit }, params = {}) {
   // console.log("paramsddddddddddddddddd    ", params);

    return service.list(params, this.$axios)
    .then(({ list, total, statistics }) => {

      console.log("total ----------------$$$$$$$$$$$$$$$", total);
      commit("SET_LIST", list);
      commit("SET_RESOURCE", statistics); // Utilisation correcte de statistic
      commit("SET_TOTAL", total);

    })
    .catch(error => {
      console.error("Erreur lors de l'appel au service : ", error);
    });
  },
};

const getters = {
  list: (state) => state.list,
  //listTotal: (state) => state.meta?.page?.total || 0,
  listTotal: (state) => state.total,
  statistics: (state) => state.statistics,
  url: (state) => state.url,
};

const reportStatistics = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default reportStatistics;
