<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :allowNone="true"
            :organization="salesInvoice.organization.id"
            :filterable="true"
            :showAll="false"
            :disabled="!!salesInvoice.id"
            @organizationChanged="
              (organizationId) => {
                salesInvoice.organization.id = organizationId;
                salesInvoice.allowedLocations = [];
                salesInvoice.salesOrder.id = null;
                salesInvoice.recipient.id = null;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
        >
          <locations-selector
            :locations="salesInvoice.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :disabled="!salesInvoice?.organization?.id"
            :organization="salesInvoice.organization.id"
            @locationsChanged="
              (locations) => {
                salesInvoice.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.location" />
      </div>

      <!-- <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_ORDERS)"
      >
        <base-input
          :label="`${$t('COMMON.SALES_ORDERS')} (*)`"
          :placeholder="$t('COMMON.SALES_ORDERS')"
        >
          <sales-order-selector
            :disabled="!salesInvoice?.organization?.id"
            :allowNone="true"
            :salesOrder="salesInvoice.salesOrder.id"
            :filterable="true"
            :showAll="false"
            :with-no-invoice="true"
            :filterOrganization="salesInvoice.organization.id"
            :filterStatus="ORDER_STATUS_VALIDATED"
            @salesOrderChanged="onSalesOrderChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.salesOrder || apiValidationErrors.undefined" />
      </div> -->

      <div class="form-wrapper">
        <base-input
          :label="$t('SALES_INVOICES.EXPIRATION_TIME')"
          :placeholder="$t('SALES_INVOICES.EXPIRATION_TIME')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              minDate: 'today',
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="salesInvoice.expiration_time"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.expiration_time" />
      </div>

      <div class="form-wrapper">
        <base-input :label="`${$t(`SALES_INVOICES.SELECT_CUSTOMERS`)} (*)`">
          <customer-selector
            :allowNone="true"
            :customer="salesInvoice.recipient.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="salesInvoice.organization.id"
            :disabled="!salesInvoice?.organization?.id"
            @customerChanged="onCustomerChange"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.recipient" />
      </div>

      <div class="form-wrapper full">
        <h4>{{ $t("COMMON.NOTE") }}</h4>
        <html-editor v-model="salesInvoice.excerpt" @change="onFormChanged()">
        </html-editor>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          salesInvoice.id
            ? $t("SALES_INVOICES.EDIT_SALES_INVOICE")
            : $t("COMMON.SEND")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import formMixin from "@/mixins/form-mixin";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import SalesOrderSelector from "@/components/SalesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import {INVOICE_STATUS_DRAFT} from "@/constants/invoices";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    CustomerSelector,
    SalesOrderSelector,
    CountrySelector,
    StateSelector,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["salesInvoiceData", "formErrors", "loading"],

  data() {
    let salesInvoiceData = { ...this.salesInvoiceData };
    salesInvoiceData = this.$fillUserOrganizationData(salesInvoiceData);

    return {
      salesInvoice: salesInvoiceData,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  created() {},

  methods: {
    async handleSubmit()
    {
      let salesInvoiceData = cloneDeep(this.salesInvoice);
      salesInvoiceData.status = INVOICE_STATUS_DRAFT;

      if(salesInvoiceData.expiration_time) {
        salesInvoiceData.expiration_time = moment(salesInvoiceData.expiration_time).toISOString();
      }

      salesInvoiceData = this.$fillUserOrganizationData(salesInvoiceData);

      if (!salesInvoiceData.salesOrder.id) {
        delete salesInvoiceData.salesOrder;
      }
      if (!salesInvoiceData.billing_company_name) {
        salesInvoiceData.billing_company_name = "N/A";
      }
      if (!salesInvoiceData.billing_firstname) {
        salesInvoiceData.billing_firstname = "N/A";
      }
      if (!salesInvoiceData.billing_lastname) {
        salesInvoiceData.billing_lastname = "N/A";
      }

      if(!salesInvoiceData.sourceWarehouse?.id)
      {
        delete salesInvoiceData.sourceWarehouse;
        salesInvoiceData?.relationshipNames.filter(rel => rel !== "sourceWarehouse");
      }

      this.$emit("salesInvoiceSubmitted", salesInvoiceData);
    },

    onSalesOrderChanged(salesOrderId, salesOrder)
    {
      this.salesInvoice.salesOrder.id = salesOrderId;
      if (salesOrder)
      {
        this.salesInvoice.recipient.id = salesOrder.recipient.id;
        this.salesInvoice.sourceWarehouse.id = salesOrder.sourceWarehouse?.id;
        this.salesInvoice.billing_entity_type = salesOrder.billing_entity_type;
        this.salesInvoice.billing_firstname = salesOrder.billing_firstname;
        this.salesInvoice.billing_lastname = salesOrder.billing_lastname;
        this.salesInvoice.billing_company_name = salesOrder.billing_company_name;
        this.salesInvoice.billing_email = salesOrder.billing_email;
        this.salesInvoice.billing_country = salesOrder.billing_country;
        this.salesInvoice.billing_state = salesOrder.billing_state;
        this.salesInvoice.billing_city = salesOrder.billing_city;
        this.salesInvoice.billing_zipcode = salesOrder.billing_zipcode;
        this.salesInvoice.billing_address = salesOrder.billing_address;
      }
      this.onFormChanged();
    },

    onCustomerChange(customerId, customer)
    {
      this.salesInvoice.recipient.id = customerId;
      if (customer)
      {
        this.salesInvoice.billing_entity_type = customer.customer_type;
        this.salesInvoice.billing_firstname = customer.firstname;
        this.salesInvoice.billing_lastname = customer.lastname;
        this.salesInvoice.billing_company_name = customer.company_name;
        this.salesInvoice.billing_email = customer.email;
        this.salesInvoice.billing_country = customer.country;
        this.salesInvoice.billing_state = customer.state;
        this.salesInvoice.billing_city = customer.city;
        this.salesInvoice.billing_zipcode = customer.zipcode;
        this.salesInvoice.billing_address = customer.address;
      }
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    salesInvoiceData(salesInvoiceData) {
      if (salesInvoiceData) {
        this.salesInvoice = {
          ...this.salesInvoice,
          ...cloneDeep(salesInvoiceData),
        };
        if (!this.salesInvoice.organization) {
          this.salesInvoice.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
