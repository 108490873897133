import moment from "moment";
import { BILLING_ENTITY_TYPE_INDIVIDUAL } from "@/constants/billingInformations";

export default {
  type: "sales-invoices",
  expiration_time: null,
  code: null,
  excerpt: "",
  discounts: [],
  relationshipNames: [
    "recipient",
    "organization",
    "allowedLocations",
    "salesOrder",
    "sourceWarehouse",
  ],
  recipient: {
    type: "customers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  salesOrder: {
    type: "sales-orders",
    id: null,
  },
  sourceWarehouse: {
    type: "warehouses",
    id: null,
  },
  items: [],
  allowedLocations: [],

  billing_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
  billing_company_name: "N/A",
  billing_firstname: "N/A",
  billing_lastname: "N/A",
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: "N/A",
  billing_zipcode: "N/A",
  billing_address: "N/A",
  billing_email: "N/A",
};
