<template>
  <div>
    <form
      class="add-form"
      ref="pricing_season_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="add-form-header">
        <div class="pricing-title-left">
          <h3>{{
              pricingSeason.id !== -2
                ? $t("PRICING_SEASONS.EDIT_PRICING_SEASON")
                : $t("PRICING_SEASONS.ADD_PRICING_SEASON")
            }}</h3>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.PRICING_SEASONS_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("PRICING_SEASONS.PRICING_SEASONS_LIST") }}</span>
              </li>
              <li>
                <span>{{
                    pricingSeason.id !== -2
                      ? $t("PRICING_SEASONS.EDIT_PRICING_SEASON")
                      : $t("PRICING_SEASONS.ADD_PRICING_SEASON")
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="add-form-inner">
        <div class="form-wrapper title">
          <h2>{{ $t("COMMON.ORGANIZATION") }}</h2>
        </div>

        <div class="form-wrapper title">
          <h2>{{ $t("PRICING_SEASONS.PRICE") }}</h2>
        </div>

        <div class="form-wrapper full">
          <base-input
            :name="`${$t('PRICING_SEASONS.NAME')}`"
            :label="`${$t('PRICING_SEASONS.NAME')} (*)`"
            v-model="pricingSeason.name"
            :required="true"
          />
        </div>

        <div class="form-wrapper full">
          <base-input
            :label="`${$t('PRICING_SEASONS.SELECT_PRICE')} (*)`"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PRICING_RULES)"
          >
            <pricing-rule-selector
              :pricing-rule="pricingSeason.pricingRules.map((item) => item.id)"
              :filterable="true"
              :showAll="false"
              :multiple="true"
              @pricingRuleChanged="
                (pricingRules) => {
                  pricingSeason.pricingRules = pricingRules;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.pricingRules" />
        </div>

        <div class="form-wrapper title">
          <h2>{{ $t("COMMON.DATE") }}</h2>
        </div>

        <!-- date range -->
        <div class="form-wrapper width_1-2">
          <base-input :label="`${$t('PRICING_SEASONS.SELECT_START_DATE')}`">
            <el-date-picker
              v-model="pricingSeason.start_date"
              type="date"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              class="w-100"
            />
            <validation-error :errors="apiValidationErrors.start_date" />
          </base-input>
        </div>
        <div class="form-wrapper width_1-2">
          <base-input :label="`${$t('PRICING_SEASONS.SELECT_END_DATE')}`">
            <el-date-picker
              v-model="pricingSeason.end_date"
              type="date"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              class="w-100"
            />
            <validation-error :errors="apiValidationErrors.end_date" />
          </base-input>
        </div>

        <div class="form-wrapper title">
          <h2>{{ $t("PRICING_SEASONS.WEEK_PRICE") }}</h2>
        </div>

        <div class="form-wrapper">
          <base-input :label="`${$t('PRICING_SEASONS.SELECT_TYPE_LABEL')}`">
            <el-select
              :name="`title`"
              v-model="pricingSeason.pricing_type"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="val in pricingTypes"
                :key="val"
                :value="val"
                :label="`${$t(`PRICING_SEASONS.${val}`)}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.pricing_type" />
          </base-input>
        </div>

        <div class="form-wrapper width_1-2">
          <base-input :label="`${$t('PRICING_RULES.VARIATION_PLUS_OR_MINUS')}`">
            <el-select
              v-model="pricingSeason.variation"
              :placeholder="`${$t('PRICING_RULES.VARIATION_PLUS_OR_MINUS')}`"
            >
              <el-option
                v-for="(label, val) in signOption"
                :key="val"
                :value="val"
                :label="`${val}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.variation" />
          </base-input>
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.' + pricingSeason.pricing_type)}`"
            v-model="pricingSeason.value"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper title">
          <h2>{{ $t("PRICING_SEASONS.WEEKEND_PRICE") }}</h2>
        </div>

        <div class="form-wrapper">
          <base-input :label="`${$t('PRICING_SEASONS.SELECT_TYPE_LABEL')}`">
            <el-select
              :name="`title`"
              v-model="pricingSeason.weekend_pricing_type"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="val in pricingTypes"
                :key="val"
                :value="val"
                :label="`${$t(`PRICING_SEASONS.${val}`)}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.weekend_pricing_type" />
          </base-input>
        </div>

        <div class="form-wrapper width_1-2">
          <base-input :label="`${$t('PRICING_RULES.VARIATION_PLUS_OR_MINUS')}`">
            <el-select
              v-model="pricingSeason.weekend_variation"
              :placeholder="`${$t('PRICING_RULES.VARIATION_PLUS_OR_MINUS')}`"
            >
              <el-option
                v-for="(label, val) in signOption"
                :key="val"
                :value="val"
                :label="`${val}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.weekend_variation" />
          </base-input>
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.PRICE$')}`"
            v-model="pricingSeason.weekend_value"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper title">
          <h2>{{ $t("PRICING_SEASONS.RESTRICTION") }}</h2>
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.MINIMUM_STAY')}`"
            v-model="pricingSeason.minimum_stay"
            :type="`number`"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.MAXIMUM_STAY')}`"
            v-model="pricingSeason.maximum_stay"
            :type="`number`"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.MINIMUM_DAY_BEFORE')}`"
            v-model="pricingSeason.minimum_day_before_arrival"
            :type="`number`"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper width_1-2">
          <base-input
            :label="`${$t('PRICING_SEASONS.MAXIMUM_DAY_BEFORE')}`"
            v-model="pricingSeason.maximum_day_before_arrival"
            :type="`number`"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <div class="form-wrapper width_1-2">
          <base-input :label="`${$t('PRICING_SEASONS.ACTIVE_FROM')}`">
            <el-date-picker
              v-model="pricingSeason.active_from"
              type="date"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              class="w-100"
            />
            <validation-error :errors="apiValidationErrors.active_from" />
          </base-input>
        </div>
      </div>

      <div class="submit-wrapper">
        <base-button
          class="btn cancel"
          type="button"
          @click="onClosePricingSeasonModal"
        >
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          {{
            pricingSeason.id !== -2
              ? $t("COMMON.EDIT")
              : $t("COMMON.ADD_ITEM")
          }}
        </base-button>
      </div>
    </form>

    <!--<global-loading :loading="true" />-->
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Checkbox, DatePicker, Input, Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError.vue";
import PricingRuleSelector from "@/components/PricingRuleSelector.vue";
import { PRICING_SEASON_TYPES } from "@/constants/common";
import { signVariationOption } from "@/constants/pricingRules";

export default {
  layout: "DashboardLayout",

  components: {
    PricingRuleSelector,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
  },

  mixins: [formMixin],

  props: ["pricingSeasonData", "formErrors", "loading", "hideOrganization"],

  data() {
    return {
      signOption: signVariationOption,
      pricingSeason: this.pricingSeasonData,
      pricingSeasons: [],
      pricingTypes: PRICING_SEASON_TYPES,
      total: 0,
      showModal: false,
      processing: false,
    };
  },

  created() {},

  mounted() {},

  computed: {
    number() {
      return number;
    },
  },

  methods: {
    log() {
      console.log(arguments);
    },

    async handleSubmit() {
      let pricingSeasonData = cloneDeep(this.pricingSeason);
      this.$emit("pricingSeasonSubmitted", pricingSeasonData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onClosePricingSeasonModal() {
      this.$emit("onClosePricingSeasonModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    pricingSeasonData(pricingSeasonData) {
      if (pricingSeasonData) {
        this.pricingSeason = {
          ...this.pricingSeason,
          ...cloneDeep(pricingSeasonData),
        };
      }
    },
  },
};
</script>
