import {
  POLICIES_PAYMENT_TYPES_AFTER_CREATE,
  POLICIES_TYPE_CANCELLATION,
  PAYMENT_TYPES_PERCENT
} from "@/constants/common";

export default {
  type: "policies",
  status: false,
  name: "",
  policies_type: POLICIES_TYPE_CANCELLATION,
  description: "<p></p>",
  payment_policies: POLICIES_PAYMENT_TYPES_AFTER_CREATE,
  payment_policies_hours: 24,
  payment_type: PAYMENT_TYPES_PERCENT,
  payment_value: 0,
  payment_auto: false,
  alert_success: false,
  alert_success_model_id: null,
  alert_error: false,
  alert_error_model_id: null,
  alert_error_camping: false,
  alert_error_camping_model_id: null,
  created_at: null,
  updated_at: null,
  relationshipNames: [
    "organization",
    "cancellations",
    "guarantees",
    "successMail",
    "errorMail",
    "errorCampingMail",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  cancellations: [],
  guarantees: [],
  successMail: null,
  errorMail: null,
  errorCampingMail: null,
};
