export default {
  DEFAULT_MODULE: "Application",
  ADMIN: "Administration",
  SPOT_MODULE: "Emplacements",
  CRM_MODULE: "Crm",
  SALES_MODULE: "Ventes",
  PURCHASES_MODULE: "Achats",
  INVENTORY_MODULE: "Inventaires",
  FILE_EXPLORER_MODULE: "Explorateur de fichiers",
  ADMIN_MODULE: "Administration",
  DASHBOARD: "Tableau de bord",
  RESELLER_MODULE: "Revendeur",
  AUCTION_MODULE: "Encans",
  EQUIPEMENT_MODULE: "Équipements",
  CUSTOMER_MODULE: "Clients",
  POLICIES_MODULE: "Politiques",
  PRICING_SEASONS_MODULE: "Saisons",
  PAYMENT_MODULE: "Paiements",
  HISTORY_MODULE: "Historique",
  CONFIGURATION_MODULE: "Configuration",
  BOOKING_MODULE: "Réservations",
  CALENDAR_MODULE: "Calendrier",
  PRICING_MODULE: "Gestion de prix",
  INVOICING_MODULE: "Facturation",
};
