var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('div',{staticClass:"page-wrapper-table-header-left"},[_c('base-input',{staticClass:"search",attrs:{"type":"search","prepend-icon":"far fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(
            !_vm.filterReseller &&
            !_vm.filterOrganization &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
          )?_c('reseller-selector',{on:{"resellerChanged":(resellerId) => (_vm.selectedReseller = resellerId)}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e()],1),_c('div',{staticClass:"page-wrapper-table-header-right"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),(_vm.roles.length > 0)?_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush clickable",attrs:{"header-row-class-name":"thead-light","data":_vm.roles},on:{"sort-change":_vm.sortChange,"row-click":_vm.selectRow}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.NAME'),"prop":"name","sortable":"custom","min-width":"220"}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
              !_vm.filterOrganization &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"prop":"organization","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.organization}})]}}],null,false,2726687549)}):_vm._e(),(
              !_vm.filterReseller &&
              !_vm.filterOrganization &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"prop":"reseller","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.reseller}})]}}],null,false,2083643056)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"70"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"far fa-ellipsis-v"})]),_c('el-dropdown-menu',{staticClass:"actions-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ROLES))?_c('el-dropdown-item',[_c('a',{staticClass:"table-action view",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewRole(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-eye.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.VIEW_DETAILS"))+" ")])])]):_vm._e(),(
                      _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_ROLES) &&
                      !row.system
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action edit",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editRole(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-edit.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])])]):_vm._e(),(
                      _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_ROLES) &&
                      !row.system
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action delete",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteRole(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-delete.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])])]):_vm._e()],1)],1)],1)}}],null,false,45919824)})],1)],1)]):_vm._e(),(_vm.roles.length > 0)?_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1):_vm._e()]),(_vm.roles.length === 0)?_c('div',{staticClass:"no-data"},[_c('div',{staticClass:"no-data-inner"},[_c('img',{attrs:{"src":"/img/kw-empty-box.svg","alt":"icon"}}),_c('p',[_vm._v(_vm._s(_vm.$t("ROLES.NO_DATA")))]),(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_ROLES))?_c('base-button',{staticClass:"kw-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.onAddRole}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("ROLES.ADD_ROLE")))])]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }