<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!pricingSeason">
      <span class="loader"></span>
    </span>
    <div v-if="pricingSeason" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("PRICING_SEASONS.CREATE_SEASON") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("PRICING_SEASONS.PRICING_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("COMMON.PRICING_SEASONS") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deletePricingSeason"
            v-if="$currentUserCan($permissions.PERM_DELETE_PRICING_SEASONS)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editPricingSeason"
            v-if="$currentUserCan($permissions.PERM_EDIT_PRICING_SEASONS)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <pricing-season-view-global :pricingSeason="pricingSeason" />
    </div>
  </div>
</template>

<script>
// import swal from "sweetalert2";
// import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import PricingSeasonViewGlobal from "@/views/Pages/PricingSeasonsModule/PricingSeasonsManagement/partials/PricingSeasonViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PricingSeasonViewGlobal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    pricingSeasonId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      pricingSeason: null,
    };
  },

  computed: {
    priceName() {
      return "{Find data}"; //this.pricingSeason?.name;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("pricingSeasons/get", {
          id: this.pricingSeasonId,
        });
        this.pricingSeason =
          this.$store.getters["pricingSeasons/pricingSeason"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPricingSeason() {
      this.$emit("onEditPricingSeason", this.pricingSeason);
    },

    deletePricingSeason() {
      this.$emit("onDeletePricingSeason", this.pricingSeason);
    },
  },
};
</script>
