import permissions from "../constants/permissions";
import PricingRuleModuleLayout from "@/views/Pages/PricingRuleModule/PricingRuleModuleLayout.vue";
import PricingRuleModuleMenu from "@/views/Pages/PricingRuleModule/PricingRuleModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListPricingRulePage = () =>
  import(
    "@/views/Pages/PricingRuleModule/PricingRuleManagement/ListPricingRulePage"
  );
const CalendarPage = () =>
  import("@/views/Pages/PricingRuleModule/PricingRuleManagement/CalendarPage");

const PricingRulePage = () =>
  import("@/views/Pages/PricingRuleModule/PricingRulePage.vue");

const ListPricingSeasonsPage = () =>
  import("@/views/Pages/PricingSeasonsModule/PricingSeasonsPage.vue");

let pricingRuleManagementPages = {
  path: "pricing-rules",
  components: {
    default: PricingRuleModuleLayout,
    SidebarSecondLevelMenu: PricingRuleModuleMenu,
  },
  children: [
    {
      path: "calendar",
      name: "View Calendar",
      components: { default: CalendarPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRICING_RULES,
        hasLevel2: true,
      },
    },
    {
      path: "",
      name: "List PricingRules",
      components: { default: ListPricingRulePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRICING_RULES,
        hasLevel2: true,
      },
    },
  ],
};

const PricingRuleModuleRoutes = [
  {
    path: "",
    components: {
      default: PricingRuleModuleLayout,
      SidebarSecondLevelMenu: PricingRuleModuleMenu,
    },
    children: [
      {
        path: "",
        name: "PricingRule Page",
        components: { default: PricingRulePage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  pricingRuleManagementPages,
];

export default PricingRuleModuleRoutes;
