export default {
  PACKAGES_LIST: "Liste des forfaits",
  ADD_PACKAGE: "Ajouter un forfait",
  PACKAGE_ADDED: "Forfait ajouté",
  EDIT_PACKAGE: "Modifier un forfait",
  PACKAGE_UPDATED: "Forfait mis a jour",
  FREQUENCY: "Fréquence",
  DEFAULT_ROLE: "Rôle par défaut",
  MAXIMUM_USERS: "Max d'utilisateur",
  DELETE_THIS_PACKAGE: "Supprimer ce forfait ?",
  PACKAGE_DELETED: "Forfait supprimé",
  VIEW_PACKAGE: "Forfait",
  BOOKINGS_INVOICED: "Facturer les réservations",
  BOOKINGS_INVOICED_NAME: "Nom",
  BOOKINGS_INVOICED_CODE: "Code",
  BOOKINGS_INVOICED_AMOUNT: "Montant",
  NO_DATA:
    "Vous n’avez ajouté aucun forfait pour le moment, cliquer sur le bouton pour ajouter.",
  PLATEFORME_CHARGE: "Frais de la plateforme"
};
