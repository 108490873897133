<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!spot">
      <span class="loader"></span>
    </span>
    <div v-if="spot" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.DETAILS") }} {{ spot.name }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("SPOTS.SPOT_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("COMMON.DETAILS") }} {{ spot.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            :class="spot.clean_condition === 'DIRTY' ? 'clean' : 'dirty'"
            @click="cleanSpot"
          >
            <img
              v-if="spot.clean_condition === 'DIRTY'"
              src="/img/kw-clean.svg"
              alt="icon"
            />
            <img v-else src="/img/kw-dirty.svg" alt="icon" />
            <span>{{ cleanConditionLabel }}</span>
          </button>
          <button
            class="copy"
            @click="duplicateSpots"
            v-if="$currentUserCan($permissions.PERM_CREATE_SPOTS)"
          >
            <i class="far fa-copy"></i>
            <span>{{ $t("SPOTS.DUPLICATE_SPOTS") }}</span>
          </button>
          <button
            class="delete"
            @click="deleteSpot"
            v-if="$currentUserCan($permissions.PERM_DELETE_SPOTS)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editSpot"
            v-if="$currentUserCan($permissions.PERM_EDIT_SPOTS)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="spot"
      >
        <tab-pane title="spot" id="1" :active="true">
          <span slot="title">
            {{ $t("SPOTS.SPOT_GENERAL_INFORMATION") }}
          </span>
          <spot-view-global :spot="spot" @onContractUpdated="get" />
        </tab-pane>
        <tab-pane title="spot" id="2">
          <span slot="title">
            {{ $t("SPOTS.SPOT_SHEET") }}
          </span>
          <spot-view-sheet :spot="spot" @onContractUpdated="get" />
        </tab-pane>

        <tab-pane title="gallery" id="999" class="spot-sheet-images">
          <span slot="title">
            {{ $t("SPOTS.SPOT_GALLERY") }}
          </span>
          <spot-view-gallery :spot="spot" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { DUPLICATED_DEFAULT_CODE } from "@/constants/spots";
import { Tabs, TabPane } from "@/components";
import SpotViewGallery from "../partials/SpotViewGallery";
import SpotViewGlobal from "../partials/SpotViewGlobal.vue";
import SpotViewSheet from "../partials/SpotViewSheet.vue";
import { CONDITION_DIRTY } from "@/constants/spots";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SpotViewGallery,
    SpotViewSheet,
    SpotViewGlobal,
  },

  mixins: [],

  props: {
    spotId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      spot: null,
    };
  },

  computed: {
    canCleanSpot() {
      return this.spot?.clean_condition == CONDITION_DIRTY;
    },
    cleanConditionLabel() {
      if (this.spot.clean_condition === CONDITION_DIRTY) {
        return this.$t(`SPOTS.CLEAN_SPOT`);
      }

      return this.$t(`SPOTS.DIRTY_SPOT`);
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("spots/get", { id: this.spotId });
        this.spot = this.$store.getters["spots/spot"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async duplicateSpots() {
      try {
        const { value: numCopy } = await swal.fire({
          text: this.$t("SPOTS.NUMBER_OF_COPIES"),
          input: "number",
          customClass: {
            popup: "default-popup",
          },
          inputLabel: this.$t("SPOTS.NUMBER_OF_COPIES"),
          inputValue: 1,
          cancelButtonText: this.$t("COMMON.CANCEL"),
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "You need to write something!";
            }
          },
        });
        if (numCopy) {
          swal.fire({
            title: this.$t("SPOTS.COPYING"),
            html: `0/${numCopy}`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            customClass: {
              popup: "default-popup",
            },
            didOpen: () => {
              swal.showLoading();
            },
          });

          await this.$store.dispatch("spots/list", {
            sort: "-id",
            page: {
              number: 1,
              size: 1,
            },
          });
          const spotsCount = this.$store.getters["spots/listTotal"];

          for (let index = 1; index <= numCopy; index++) {
            const spotData = cloneDeep(this.spot);
            delete spotData.map_position_x;
            delete spotData.map_position_y;
            delete spotData.id;
            spotData.gallery = spotData.gallery.filter((image) => !!image);
            spotData.code = `${DUPLICATED_DEFAULT_CODE}-${spotsCount + index}`;
            await this.$store.dispatch("spots/add", spotData);
            swal.update({
              html: `${index}/${numCopy}`,
            });
          }

          this.$notify({
            type: "success",
            message: this.$t("SPOTS.SPOT_ADDED"),
          });

          if (numCopy == 1) {
            const spot = await this.$store.getters["spots/spot"];
            this.$emit("onReopen", spot);
          }
          swal.close();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editSpot() {
      this.$emit("onEditSpot", this.spot);
    },

    cleanSpot() {
      this.$emit("onCleanSpot", this.spot.id, this.spot.clean_condition);
    },

    deleteSpot() {
      this.$emit("onDeleteSpot", this.spot);
    },
  },
};
</script>
