var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.salesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.salesInvoice.id},on:{"organizationChanged":(organizationId) => {
              _vm.salesInvoice.organization.id = organizationId;
              _vm.salesInvoice.allowedLocations = [];
              _vm.salesInvoice.salesOrder.id = null;
              _vm.salesInvoice.recipient.id = null;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.salesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"disabled":!_vm.salesInvoice?.organization?.id,"organization":_vm.salesInvoice.organization.id},on:{"locationsChanged":(locations) => {
              _vm.salesInvoice.allowedLocations = locations;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('SALES_INVOICES.EXPIRATION_TIME'),"placeholder":_vm.$t('SALES_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.salesInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "expiration_time", $$v)},expression:"salesInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t(`SALES_INVOICES.SELECT_CUSTOMERS`)} (*)`}},[_c('customer-selector',{attrs:{"allowNone":true,"customer":_vm.salesInvoice.recipient.id,"filterable":true,"showAll":false,"filterOrganization":_vm.salesInvoice.organization.id,"disabled":!_vm.salesInvoice?.organization?.id},on:{"customerChanged":_vm.onCustomerChange}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.recipient}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('h4',[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.salesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "excerpt", $$v)},expression:"salesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.salesInvoice.id ? _vm.$t("SALES_INVOICES.EDIT_SALES_INVOICE") : _vm.$t("COMMON.SEND"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }