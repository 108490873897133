export default {
  SEASONS: "Seasons",
  PRICING_SEASONS_LIST: "List of seasons",
  ADD_PRICING_SEASON: "Add a season",
  PRICING_SEASON_DETAILS: "Season details",
  PRICING_SEASON_ADDED: "Season added",
  EDIT_PRICING_SEASON: "Edit a season",
  PRICING_SEASON_UPDATED: "Updated season",
  DELETE_THIS_PRICING_SEASON: "Do you want to delete this selected season ?",
  DELETE_PRICING_SEASON_SUCCESS: "Deletion of season was completed successfully",
  PRICING_SEASON_DELETED: "Season deleted",
  PRICING_SEASON_CLEANED: "",
  CLEAN_THIS_PRICING_SEASON: "",
  NO_DATA: "",
  PRICE: "Price",
  PRICE$: "Price",
  SELECT_PRICE: "Selection to be made from all existing prices",
  SELECT_START_DATE: "Select the start date of the season",
  SELECT_END_DATE: "Select the end of the season date",
  WEEK_PRICE: "Week prices",
  WEEKEND_PRICE: "Weekend prices",
  SELECT_TYPE_LABEL: "Variation in Dollar ($) or Percentage (%)",
  PRICE_LABEL: "Variation Plus + or Minus -",
  DOLLAR: "Dollar",
  PERCENT: "Percent",
  RESTRICTION: "Restriction",
  MINIMUM_STAY: "Minimum stay",
  MAXIMUM_STAY: "Maximum stay",
  MINIMUM_DAY_BEFORE: "Minimum day before arrival",
  MAXIMUM_DAY_BEFORE: "Maximum day before arrival",
  ACTIVE_FROM: "Active from",
  PRICING_MODULE: "Price management",
  CREATE_SEASON: "Create season",
  SEASON_YEAR: "Season year",
  NAME: 'Season name',
  PRICE_NAME: "Price name",
  PRICE_TYPE: "Price type",
  PLACE_TYPE: "Location type",
  DATE: "Start date or period",
  standard: "Normal price",
  derived: "Derived price (%)",
};