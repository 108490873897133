<template>
  <div class="radio-tabs">
    <div class="radio-group">
      <label
        v-for="option in optionsTabs"
        :key="option.value"
        class="radio-button"
        v-model="selectedOption"
        @change="optionChanged"
        :class="{ active: selectedOption === option.value }"
      >
        <input
          type="radio"
          :value="option.value"
          v-model="selectedOption"
          class="hidden-input"
        />
        {{ option.label }}
      </label>
    </div>
    <!--<p>Selected: {{ selectedOption }}</p>-->
  </div>
</template>

<script>
export default {
  name: "radio-tab-selector",
  props: {
    optionsTabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: "all",
    };
  },
  methods: {
    optionChanged(optionTab){
      this.$emit("update:selected", this.selectedOption);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-group {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin: 0px 10px 30px 10px !important;
}

.radio-button {
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
  text-align: center;
  margin-bottom: 0;
}

.radio-button.active {
  border-bottom: 2px solid #626262;
  
  background-color: transparent;
  color: #000;
}

.radio-button.active:first-of-type{
  border-left: 2px solid #626262;
}

.radio-button:hover {
  background-color: #f0f0f0;
}

.hidden-input {
  display: none;
}
</style>
