export default {
  type: "pricing-rules",
  name: null,
  spotName: null,
  fullName: null,
  value: null,
  pricing_type: "price",
  weekend_value: null,
  variation: null,
  seasonal: false,
  start_date: null,
  description: null,
  end_date: null,
  per_person: false,
  pricing_rule_type: "standard",
  minimum_person: 2,
  maximum_person: 4,
  minimum_stay: 1,
  maximum_stay: 9999,
  minimum_day_before_arrival: 0,
  maximum_day_before_arrival: 9999,
  bundle_id: null,
  source_id: null,
  canal_id: null,
  segment_id: null,
  identification_id: null,
  code_id: null,
  badge_id: null,
  publish_online: false,
  discount_rate: 0,
  lodging_tax: true,
  created_at: null,
  adult_add_cost_value: 0,
  children_add_cost_value: 0,
  relationshipNames: ["spotType", "organization", "derivedFrom", "guarantee"],
  spotType: {
    type: "spot-types",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  derivedFrom: {
    type: "pricing-rules",
    id: null,
  },
  guarantee: {
    type: "policies",
    id: null,
  },
};
